import { Typography } from '@mui/material'
import './CompraPremiada.css'
import { TitleComponent } from 'app/shared/components'
import { lightGreen } from '@mui/material/colors';
import useIsMobile from 'app/shared/hooks/useIsMobile';


const verdeescuro = lightGreen[900];

export const CompraPremiada = () => {
    const Mobile = useIsMobile();
    return (
        <div className={`divcomprapremiada d-flex flex-column pt-5 `} style={{ paddingBottom: Mobile ? '12rem' : '' }}>
            <img src={require(Mobile ? 'assets/comprapremiada/comprapremiadaMobile.png' : 'assets/comprapremiada/comprapremiada.png')} alt="" style={{ width: Mobile ? '100%' : '80%' }} className={`mx-auto ${Mobile ? '' : 'my-5'}`} />

            <TitleComponent texto='Regulamento' />
            <Typography fontFamily={'Arima Madurai'} variant='h5' style={{ textAlign: 'center' }} color={verdeescuro}>
                Promoção <br></br>1° Compra Premiada Agross
            </Typography>
            <Typography style={{ width: Mobile ? '90%' : '70%', textAlign: 'justify' }} className=' mx-auto my-4' color={verdeescuro}>
                <b>1.</b> DESCRIÇÃO DO PROCESSO DE PARTICIPAÇÃO<br></br>
                <b>1.1</b> Esta é uma promoção realizada pela H&F GOIANA AGRÍCOLA DO BRASIL LTDA., doravante denominada simplesmente “AgRoss”, inscrita no CNPJ/MF sob o nº 47.861.748/0001-42, que terá início à partir de sua publicação, <s>vigorando por 120 dias*</s> (prorrogado) aos requisitos elencados abaixo:<br></br>
                <b>1.1.1</b> Esta promoção é direcionada a todos os revendedores de defensivos agrícolas devidamente regularizados a qual a unidade H&F Goiana atenda e que queiram participar.<br></br>
                <b>1.2</b> Para ser premiado o revendedor precisa adquirir em sua <b>1° compra, no mínimo R$ 5.000,00 (cinco mil) reais do portfólio AgRoss.</b> Não haverá sorteio, <b>realizou a primeira compra com estes requisitos, ganhou.</b><br></br>
                Parágrafo único: Promoção exclusiva para 1ª compra na unidade H&F Goiana .<br></br>
                <b>1.2.1</b> O prêmio será um DRONE BIRD CAMERA HD 80M 22 MIN – ES255 ou modelo similar, a depender da disponibilidade de estoque e/ou do fornecedor da AgRoss.<br></br>
                <b>1.2.2</b> O cliente só irá ganhar o prêmio, se a sua compra for aprovada pela AgRoss, ou seja, a aprovação de seu cadastro e a efetivação da compra.<br></br>
                <b>1.2.3</b> Cumprida a condição de participação a entrega do prêmio ocorrerá no momento da entrega dos produtos adquiridos no 1° pedido da AgRoss.<br></br>
                <b>1.3.</b> O prêmio deverá ser conferido no momento da entrega, sendo que a AgRoss não se responsabiliza por quaisquer danos ou avarias que ocorrerem após a sua entrega e nem por defeitos que surgirem, devendo a REVENDA premiada seguir as instruções de uso do fabricante do produto para garantir a qualidade de seu funcionamento.<br></br>
                <b>1.4</b> Qualquer eventualidade ou fato omisso que não se enquadre nesse regulamento, ficará sobre a apreciação e julgamento da AgRoss, devendo ser acatada a decisão em caráter inquestionável por todos os participantes.<br></br>
                <br></br>
                <b>*O prazo informado poderá ser alterado a qualquer tempo pela AgRoss, independente de prévia notificação.</b><br></br>
                <br></br>
                Data de publicação: 03/07/2023


            </Typography>
        </div>
    )
}