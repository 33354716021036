import { Button, FilledInput, FormControl, IconButton, Input, InputAdornment, InputLabel, Link, Typography } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import TokenContext from 'app/shared/services/token/TokenContext';

import axios from 'axios';
import * as React from 'react';
import { CreditoComponent, Extrato, MinhasSolicitacoes, Ocorrencias, TitleComponent } from "app/shared/components";
import { useState, useContext, useEffect } from "react";
import { UserLogged } from "app/shared/contexts";
import AuthService from "app/shared/services/AuthService";
import './MinhaConta.css'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import useIsMobile from "app/shared/hooks/useIsMobile";
import AccountCircle from "@mui/icons-material/AccountCircle";

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EmailIcon from '@mui/icons-material/Email';
import Swal from "sweetalert2";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ShowDivContext } from "app/shared/contexts/ShowDivContext/ShowDivContext";

const primary = lightGreen[800];

const bege = '#F7F0EA';


interface DadosCliente {
    codfilial: string,
    cnpj: string,
    razsoc: string,
    nome_fantasia: string,
    codcid: string,
    cidade: string,
    estado: string,
    endereco: string,
    bairro: string,
    numero: string,
    complemento: string,
    fone: string,
    email: string,
    email_tg: string,
    responsavel_tg: string,
    cpf: string,
    ativo: string
}

interface ContaProps {
    authService: AuthService;
}

export const MinhaConta = (props: ContaProps) => {
    const token = React.useContext(TokenContext);
    const { userLogged } = useContext(UserLogged);
    const [dadosCliente, setDadosCliente] = useState<DadosCliente | null>(null);
    const cnpj = localStorage.getItem('cliente_cnpj');
    const [selecionado, setSelecionado] = useState('Minhas Solicitações');
    const [loaderConta, setLoaderConta] = useState(false);
    const Mobile = useIsMobile();
    const [trNome, setTrNome] = useState('');
    const { setShowDiv } = useContext(ShowDivContext);
    const [trEmail, setTrEmail] = useState('');
    const [trCpf, setTrCpf] = useState('');
    const [showAlterarResp, setShowAlterarResp] = useState(false);
    const [showAlterarSenha, setShowAlterarSenha] = useState(false);
    const [loaderResponsavel, setLoaderResponsavel] = useState(false);
    const [loaderAlterarSenha, setLoaderAlterarSenha] = useState(false);
    const [showPasswordLogin, setShowPasswordLogin] = React.useState(false);
    const [showPasswordNovaSenha, setShowPasswordNovaSenha] = React.useState(false);
    const [showPasswordNovaSenhaRep, setShowPasswordNovaSenhaRep] = React.useState(false);
    const [altSenhaAntiga, setAltSenhaAntiga] = useState('');
    const [altSenhaNova, setAltSenhaNova] = useState('');
    const [altSenhaNovaRep, setAltSenhaNovaRep] = useState('');

    const handleClickShowPasswordLogin = () => setShowPasswordLogin((show) => !show);
    const handleClickShowPasswordNovaSenha = () => setShowPasswordNovaSenha((show) => !show);
    const handleClickShowPasswordNovaSenhaRep = () => setShowPasswordNovaSenhaRep((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        props.authService.getLoggedUser()
            .then(user => {
                if (user) {


                    const getDadosCliente = async () => {
                        setLoaderConta(true);
                        try {
                            const response = await axios.get(`https://data-api.agross.com.br/api/v1/clientestg/${cnpj}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });
                            console.log('minha conta: ', response.data.clientestg[0]);
                            setDadosCliente(response.data.clientestg[0])
                            setLoaderConta(false);
                        } catch (error) {
                            console.error(error);
                            setLoaderConta(false);
                        }
                    }
                    getDadosCliente();
                } else {
                    console.log('No user is signed in');
                    setLoaderConta(false);

                }
            })
            .catch(error => {
                console.error('An error occurred:', error);
                setLoaderConta(false);
            });



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDadosCliente]);

    const handleClick = (itemMenu: string) => {
        setSelecionado(itemMenu);
        window.scrollTo(0, 250);
    }

    // interface ICadastro {
    //     [key: string]: string;
    // }

    const handleAlterarResponsavel = () => {
        const cnpj = localStorage.getItem('cliente_cnpj');
        // const responsavelcompleto: ICadastro = {
        //     nome: trNome,
        //     cpf: trCpf,
        //     email: trEmail
        // };



        const fetchData = async () => {
            setLoaderResponsavel(true);
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/alteraresponsavel?nome=${trNome}&cnpj=${cnpj}&email=${trEmail}&cpf=${trCpf}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response.data);
                Swal.fire(
                    'Solicitação em Análise',
                    response.data,
                    'info'
                )
                setShowAlterarResp(false);
                setLoaderResponsavel(false);

            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
                setLoaderResponsavel(false);
            }
        };

        fetchData();
    }


    const handleResponsavelNome = (event: any) => {
        setTrNome(event.target.value);
    }
    const handleResponsavelCPF = (event: any) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        setTrCpf(onlyNums);
    }
    const handleAlterarSenhaNova = (event: any) => {
        setAltSenhaNova(event.target.value);
    }
    const handleAlterarSenhaAntiga = (event: any) => {
        setAltSenhaAntiga(event.target.value);
    }
    const handleAlterarSenhaNovaRep = (event: any) => {
        setAltSenhaNovaRep(event.target.value);
    }
    const handleResponsavelEmail = (event: any) => {
        setTrEmail(event.target.value);
    }
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleAlterarSenha = () => {
        const alterarsenhacompleto = {
            senhaantiga: altSenhaAntiga,
            senhanova: altSenhaNova,
            senhanovarep: altSenhaNovaRep
        }
        console.log(alterarsenhacompleto);


        const fetchData = async () => {
            setLoaderAlterarSenha(true);
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/clientelogin?cnpj=${cnpj}&senha=${alterarsenhacompleto.senhaantiga}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response.data.clientelogin[0].clientelogin);
                if (response.data.clientelogin[0].clientelogin === 'N') {
                    Swal.fire(
                        'Erro',
                        'A senha antiga está incorreta',
                        'error'
                    )
                    setLoaderAlterarSenha(false);
                } else if (response.data.clientelogin[0].clientelogin === 'S') {
                    const fetchAlterarSenha = async () => {

                        try {
                            const response = await axios.post(`https://data-api.agross.com.br/api/v1/reset?cnpj=${cnpj}&senha=${alterarsenhacompleto.senhanova}`, {}, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            });
                            console.log('reset: ', response.data.reset[0].reset);
                            if (response.data.reset[0].reset === 'S') {
                                Swal.fire(
                                    'Senha Alterada!',
                                    'Sua senha foi alterada com sucesso!',
                                    'success'
                                )
                            }
                            setLoaderAlterarSenha(false);
                            setShowAlterarSenha(false);
                        } catch (error) {
                            console.error('Erro ao alterar senha', error);
                            setLoaderAlterarSenha(false);
                        }
                    };

                    fetchAlterarSenha();
                }



            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
                setLoaderAlterarSenha(false);
            }
        };

        fetchData();
    }

    return (

        <div>
            {userLogged ?
                <TitleComponent texto="Minha Conta" variant={Mobile ? "h4" : "h3"} />
                :
                ''}
            {!userLogged ?
                <Typography style={{ color: primary, fontSize: '0.7rem', textAlign: 'center' }} className="my-5">
                    Já possui cadastro?{' '}
                    <Link href="#" color="secondary"
                        onClick={() => {
                            setShowDiv(true);
                        }}

                    >
                        Clique aqui
                    </Link>{' '}
                    e faça login!
                </Typography>
                :
                <div className={`d-flex ${Mobile ? 'flex-column' : ''}`}>
                    <div style={{ width: Mobile ? '100%' : '30%' }} className="mx-2 my-2 p-1">
                        <div style={{ border: '1px solid gray', borderRadius: '0.3rem' }} className=" position-relative">
                            <div className={`loader ${loaderConta ? 'd-flex' : 'd-none'}`}>
                                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '20%' }} />
                            </div>
                            <div style={{ borderBottom: '1px solid gray', backgroundColor: bege, borderRadius: '0.3rem' }} className="p-2">
                                <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.5rem', color: primary }}>
                                    Informações da Conta
                                </Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid gray', color: primary }} className="p-2 d-flex flex-column">

                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    Razão Social: {dadosCliente?.razsoc}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    Nome Fantasia: {dadosCliente?.nome_fantasia}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    CNPJ: {dadosCliente?.cnpj}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    Endereço: {dadosCliente?.endereco},&nbsp;{dadosCliente?.numero}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    Bairro: {dadosCliente?.bairro}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    Cidade: {dadosCliente?.cidade}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    Responsável: {dadosCliente?.responsavel_tg}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    E-mail: {dadosCliente?.email_tg}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    CPF: {dadosCliente?.cpf}
                                </Typography>
                                <Typography style={{ borderBottom: '1px solid lightgray' }} className="my-2">
                                    Telefone: {dadosCliente?.fone}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${primary}`, width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<ManageAccountsIcon />}
                                    onClick={() => {
                                        setShowAlterarResp(true)
                                        setShowAlterarSenha(false)
                                    }}
                                >
                                    Solicitar Alteração de Responsável
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className=" my-2"
                                    style={{ color: 'white', border: `1px solid ${primary}`, width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<ManageAccountsIcon />}
                                    onClick={() => {
                                        setShowAlterarSenha(true)
                                        setShowAlterarResp(false)
                                    }}
                                >
                                    Alterar Senha
                                </Button>
                            </div>
                        </div>
                        <div style={{ border: '1px solid gray', borderRadius: '0.3rem' }} className="mt-3">
                            <div style={{ borderBottom: '1px solid gray', backgroundColor: bege, borderRadius: '0.3rem' }} className="p-2">
                                <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.5rem', color: primary }}>
                                    Menu Principal
                                </Typography>
                            </div>
                            <div
                                style={{ borderBottom: '1px solid gray', borderRadius: '0.3rem' }}
                                className="p-2 hovermenuprincipal"
                                onClick={() => handleClick('Minhas Solicitações')}
                            >
                                <Typography style={{ color: primary }} >
                                    Minhas Solicitações
                                </Typography>
                            </div>
                            <div
                                style={{ borderBottom: '1px solid gray', borderRadius: '0.3rem' }}
                                className="p-2 hovermenuprincipal"
                                onClick={() => handleClick('Ocorrências')}
                            >
                                <Typography style={{ color: primary }} >
                                    Ocorrências
                                </Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid gray', borderRadius: '0.3rem' }}
                                className="p-2 hovermenuprincipal"
                                onClick={() => handleClick('Extrato')}
                            >
                                <Typography style={{ color: primary }} >
                                    Meu Extrato
                                </Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid gray', borderRadius: '0.3rem' }}
                                className="p-2 hovermenuprincipal"
                                onClick={() => handleClick('Credito')}
                            >
                                <Typography style={{ color: primary }} >
                                    Crédito e Contas a Pagar
                                </Typography>
                            </div>
                        </div>
                        <div className={`divAlterarRespgeral ${showAlterarResp ? 'divAlterarRespgeral-shown' : 'divAlterarRespgeral-hidden'}`}>
                            <div className={`loader ${loaderResponsavel ? 'd-flex' : 'd-none'}`}>
                                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '20%' }} />
                            </div>
                            <div className='divalterarresp'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setShowAlterarResp(false)}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Alteração de Responsável
                                </Typography>


                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="alternomeresp" style={{ color: primary }}>
                                        Novo Nome do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleResponsavelNome}
                                        id="alternomeresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="altercpfresp" style={{ color: primary }}>
                                        Novo CPF do Responsável* (Somente números)
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleResponsavelCPF}
                                        id="altercpfresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="alteremailresp" style={{ color: primary }}>
                                        Novo E-mail do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleResponsavelEmail}
                                        id="alteremailresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    onClick={handleAlterarResponsavel}
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}

                                >
                                    Enviar Alteração
                                </Button>

                            </div>
                        </div>
                        <div className={`divAlterarSenhageral ${showAlterarSenha ? 'divAlterarSenhageral-shown' : 'divAlterarSenhageral-hidden'}`}>
                            <div className={`loader ${loaderAlterarSenha ? 'd-flex' : 'd-none'}`}>
                                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '20%' }} />
                            </div>
                            <div className='divalterarSenha'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setShowAlterarSenha(false)}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Alterar Senha
                                </Typography>

                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="senhaantiga" style={{ color: primary }}>Senha Antiga</InputLabel>
                                    <FilledInput

                                        onChange={handleAlterarSenhaAntiga}
                                        style={{ color: primary }}
                                        id="senhaantiga"
                                        type={showPasswordLogin ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordLogin}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="senhanova" style={{ color: primary }}>Nova Senha</InputLabel>
                                    <FilledInput

                                        onChange={handleAlterarSenhaNova}
                                        style={{ color: primary }}
                                        id="senhanova"
                                        type={showPasswordNovaSenha ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordNovaSenha}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordNovaSenha ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="senhanovarep" style={{ color: primary }}>Repita a Nova Senha</InputLabel>
                                    <FilledInput

                                        onChange={handleAlterarSenhaNovaRep}
                                        style={{ color: primary }}
                                        id="senhanovarep"
                                        type={showPasswordNovaSenhaRep ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordNovaSenhaRep}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordNovaSenhaRep ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    onClick={() => {
                                        if (altSenhaAntiga === '') {
                                            Swal.fire(
                                                'Erro',
                                                'Preencha a senha antiga',
                                                'info'
                                            )
                                        } else if (altSenhaNova === '') {
                                            Swal.fire(
                                                'Erro',
                                                'Preencha a senha nova',
                                                'info'
                                            )
                                        } else if (altSenhaNovaRep === '') {
                                            Swal.fire(
                                                'Erro',
                                                'Preencha a confirmação de senha nova',
                                                'info'
                                            )
                                        } else if (altSenhaNova !== altSenhaNovaRep) {
                                            Swal.fire(
                                                'Erro',
                                                'As senhas novas não são iguais',
                                                'info'
                                            )
                                        } else {
                                            swalWithBootstrapButtons.fire({
                                                title: 'Alterar Senha',
                                                text: "Deseja alterar senha?",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: 'Sim',
                                                cancelButtonText: 'Não',
                                                reverseButtons: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    handleAlterarSenha();
                                                }
                                            })
                                        }

                                    }}
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}

                                >
                                    Enviar Alteração
                                </Button>

                            </div>
                        </div>
                    </div>
                    {selecionado === 'Minhas Solicitações' && <MinhasSolicitacoes authService={new AuthService()} />}
                    {selecionado === 'Ocorrências' && <Ocorrencias authService={new AuthService()} />}
                    {selecionado === 'Extrato' && <Extrato authService={new AuthService()} />}
                    {selecionado === 'Credito' && <CreditoComponent authService={new AuthService()} />}


                </div>

            }

        </div>
    )
}