import { Button, Typography } from "@mui/material"
import { TitleComponent } from "app/shared/components"
import { lightGreen } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const primary = lightGreen[800];



export const AminoAgRoss = () => {
    const navigate = useNavigate();
    return (
        <div className=" d-flex flex-column">
            <TitleComponent texto="AminoAgRoss Fertilizante Foliar" />
            <Button variant="outlined" size="small" startIcon={<KeyboardBackspaceOutlinedIcon />} style={{ color: primary, width: '5rem' }} className="mx-4" onClick={() => {
                            navigate('/portfolio');
                            window.scrollTo(0, 0);
                        }}>
                                    Voltar
                                </Button>
            <img src={require('assets/AminoAgross.png')} alt="" className="w-100" />
            <div className="w-75 m-auto">
                <Typography className="text-center" style={{ color: primary }}>
                    Amino AgRoss é um fertilizante foliar organomineral com macro e micronutrientes, rico em carbono orgânico, importante na formação, vegetação e florescimento das plantas. Desenvolvido com o mais alto nível de tecnologia, visa atender a demanda de praticamente todas as culturas, trazendo inúmeros benefícios.
                </Typography>
                <Typography style={{ color: primary }} className="mx-3 my-5">
                
                    • Maior desenvolvimento vegetativo das plantas;<br />
                    • Maior desenvolvimento radicular;<br />
                    • Auxiliam na recuperação de áreas com fitotoxicidade;<br />
                    • Aumenta a resistência das plantas às condições climáticas adversas;<br />
                    • Melhoria no pegamento de flores e frutos<br />
                </Typography>
            </div>
        </div>
    )
}