import { Box, Button, Modal, Typography } from "@mui/material"
import { lightGreen } from "@mui/material/colors";
import TokenContext from 'app/shared/services/token/TokenContext';
import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import AuthService from "app/shared/services/AuthService";
import './MinhasSolicitacoes.css';
import useIsMobile from "app/shared/hooks/useIsMobile";
import Swal from "sweetalert2";
import LoadingButton from '@mui/lab/LoadingButton';
import PersonIcon from '@mui/icons-material/Person';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { TitleComponent } from "../titlecomponent/TitleComponent";

const primary = lightGreen[800];

const bege = '#F7F0EA';

interface MinhasSolicitacoesProps {
    authService: AuthService;
}

interface solicitacoes {
    codfilial: string,
    filial: string,
    id_solicitacao: string,
    cnpj: string,
    dtpedido: string,
    status: string,
    concluido: string,
    total_solicitacao: string,
    observacao: string,
    codbrinde: string,
    brinde: string,
    id_categoria: string,
    categoria: string,
    pontos: string,
    total_brinde: string,
    quantidade: string,
    voltagem: string | null
}

export const MinhasSolicitacoes = (props: MinhasSolicitacoesProps) => {

    const token = useContext(TokenContext);
    const cnpj = localStorage.getItem('cliente_cnpj');
    const [minhassolicitacoes, setminhassolicitacoes] = useState<solicitacoes[] | null>(null)
    const [filteredSolicitacoes, setFilteredSolicitacoes] = useState<solicitacoes[] | null>(null)
    const [SolicitacaoNum, setSolicitacaoNum] = useState('');

    const [loaderSolicitacoes, setLoaderSolicitacoes] = useState(false);
    const [loaderCancelamento, setLoaderCancelamento] = useState(false);
    const Mobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        props.authService.getLoggedUser()
            .then(user => {
                if (user) {
                    console.log('teste2');


                    const getDadosCliente = async () => {
                        setLoaderSolicitacoes(true);
                        try {
                            const response = await axios.get(`https://data-api.agross.com.br/api/v1/pedidostg/${cnpj}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });
                            console.log('minhas solicitações: ', response.data.pedidostg);
                            setminhassolicitacoes(response.data.pedidostg);
                            setLoaderSolicitacoes(false);


                        } catch (error) {
                            console.error(error);
                            setLoaderSolicitacoes(false);
                        }
                    }
                    getDadosCliente();
                } else {
                    console.log('No user is signed in');
                    setLoaderSolicitacoes(false);


                }
            })
            .catch(error => {
                console.error('An error occurred:', error);
                setLoaderSolicitacoes(false);
            });



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const cancelarPedido = (solicitacao: string) => {
        swalWithBootstrapButtons.fire({
            title: 'Cancelar Pedido',
            text: "Deseja solicitar o cancelamento?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const fetchData = async () => {
                    setLoaderCancelamento(true)
                    try {
                        const response = await axios.post(`https://data-api.agross.com.br/api/v1/cancelapedido?cnpj=${cnpj}&id_pedido=${solicitacao}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        console.log(response.data);
                        Swal.fire(
                            'Solicitação em Análise',
                            response.data,
                            'error'
                        )
                        setLoaderCancelamento(false)
                    } catch (error) {
                        console.error('Erro ao alterar quantidade', error);
                        setLoaderCancelamento(false)
                    }
                };

                fetchData();

            }
        })


    }

    const HandleFilter = (id: string) => {
        console.log(id);
        if (minhassolicitacoes) {
            setFilteredSolicitacoes(minhassolicitacoes.filter(solicitacao => solicitacao.id_solicitacao === id));
            setSolicitacaoNum(id);
        }
        console.log(minhassolicitacoes);
        console.log(filteredSolicitacoes?.[0].total_solicitacao);
        handleOpen();
    }
    const style = {
        position: 'absolute' as 'absolute',
        borderRadius: '0.5rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 24,
        p: 4,
    };
    const styleMobile = {
        position: 'absolute' as 'absolute',
        borderRadius: '0.5rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 24,
        p: 4,
    };
    return (
        <div style={{ width: Mobile ? '90%' : '70%' }} className={`${Mobile ? 'm-auto' : 'mx-2'} my-2 p-1 position-relative`}>
            <div className={`loaderConta ${loaderSolicitacoes ? 'd-flex' : 'd-none'}`}>
                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '13%' }} />
            </div>
            <div style={{ border: '1px solid gray', borderRadius: '0.3rem' }}>
                <div style={{ borderBottom: '1px solid gray', backgroundColor: bege, borderRadius: '0.3rem' }} className="p-2">
                    <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.5rem', color: primary }}>
                        Minhas Solicitações
                    </Typography>
                </div>
                <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                    style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', backgroundColor: bege }}>
                    <div style={{ width: '30%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.2rem', color: primary }}>
                            Solicitação
                        </Typography>
                    </div>
                    <div style={{ width: '40%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.2rem', color: primary }}>
                            Status
                        </Typography>
                    </div>
                    <div style={{ width: '30%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.2rem', color: primary }}>
                            Opção
                        </Typography>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={Mobile ? styleMobile : style}>
                        <TitleComponent texto={`Solicitação Nº ${Number(SolicitacaoNum).toLocaleString()}`} variant="h5" />

                        <CancelRoundedIcon
                            className='btnclose'
                            style={{ position: 'absolute', top: 2, right: 3, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                        <div style={{
                            overflow: filteredSolicitacoes?.length === undefined ? '' : filteredSolicitacoes?.length > 3 ? 'overlay' : '',
                            maxHeight: filteredSolicitacoes?.length === undefined ? '' : filteredSolicitacoes?.length > 3 ? '55vh' : ''
                        }}>

                            {filteredSolicitacoes?.map((carrinho) => (

                                <div>
                                    {Mobile ?
                                        <div style={{ boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', position: 'relative' }} className=' m-3 rounded-2 p-2'>
                                            <div className=' d-flex align-items-center flex-column'>
                                                <div style={{ width: '35%' }}>
                                                    <img src={require(`assets/brindes/${carrinho.codbrinde}.jpg`)} alt="" style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '50%', textAlign: 'center' }} className="my-2">
                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                        {carrinho.brinde}
                                                    </Typography>
                                                </div>
                                                <div style={{ width: '90%' }} className=" d-flex justify-content-around">
                                                    <div style={{ width: '50%', textAlign: 'center' }}>
                                                        <Typography style={{ color: primary }} variant='subtitle2'>
                                                            {carrinho.quantidade} Unidade(s)
                                                        </Typography>
                                                    </div>
                                                    <div style={{ width: '50%', textAlign: 'center' }}>
                                                        <Typography style={{ color: primary }} variant='subtitle2'>
                                                            {Number(carrinho.total_brinde).toLocaleString()} Pontos
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', position: 'relative' }} className=' m-3 rounded-2 p-2'>
                                            <div className=' d-flex justify-content-around align-items-center'>
                                                <div style={{ width: '15%' }}>
                                                    <img src={require(`assets/brindes/${carrinho.codbrinde}.jpg`)} alt="" style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '45%' }}>
                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                        {carrinho.brinde}
                                                    </Typography>
                                                </div>
                                                <div style={{ width: '20%' }}>
                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                        {carrinho.quantidade} Unidade(s)
                                                    </Typography>
                                                </div>
                                                <div style={{ width: '20%' }}>
                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                        {Number(carrinho.total_brinde).toLocaleString()} Pontos
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                        <Typography className="my-3 mx-auto" color={primary}>
                            Pontuação Total: {Number(filteredSolicitacoes?.[0].total_solicitacao).toLocaleString()} Pontos
                        </Typography>
                    </Box>
                </Modal>
                {minhassolicitacoes && minhassolicitacoes
                    .filter((solicitacao, index, self) =>
                        index === self.findIndex((sol) => sol.id_solicitacao === solicitacao.id_solicitacao)
                        && solicitacao.status !== 'I')
                    .map((solicitacao) => (
                        <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                            style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)' }}>

                            <div className=" d-flex flex-column align-items-center" style={{ width: '30%', color: primary }}
                                onClick={() => {
                                    HandleFilter(solicitacao.id_solicitacao);

                                }}
                            >
                                <div style={{ marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer', textAlign: 'center' }} className="linksolicitacao">
                                    <div style={{ fontSize: Mobile ? '0.8rem' : '' }}>
                                        Solicitação Nº: {Number(solicitacao.id_solicitacao).toLocaleString()}
                                    </div>
                                    <div style={{ fontSize: Mobile ? '0.8rem' : '' }}>
                                        Data: {solicitacao.dtpedido}
                                    </div>
                                    <div style={{ fontSize: Mobile ? '0.8rem' : '' }}>
                                        Pontuação Total: {Number(solicitacao.total_solicitacao).toLocaleString()}
                                    </div>
                                </div>

                            </div>
                            <div style={{ width: '40%', textAlign: 'center' }}>
                                {solicitacao.status === 'R' ?
                                    <Typography className=" p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'red' }}>
                                        Reprovado
                                    </Typography>
                                    :
                                    (solicitacao.status === 'A' ?
                                        <Typography className=" bg-success p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>
                                            Aprovado
                                        </Typography>
                                        :
                                        ((solicitacao.status === 'L' || solicitacao.status === 'E') ?
                                            <Typography className=" bg-warning p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>
                                                Em Análise
                                            </Typography>
                                            : '')
                                    )
                                }
                            </div>
                            <div style={{ width: '30%', display: 'flex' }}>
                                {loaderCancelamento ?
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        disabled={true}
                                        variant="contained"
                                        size="small"
                                        style={{ color: 'gray', border: `1px solid ${primary}`, width: '70%', marginLeft: 'auto', marginRight: 'auto', fontSize: Mobile ? '0.5rem' : '' }}
                                        startIcon={<PersonIcon />}

                                    >
                                        Pedir Cancelamento
                                    </LoadingButton>
                                    :
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', fontSize: Mobile ? '0.5rem' : '' }}
                                        disabled={solicitacao.status === 'A' ? true : solicitacao.status === 'R' ? true : false}
                                        onClick={() => { cancelarPedido(solicitacao.id_solicitacao); }}
                                    >
                                        Pedir Cancelamento
                                    </Button>
                                }


                            </div>

                        </div>
                    ))
                }

            </div>
        </div>
    )
}