// NewsList.tsx

import React, { useEffect, useState } from 'react';
import { News } from './News';
import NewsItem from './NewsItem';
import { Grid } from '@mui/material';
import { newsData } from './newsData';

const NewsList: React.FC = () => {
  const [newsList, setNewsList] = useState<News[]>([]);

  useEffect(() => {
    setNewsList([...newsData].sort((a, b) => b.id - a.id));
  }, []);

  return (
    <Grid container spacing={2} style={{cursor: 'pointer'}} className='mb-5 '>
      {newsList.map((news) => (
        <Grid item xs={12} sm={6} md={4} key={news.id} >
          <NewsItem news={news} />
        </Grid>
      ))}
    </Grid>
  );
};

export default NewsList;
