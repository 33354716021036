import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import './MenuRadio.css';
import '../../../../../../index.css';
import { useState, useContext } from "react";
import { lightGreen } from '@mui/material/colors';
import * as React from 'react';
import { ShowDivContext } from 'app/shared/contexts/ShowDivContext/ShowDivContext';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import AuthService from 'app/shared/services/AuthService';
import { MenuNewsletter } from '../CadastroNewsletter/MenuNewsletter';
import { MenuRevenda } from '../CadastroRevenda/MenuRevenda';

const primary = lightGreen[800];
const bege = '#F7F0EA';

interface Menuheader2Props {
    onMenuClick?: (menuItem: string) => void;
    authService: AuthService;
    showSeletorProp: boolean;
    onClose: () => void; // Adicionando a propriedade onClose
}

export const MenuRadio: React.FC<Menuheader2Props> = (props) => {
    const { showDiv, setShowDiv } = useContext(ShowDivContext);
    const [showSeletor, setShowSeletor] = useState(props.showSeletorProp);
    const [radioValue, setRadioValue] = useState<string | null>(null);
    const [showCadastrar, setShowCadastrar] = useState(false);
    const [showNewsletter, setShowNewsletter] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue(event.target.value);
    };

    React.useEffect(() => {
        setShowSeletor(props.showSeletorProp);
    }, [props.showSeletorProp]);

    const Mobile = useIsMobile();

    const handleAction = () => {
        setShowDiv(false);
        setShowSeletor(false);

        if (radioValue === 'revenda') {
            props.onClose();
            setShowCadastrar(true);
        } else if (radioValue === 'normal') {
            props.onClose();
            setShowNewsletter(true); // Abre o MenuNewsletter
        }
    };

    return (
        <div style={{ overflowX: 'clip' }}>
            <AppBar position="relative" >
                <Toolbar style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', paddingRight: 0, paddingLeft: 0 }}>
                    {Mobile ?
                        <div className={`diventrarmobile ${showSeletor ? 'diventrargeralmobile' : 'diventrargeral-hidden'}`}>
                            <div className='diventrar' style={{ display: 'flex', flexDirection: 'column', height: '100%', alignContent: 'center' }}>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => {
                                        setShowSeletor(false);
                                        setRadioValue(null);
                                        props.onClose(); // Chamando a função onClose passada pelo componente pai
                                    }}
                                />
                                <div style={{ width: '40%', alignContent: 'center', marginLeft: '-2%' }}>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        style={{ width: '100%' }}
                                        value={radioValue}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="revenda" style={{ color: primary }} control={<Radio />} label="Sou uma revenda e quero ser cliente AgRoss" />
                                        <FormControlLabel value="normal" style={{ marginTop: '8%', color: primary }} control={<Radio />} label="Não sou revenda, apenas quero receber informações da AgRoss." />
                                    </RadioGroup>
                                </div>
                                <div style={{ width: '100%', alignContent: 'center' }}>
                                    <div style={{ marginLeft: '7%', marginTop: '5%' }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{ color: 'white', border: `1px solid ${bege}`, top: 0 }}
                                            onClick={handleAction}
                                            startIcon={<PersonIcon />}
                                            disabled={!radioValue}
                                        >
                                            Cadastrar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={`diventrargeral ${showSeletor ? 'diventrargeral-shown' : 'diventrargeral-hidden'}`}>
                            <div className='diventrar' style={{ display: 'flex', position: 'relative', flexDirection: 'row' }}>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => {
                                        setShowSeletor(false);
                                        setRadioValue(null);
                                        props.onClose(); // Chamando a função onClose passada pelo componente pai
                                    }}
                                />
                                <div style={{ width: '75%', alignContent: 'center', marginLeft: '-2%' }}>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        style={{ width: '100%' }}
                                        value={radioValue}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="revenda" style={{ color: primary }} control={<Radio />} label="Sou uma revenda e quero ser cliente AgRoss" />
                                        <FormControlLabel value="normal" style={{ marginTop: '3%', color: primary }} control={<Radio />} label="Não sou revenda, apenas quero receber informações da AgRoss." />
                                    </RadioGroup>
                                </div>
                                <div style={{ width: '25%', alignContent: 'center' }}>
                                    <div className='d-flex justify-content-evenly'>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{ color: 'white', border: `1px solid ${bege}`, top: 0 }}
                                            onClick={handleAction}
                                            startIcon={<PersonIcon />}
                                            disabled={!radioValue}
                                        >
                                            Cadastrar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            {showNewsletter && <MenuNewsletter authService={props.authService} onClose={() => setShowNewsletter(false)} showSeletorProp={showSeletor} />}
            {showCadastrar && <MenuRevenda authService={props.authService} onClose={() => setShowCadastrar(false)} showSeletorProp={showSeletor} showCadastrar={showCadastrar} />}
        </div>
    );
};
