import AuthService from 'app/shared/services/AuthService';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useState, useEffect } from "react";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { lightGreen } from '@mui/material/colors';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Swal from 'sweetalert2';

const primary = lightGreen[800];
const bege = '#F7F0EA';

interface Menuheader2Props {
    onMenuClick?: (menuItem: string) => void;
    authService: AuthService;
    onClose: () => void; // Adicionando a propriedade onClose
    showSeletorProp: boolean; // Prop adicional para consistência
}

export const MenuNewsletter: React.FC<Menuheader2Props> = (props) => {
    const Mobile = useIsMobile();

    const [showCadastrar, setShowCadastrar] = useState(true); // Modificado para iniciar como true

    const [nome, setNome] = useState('');
    const [errorNome, setErrorNome] = useState(false);
    const [NomeRespCadastro, setNomeRespCadastro] = useState('');

    const [EmailRespCadastro, setEmailRespCadastro] = useState('');
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);

    const [CPFRespCadastro, setCPFRespCadastro] = useState('');
    const [telefone, setTelefone] = useState('');
    const [errorTelefone, setErrorTelefone] = useState(false);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        validateFields();
    }, [nome, email, telefone]);

    const handleCadastroNome = (event: any) => {
        const value = event.target.value;
        setNome(value);
        setNomeRespCadastro(event.target.value);
        if (value.length > 3) {
            setErrorNome(false);
        }
    }

    const handleBlur = () => {
        if (nome.length === 0 || nome.length <= 3) {
            setErrorNome(true);
        }
    };

    const handleCadastroEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value);
        setEmailRespCadastro(value);
        if (value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setErrorEmail(false);
        }
    };

    const handleBlurEmail = () => {
        if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setErrorEmail(true);
        } else {
            setErrorEmail(false);
        }
    };

    const handleCadastroCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        if (value.length > 0) {
            value = value.replace(/^(\d{2})(\d)/, '($1) $2'); // Adiciona parênteses ao DDD
            value = value.replace(/(\d{5})(\d)/, '$1-$2'); // Adiciona o hífen
        }
        setTelefone(value);
        setCPFRespCadastro(value.replace(/\D/g, '')); // Armazena apenas os dígitos
        if (value.replace(/\D/g, '').length === 11) {
            setErrorTelefone(false);
        }
    }

    const handleBlurCPF = () => {
        if (telefone.replace(/\D/g, '').length < 11) {
            setErrorTelefone(true);
        }
    };

    const validateFields = () => {
        if (
            nome.length > 3 &&
            email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) &&
            telefone.replace(/\D/g, '').length === 11
        ) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };

    const handleSubmit = () => {
        Swal.fire({
            title: 'Cadastrado com sucesso!',
            icon: 'success',
            confirmButtonText: 'OK'
        });
    };

    return (
        <div style={{ overflowX: 'clip' }}>
            <AppBar position="relative" >
                <Toolbar style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', paddingRight: 0, paddingLeft: 0 }}>
                    {Mobile ?
                        <div className={`divcadastrargeralmobile`}
                            style={{ transform: showCadastrar ? 'translateX(0%)' : 'translateX(110%)', transition: showCadastrar ? 'transform 0.7s ease-in-out' : 'transform 0.7s ease-in-out' }}
                        >
                            <div className='divcadastrarmobile'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => props.onClose()}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Faça seu cadastro!
                                </Typography>

                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="nomeresp" style={{ color: errorNome ? 'red' : primary }} >
                                        {errorNome ? 'Digite um Nome Válido' : 'Nome do Responsável*'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroNome}
                                        onBlur={handleBlur}
                                        id="nomeresp"
                                        type="text"
                                        value={nome}
                                        inputProps={{
                                            pattern: '[A-Za-z]*'
                                        }}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="emailresp" style={{ color: errorEmail ? 'red' : primary }} >
                                        {errorEmail ? 'Por favor, insira um e-mail válido.' : 'E-mail*'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: errorEmail ? 'red' : primary }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCadastroEmail(e);
                                        }}
                                        onBlur={handleBlurEmail}
                                        id="emailresp"
                                        type="email"
                                        value={email}
                                        inputProps={{
                                            pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="cpfresp" style={{ color: errorTelefone ? 'red' : primary }} >
                                        {errorTelefone ? 'Digite um numero celular Válido' : 'Celular com DDD* (Somente números)'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: errorTelefone ? 'red' : primary }}
                                        onChange={handleCadastroCPF}
                                        onBlur={handleBlurCPF}
                                        id="cpfresp"
                                        type="text"
                                        value={telefone}
                                        inputProps={{
                                            maxLength: 15,
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<PersonIcon />}
                                    disabled={isButtonDisabled}
                                    onClick={handleSubmit}
                                >
                                    Cadastrar
                                </Button>
                            </div>
                        </div>
                        :
                        <div className={`divcadastrargeral ${showCadastrar ? 'divcadastrargeral-shown' : 'divcadastrargeral-hidden'}`}>
                            <div className='divcadastrar'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => props.onClose()}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Faça seu cadastro!
                                </Typography>

                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="nomeresp" style={{ color: errorNome ? 'red' : primary }}>
                                        {errorNome ? 'Digite um Nome Válido' : 'Nome do Responsável*'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroNome}
                                        onBlur={handleBlur}
                                        id="nomeresp"
                                        type="text"
                                        value={nome}
                                        inputProps={{
                                            pattern: '[A-Za-z]*'
                                        }}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="emailresp" style={{ color: errorEmail ? 'red' : primary }}>
                                        {errorEmail ? 'Por favor, insira um e-mail válido.' : 'E-mail*'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: errorEmail ? 'red' : primary }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCadastroEmail(e);
                                        }}
                                        onBlur={handleBlurEmail}
                                        id="emailresp"
                                        type="email"
                                        value={email}
                                        inputProps={{
                                            pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="cpfresp" style={{ color: errorTelefone ? 'red' : primary }}>
                                        {errorTelefone ? 'Digite um numero celular Válido' : 'Celular com DDD* (Somente números)'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: errorTelefone ? 'red' : primary }}
                                        onChange={handleCadastroCPF}
                                        onBlur={handleBlurCPF}
                                        id="cpfresp"
                                        type="text"
                                        value={telefone}
                                        inputProps={{
                                            maxLength: 15,
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<PersonIcon />}
                                    disabled={isButtonDisabled}
                                    onClick={handleSubmit}
                                >
                                    Cadastrar
                                </Button>
                            </div>
                        </div>
                    }

                </Toolbar>
            </AppBar>
        </div>
    )
}
