import { Typography } from "@mui/material"

import NewsList from "./newslist"
import { lightGreen } from '@mui/material/colors';

const verdeescuro = lightGreen[900];



export const Noticias = () => {

    return (
        <div className="mt-5 d-flex flex-column">
            <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className=" d-flex flex-column">
                    <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Notícias
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
                <NewsList />
            </div>
        </div>



    )
}