import Hotjar from '@hotjar/browser'

const siteId = 5018745;
const hotjarVersion = 6;

export const initHotjar = () => {
  Hotjar.init(siteId, hotjarVersion);
};




