import { Route, BrowserRouter, Routes as Switch, Navigate, useLocation } from "react-router-dom";
import { Agross, Agross2, AminoAgRoss, AssistaAoVivo, Brindes, Carrinho, ComoParticipar, CompraPremiada, Contato, Depoimentos, Esqueceu, HoraDoRango, MateriaisAgRoss, MinhaConta, NossaHistoria, Noticias, Pdf, Perguntas, PerguntasFrequentes, PoliticaPrivacidade, Portfolio, Ppt, ProdutosParticipantes, Raspadinha, RecomendacoesTecnicas, Regras, Regulamento, Roundup3000, SementeMilho, TroqueEGanhe, TvAgross, Vantagens, Videos, Portfolio2, Desafio } from "../pages";
import { Menuheader, Menufooter } from "../shared/components";
import { MenuContextProvider, UserLogged } from "app/shared/contexts";
import NewsDetail from "app/pages/pagesagross/noticias/NewsDetail";
import { useState, useEffect } from "react";
import { ShowDivContext } from "app/shared/contexts/ShowDivContext/ShowDivContext";
import AuthService from "app/shared/services/AuthService";
import { CartProvider } from "app/shared/services/CartProvider";
import React from 'react';
import TokenContext from "app/shared/services/token/TokenContext";
import getTokenAndSave from "app/shared/services/token/getTokenAndSave";
import { CarrinhoProvider } from "app/shared/services/cart/CarrinhoContext";
import { logPageView } from '../../analytics';

interface AppRoutesProps {
    showDiv: boolean;
    setShowDiv: React.Dispatch<React.SetStateAction<boolean>>;
}

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView();
        console.log("Location changed to:", location.pathname); // Adicionando log para verificar a localização atual
    }, [location]);

    return null;
};

const AppRoutes: React.FC<AppRoutesProps> = ({ showDiv, setShowDiv }) => {
    const location = useLocation();
    const hideHeaderPaths = ["/agross2"]; // Adicione aqui as rotas onde você quer esconder o Menuheader

    return (
        <>
            {!hideHeaderPaths.includes(location.pathname) && (
                <Menuheader authService={new AuthService()} />
            )}

            <Switch>
                {/* Rotas AgRoss */}
                <Route path="/" element={<Agross />} />
                <Route path="/agross2" element={<Agross2 />} />
                <Route path="/nossahistoria" element={<NossaHistoria />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/portfolio2" element={<Portfolio2 />} />
                <Route path="/recomendacoes" element={<RecomendacoesTecnicas />} />
                <Route path="/vantagens" element={<Vantagens />} />
                <Route path="/noticias" element={<Noticias />} />
                <Route path="/noticias/:id" element={<NewsDetail />} />
                <Route path="/depoimentos" element={<Depoimentos />} />
                <Route path="/contato" element={<Contato />} />
                <Route path="/aminoagross" element={<AminoAgRoss />} />
                <Route path="/sementemilho" element={<SementeMilho />} />
                <Route path="/roundup3000" element={<Roundup3000 />} />
                <Route path="/raspadinha" element={<Raspadinha />} />
                <Route path="/desafio" element={<Desafio />} />
                <Route path="/comprapremiada" element={<CompraPremiada />} />
                <Route path="/materiais" element={<MateriaisAgRoss />} />
                <Route path="/pdf" element={<Pdf />} />
                <Route path="/ppt" element={<Ppt />} />
                <Route path="/politica" element={<PoliticaPrivacidade />} />

                {/* Rotas TvAgross */}
                <Route path="/tvagross" element={<TvAgross />} />
                <Route path="/assistaaovivo" element={<AssistaAoVivo />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/perguntasfrequentes" element={<Perguntas />} />
                <Route path="/horadorango" element={<HoraDoRango />} />

                {/* Rotas Troque e Ganhe */}
                <Route path="/troqueeganhe" element={<TroqueEGanhe onLinkClick={() => setShowDiv(true)} />} />
                <Route path="/regulamento" element={<Regulamento />} />
                <Route path="/perguntasfrequentestg" element={<PerguntasFrequentes />} />
                <Route path="/brindes" element={<Brindes authService={new AuthService()} />} />
                <Route path="/carrinho" element={<Carrinho />} />
                <Route path="/minhaconta" element={<MinhaConta authService={new AuthService()} />} />
                <Route path="/produtosparticipantes" element={<ProdutosParticipantes />} />
                <Route path="/esqueceu" element={<Esqueceu />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Switch>

            <Menufooter />
        </>
    );
};

export const Routes = () => {
    const [showDiv, setShowDiv] = useState(false);
    const [userLogged, setUserLogged] = useState(true);
    const [token, setToken] = React.useState<string | null>(localStorage.getItem('token') || null);

    React.useEffect(() => {
        getTokenAndSave().then(newToken => {
            setToken(newToken);
        });

        const intervalId = setInterval(() => {
            getTokenAndSave().then(newToken => {
                setToken(newToken);
            });
        }, 50 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <BrowserRouter>
            <TokenContext.Provider value={token}>
                <CarrinhoProvider>
                    <CartProvider>
                        <MenuContextProvider>
                            <ShowDivContext.Provider value={{ showDiv, setShowDiv }}>
                                <UserLogged.Provider value={{ userLogged, setUserLogged }}>
                                    <RouteChangeTracker />
                                    <AppRoutes showDiv={showDiv} setShowDiv={setShowDiv} />
                                </UserLogged.Provider>
                            </ShowDivContext.Provider>
                        </MenuContextProvider>
                    </CartProvider>
                </CarrinhoProvider>
            </TokenContext.Provider>
        </BrowserRouter>
    );
};
