import { Link, Typography } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import { TitleComponent } from "app/shared/components"
import './MateriaisAgRoss.css'
import DownloadIcon from '@mui/icons-material/Download';
const primary = lightGreen[800];




export const MateriaisAgRoss = () => {
    const arquivos = [
        { nome: 'Manual de Identidade Visual (PDF)', caminho: 'assets/materiais/Manual_de_Identidade_Visual.pdf' },
        { nome: 'Logo Agross (Vetor)', caminho: 'assets/materiais/Logo_Agross.pdf' },
        { nome: 'Sub Logo (Vetor)', caminho: 'assets/materiais/Sublogo AgRoss.pdf' },
        { nome: 'Logos (PNG)', caminho: 'assets/materiais/Logos_PNG.zip' },
        { nome: 'Outros Logos (Vetor)', caminho: 'assets/materiais/outros-Logos.pdf' },
        { nome: 'Revenda Legal (PDF)', caminho: 'assets/materiais/revendalegal.pdf' }
    ];
    return (
        <div className=" d-flex flex-column">
            <TitleComponent texto="Materiais AgRoss" />

            <div className="mx-auto mb-5">
                {arquivos.map((arquivo, index) => (
                    <Typography key={index} style={{ cursor: 'pointer', marginTop: '1rem' }}>
                        <Link href={arquivo.caminho} color={primary} download style={{ textDecoration: 'none' }} className="linkdownload">
                            <DownloadIcon />{arquivo.nome}
                        </Link>
                    </Typography>
                ))}
            </div>

            <div style={{ width: '80%', height: '42rem', marginTop: '1rem', marginBottom: '12rem' }} className=" mx-auto">
                <TitleComponent texto={'Revista Troque e Ganhe 13ª Edição'} />
                <iframe
                    src="https://online.pubhtml5.com/psjd/eruc/"
                    title="Revista"
                    width="100%"
                    height="75%"
                    style={{ border: 'none' }}
                    allowFullScreen
                ></iframe>
            </div>

        </div>
    )
}