import React, { useState, useEffect } from 'react';
import { CronometroTv, TitleComponent } from 'app/shared/components';
import './TvAgross.css'
import {
    Typography,
    // TextField, RadioGroup, FormControlLabel, Radio, 
    Accordion, AccordionSummary, AccordionDetails, Link
} from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import YouTube from 'react-youtube';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
// import MenuItem from '@mui/material/MenuItem';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Element } from 'react-scroll';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import { useMenuContext } from 'app/shared/contexts';

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';




// function formatPhoneNumber(value: string) {
//     const phoneNumber = parsePhoneNumberFromString(value, 'BR'); // Assumindo 'BR' para Brasil
//     if (!phoneNumber) {
//         return value;
//     }
//     return phoneNumber.formatNational();
// }

// const currencies = [
//     {
//         value: 'Campinas',
//         label: 'Campinas',
//     },
//     {
//         value: 'Carazinho',
//         label: 'Carazinho',
//     },
//     {
//         value: 'Cariacica',
//         label: 'Cariacica',
//     },
//     {
//         value: 'Goiás',
//         label: 'Goiás',
//     },
//     {
//         value: 'Lins',
//         label: 'Lins',
//     },
//     {
//         value: 'Pouso Alegre',
//         label: 'Pouso Alegre',
//     },
//     {
//         value: 'Petrolina',
//         label: 'Petrolina',
//     },
//     {
//         value: 'Sete Lagoas',
//         label: 'Sete Lagoas',
//     },
// ];
interface AccordionItemProps {
    title: string;
    content: string;
    isOpen: boolean;
    onTitleClick: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, content, isOpen, onTitleClick }) => {

    return (
        <Accordion expanded={isOpen} onChange={onTitleClick}>
            <AccordionSummary
                style={{ backgroundColor: isOpen ? verdeescuro : bege }}
                expandIcon={<ExpandMoreIcon style={{ color: isOpen ? bege : verdeescuro }} />}
            >
                <Typography fontFamily={'Arima Madurai'} variant='h6' style={{ color: isOpen ? bege : verdeescuro }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ color: verdeescuro }}>{content}</Typography>
            </AccordionDetails>
        </Accordion>
    );
};


export const TvAgross = () => {
    // const [focus, setFocus] = useState(false);
    const Mobile = useIsMobile();
    // const [value, setValue] = useState("");
    // const inputRef = useRef<HTMLInputElement | null>(null);
    const { scrollToParticipar, setScrollToParticipar, isCompleted, setScrollToPerguntastv, scrollToPerguntastv, scrollToVideostv, setScrollToVideostv } = useMenuContext();

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const re = /^[0-9\b]+$/;

    //     // Se o valor não for vazio, testa a regex
    //     if (event.target.value === '' || re.test(event.target.value)) {
    //         setValue(formatPhoneNumber(event.target.value));
    //     }
    // };

    /* const handleRightClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        event.preventDefault();
        alert('Right-click is disabled.');
    } */

    const opts = {
        height: '450',
        width: '800',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const optsmobile = {
        height: '240rem',
        width: '380rem',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const videoId = 'KIqM7130n74'; // ID do vídeo do YouTube



    const videoOptions = {
        height: '265',
        width: '470',
        playerVars: {
            autoplay: 0,
        },
    };

    const videoOptionsMobile = {
        height: '250rem',
        width: '400rem',
        playerVars: {
            autoplay: 0,
        },
    };

    const videoIds = [
        'XtY3kF--wiY',
        'xB_ZyExL88M',
        'qa09h_RuWK4',
        '23GPpA6zo2s',
        'xRXSJAfAHR4',
        'OaFtSJ0YSlI',
        'UicRvh9hp4k',
    ];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const [openAccordion, setOpenAccordion] = useState<string | null>(null);

    const handleAccordionChange = (title: string) => {
        setOpenAccordion(openAccordion === title ? null : title);
    };

    useEffect(() => {
        if (scrollToParticipar) {
            // Adiar a execução até a próxima 'tarefa' para garantir que todos os renders tenham sido executados
            setTimeout(() => {
                const element = document.getElementById('participar');
                if (element) {
                    window.scrollTo({
                        top: element.getBoundingClientRect().top + window.scrollY - 80,
                        behavior: 'smooth',
                    });
                }

                setScrollToParticipar(false); // resetar para falso depois de rolar
            }, 0); // Isso efetivamente coloca isso no final da fila de execução, após renders
        }
    }, [scrollToParticipar, setScrollToParticipar]);
    useEffect(() => {
        if (scrollToPerguntastv) {
            // Adiar a execução até a próxima 'tarefa' para garantir que todos os renders tenham sido executados
            setTimeout(() => {
                const element = document.getElementById('perguntastv');
                if (element) {
                    window.scrollTo({
                        top: element.getBoundingClientRect().top + window.scrollY - 80,
                        behavior: 'smooth',
                    });
                }

                setScrollToPerguntastv(false); // resetar para falso depois de rolar
            }, 0); // Isso efetivamente coloca isso no final da fila de execução, após renders
        }
    }, [scrollToPerguntastv, setScrollToPerguntastv]);
    useEffect(() => {
        if (scrollToVideostv) {
            // Adiar a execução até a próxima 'tarefa' para garantir que todos os renders tenham sido executados
            setTimeout(() => {
                const element = document.getElementById('videostv');
                if (element) {
                    window.scrollTo({
                        top: element.getBoundingClientRect().top + window.scrollY - 80,
                        behavior: 'smooth',
                    });
                }

                setScrollToVideostv(false); // resetar para falso depois de rolar
            }, 0); // Isso efetivamente coloca isso no final da fila de execução, após renders
        }
    }, [scrollToVideostv, setScrollToVideostv]);

    return (
        <div>
            {isCompleted ?
                <div className=' d-flex flex-column'>
                    <Link href='https://tvagross.com.br/inicio' target='_blank' style={{ textDecoration: 'none' }}>
                        <TitleComponent texto='A Tv AgRoss está' />
                    </Link>
                    <div className=' d-flex mx-auto'>
                        <Link href='https://tvagross.com.br/inicio' target='_blank'>
                            <img src={require('app/shared/components/flipclock/noar.gif')} alt="" style={{ width: Mobile ? '25rem' : '30rem' }} />
                        </Link>
                    </div>
                </div>
                :
                <div>
                    <a href="https://tvagross.com.br/inicio" target='blank'>
                        {Mobile ?

                            <img src={require('../../../shared/components/carrouselag/03 Banner Site Mobile.png')} alt="" width={'100%'} />
                            :
                            <img src={require('../../../shared/components/carrouselag/02 Banner Site.png')} alt="" width={'100%'} />
                        }
                    </a>
                    <div className=" d-flex flex-column mt-5">
                        <Typography fontFamily={'Arima Madurai'} variant={Mobile ? 'h5' : 'h3'} style={{ color: verdeescuro, textAlign: 'center' }}>
                            A Tv AgRoss começa em:
                        </Typography>
                        <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-4" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                    <CronometroTv />
                </div>

            }




            {/* <div style={{ backgroundColor: bege }} className=' py-4'>
                <div className=" d-flex flex-column mt-5">
                    <Typography fontFamily={'Arima Madurai'} variant={Mobile ? 'h5' : 'h3'} style={{ color: verdeescuro, textAlign: 'center', width: Mobile ? '80%' : 'auto', margin: 'auto' }}>
                        Seja o primeiro a saber das novidades da TV AgRoss
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
                <div className='divformtv d-flex justify-content-between flex-wrap mb-5' style={{ width: Mobile ? '90%' : '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <TextField
                        required
                        style={{ width: Mobile ? '100%' : '48%' }}
                        className={Mobile ? 'my-3' : ''}
                        id="filled-required"
                        label="Informe seu Whatsapp"
                        variant="filled"
                        value={value}
                        onChange={handleChange}
                        InputProps={{
                            onFocus: () => setFocus(true),
                            onBlur: () => setFocus(false),
                            inputRef: inputRef,
                        }}
                        InputLabelProps={{
                            shrink: focus || value !== "",
                        }}
                    />
                    <div className=' d-flex align-items-center justify-content-around' style={{ width: Mobile ? '100%' : '48%', backgroundColor: '#e8e2dc', borderBottom: `1px solid ${primary}` }}>
                        <div>
                            <Typography className=' text-center' style={{ color: primary }}>
                                Já é nosso cliente?
                            </Typography>
                        </div>
                        <div>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                style={{ marginLeft: 'auto', marginRight: 'auto', color: primary }}
                            >
                                <FormControlLabel value="S" control={<Radio />} label="Sim" />
                                <FormControlLabel value="N" control={<Radio />} label="Não" />


                            </RadioGroup>
                        </div>
                    </div>
                    <TextField
                        required
                        fullWidth={true}
                        id="razaosocial"
                        label="Razão Social"
                        variant="filled"
                        className='my-3'
                    />
                    <TextField
                        required
                        id="idcidade"
                        label="Cidade"
                        variant="filled"
                        style={{ width: '48%' }}
                    />
                    <TextField
                        style={{ width: '48%' }}
                        id="outlined-select-currency"
                        select
                        required
                        label="Unidade AgRoss"
                        defaultValue="Campinas"
                        variant='filled'
                    >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div> */}
            <Element name="participar" id="participar">
                <div className=' d-flex flex-column py-4'>
                    <div className=" d-flex flex-column mt-5">
                        <Typography fontFamily={'Arima Madurai'} variant={Mobile ? "h4" : "h3"} style={{ color: verdeescuro, textAlign: 'center' }}>
                            Como Participar
                        </Typography>
                        <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                    <div className='mb-5 mx-auto'>
                        <YouTube videoId={videoId} opts={Mobile ? optsmobile : opts} />
                    </div>


                    {/* <div className='mb-5 mx-auto' style={{ position: 'relative' }}>
                        <YouTube videoId={videoId} opts={Mobile ? optsmobile : opts} />
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} onContextMenu={handleRightClick}></div>
                    </div> */}


                </div>
            </Element>

            <Element name="videostv" id="videostv">
                <div style={{ backgroundColor: bege }} className=' py-4 d-flex flex-column'>

                    <TitleComponent texto="O que já rolou na Tv&nbsp;AgRoss" variant={Mobile ? 'h4' : 'h3'} />

                    <div style={{ width: '96%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Carousel responsive={responsive} infinite={true}>
                            {videoIds.map((videoId, index) => (
                                <div key={index} className={`${Mobile ? 'd-flex' : ''}`}>
                                    <YouTube videoId={videoId} opts={Mobile ? videoOptionsMobile : videoOptions} className={`${Mobile ? 'mx-auto' : ''}`} />
                                </div>
                            ))}
                        </Carousel>
                    </div>

                    <TitleComponent texto="Se inscreva no nosso canal para ver mais vídeos." variant="h4" exibirImagem={false} />

                    <a href="http://www.youtube.com/channel/UCGMJIULAaiP0HvX3SiJ7eRQ?sub_confirmation=1" target='blank' style={{ marginLeft: 'auto', marginRight: 'auto', width: Mobile ? '40%' : '15%' }} className='mb-5'>
                        <img src={require('assets/tvagross/inscyoutube.png')} alt="" className=" my-3" style={{ width: '100%' }} />
                    </a>


                </div>
            </Element>


            <Element name="perguntastv" id="perguntastv">
                <div className=' d-flex flex-column py-4'>
                    <TitleComponent texto='Perguntas Frequentes' variant={Mobile ? 'h4' : 'h3'} />

                    <div className=' d-flex justify-content-around my-5' style={{ width: Mobile ? '95%' : '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ width: Mobile ? '90%' : '70%' }}>
                            <AccordionItem
                                title="Porque minhas notas não aparecem?"
                                content="Apenas notas fiscais de vendas emitidas em um período definido antes da data do sorteio serão válidas. Em cada programa da TV Agross, o período exato de validade das notas será informado. Caso suas notas estejam dentro do período informado e mesmo assim não estão aparecendo, por favor entre em contato com o Gerente que lhe atende."
                                isOpen={openAccordion === "Question 1"}
                                onTitleClick={() => handleAccordionChange("Question 1")}
                            />
                            <AccordionItem
                                title="Como funciona o sorteio?"
                                content="Em cada rodada, é sorteado a Centena e a Milhar das notas. É necessário que o cliente verifique os 3 (na centena) últimos números da Nota Fiscal, ou os 4 (na milhar). As Notas Fiscais válidas aparecem ao lado direito da sua tela. Caso você tenha a sequência dos 3 ou 4 últimos números sorteados, basta clicar em cima da nota fiscal. Aparecerá uma mensagem de confirmação em baixo. Se houver mais de um ganhador, será feito o desempate por um sorteio de bola maior."
                                isOpen={openAccordion === "Question 2"}
                                onTitleClick={() => handleAccordionChange("Question 2")}
                            />
                            <AccordionItem
                                title="Somente o dono da revenda pode estar logado para participar da TV AgRoss?"
                                content="Não, qualquer pessoa pode entrar para assistir nossa programação, porém, para participar dos sorteios é necessário que você seja cliente AgRoss e faça login na página. *Funcionários AgRoss não podem acessar e participar dos sorteios da Tv como cliente."
                                isOpen={openAccordion === "Question 3"}
                                onTitleClick={() => handleAccordionChange("Question 3")}
                            />
                            <AccordionItem
                                title="Preciso estar assistindo a TV ao vivo para participar do sorteio?"
                                content="Sim, o sorteio é válido apenas para clientes AgRoss conectados e antenados."
                                isOpen={openAccordion === "Question 4"}
                                onTitleClick={() => handleAccordionChange("Question 4")}
                            />

                            <AccordionItem
                                title="Fui premiado e agora?"
                                content="O prêmio será entregue no endereço cadastrado da revenda."
                                isOpen={openAccordion === "Question 5"}
                                onTitleClick={() => handleAccordionChange("Question 5")}
                            />
                            <AccordionItem
                                title="Como envio minha foto para aparecer ao vivo na TV?"
                                content="Envie sua foto NA HORIZONTAL pelo Whatsapp (19) 9 9388-0615 e informe o NOME DA REVENDA, CIDADE e NOME DAS PESSOAS que aparecem na foto."
                                isOpen={openAccordion === "Question 6"}
                                onTitleClick={() => handleAccordionChange("Question 6")}
                            />
                            <AccordionItem
                                title="Se o número da minha nota fiscal for sorteado, vocês me avisam?"
                                content="Não, você deve estar logado e verificar se as notas estão aparecendo na tela, caso sua nota seja sorteada, você terá 30 segundos para clicar em cima dela e depois é só aguardar."
                                isOpen={openAccordion === "Question 7"}
                                onTitleClick={() => handleAccordionChange("Question 7")}
                            />
                            <AccordionItem
                                title="Não consigo entrar com Login e Senha para participar da TV, o que devo fazer?"
                                content="Somente clientes da AgRoss podem participar. Se você já é nosso cliente, por favor entre em contato com o Gerente que lhe atende e informe a situação."
                                isOpen={openAccordion === "Question 8"}
                                onTitleClick={() => handleAccordionChange("Question 8")}
                            />
                        </div>
                    </div>
                </div>
            </Element>

            <div style={{ backgroundColor: bege }} className=' py-4 d-flex flex-column'>

                <TitleComponent texto="Saiba Mais" variant={Mobile ? 'h4' : 'h3'} />
                <Typography style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', color: primary, textAlign: 'justify' }} className='mb-5'>
                    A TV Agross no ar desde 2015, tem executado com êxito seus programas ao vivo para mais de 1.800 revendas, sob o comando de profissionais experientes da televisão e equipamentos de qualidade, oferecendo conteúdo adequado e enriquecedor para que os telespectadores possam estreitar ainda mais a ponte de informações técnicas e mercadológicas entre fabricantes, revendas e produtores. Durante o programa são sorteados Carros, Motos, Smart TVs e muito mais.
                </Typography>
            </div>
        </div>



    )
}