import { createContext, useContext, useState } from "react";

interface MenuContextValue {
  selectedItem: string | null;
  setSelectedItem: (item: string | null) => void;
  scrollToDepoimentos: boolean;
  setScrollToDepoimentos: (scroll: boolean) => void;
  scrollToParticipar: boolean;
  setScrollToParticipar: (scroll: boolean) => void;
  scrollToVideostv: boolean;
  setScrollToVideostv: (scroll: boolean) => void;
  scrollToPerguntastv: boolean;
  setScrollToPerguntastv: (scroll: boolean) => void;
  scrollToPerguntastg: boolean;
  setScrollToPerguntastg: (scroll: boolean) => void;
  hiddenMenuVisible: boolean;
  setHiddenMenuVisible: (item: boolean) => void;
  hiddenMenutgVisible: boolean;
  setHiddenMenutgVisible: (item: boolean) => void;
  isCompleted: boolean;
  setIsCompleted: (item: boolean) => void;
  open: boolean;
  setOpen: (item: boolean) => void;
  opentg: boolean;
  setOpentg: (item: boolean) => void;
  nome1pessoa: string | null;
  setnome1pessoa: (item: string | null) => void;
  nome2pessoa: string | null;
  setnome2pessoa: (item: string | null) => void;
  cpf1pessoa: string | null;
  setcpf1pessoa: (item: string | null) => void;
  cpf2pessoa: string | null;
  setcpf2pessoa: (item: string | null) => void;
  data1pessoa: string | null;
  setdata1pessoa: (item: string | null) => void;
  data2pessoa: string | null;
  setdata2pessoa: (item: string | null) => void;
  origem: string | null;
  setorigem: (item: string | null) => void;
  destino: string | null;
  setdestino: (item: string | null) => void;
  obs: string | null;
  setobs: (item: string | null) => void;
  openConsulta: boolean;
  setOpenConsulta: (item: boolean) => void;
  razaosocial: string | null;
  setRazaoSocial: (item: string | null) => void;
}


interface MenuContextProviderProps {
  children: React.ReactNode;
}

const MenuContext = createContext<MenuContextValue | undefined>(undefined);

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenuContext must be used within a MenuContextProvider");
  }
  return context;
};

export const MenuContextProvider: React.FC<MenuContextProviderProps> = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [scrollToDepoimentos, setScrollToDepoimentos] = useState(false);
  const [scrollToParticipar, setScrollToParticipar] = useState(false);
  const [scrollToVideostv, setScrollToVideostv] = useState(false);
  const [scrollToPerguntastv, setScrollToPerguntastv] = useState(false);
  const [scrollToPerguntastg, setScrollToPerguntastg] = useState(false);
  const [hiddenMenuVisible, setHiddenMenuVisible] = useState(false);
  const [hiddenMenutgVisible, setHiddenMenutgVisible] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [opentg, setOpentg] = useState(false);
  const [nome1pessoa, setnome1pessoa] = useState<string | null>(null);
  const [nome2pessoa, setnome2pessoa] = useState<string | null>(null);
  const [cpf1pessoa, setcpf1pessoa] = useState<string | null>(null);
  const [cpf2pessoa, setcpf2pessoa] = useState<string | null>(null);
  const [data1pessoa, setdata1pessoa] = useState<string | null>(null);
  const [data2pessoa, setdata2pessoa] = useState<string | null>(null);
  const [origem, setorigem] = useState<string | null>(null);
  const [destino, setdestino] = useState<string | null>(null);
  const [obs, setobs] = useState<string | null>(null);
  const [openConsulta, setOpenConsulta] = useState(false);
  const [razaosocial, setRazaoSocial] = useState<string | null>(null);

  return (
    <MenuContext.Provider value={{
      selectedItem, setSelectedItem, scrollToDepoimentos, setScrollToDepoimentos, scrollToParticipar,
      setScrollToParticipar, scrollToVideostv, setScrollToVideostv, scrollToPerguntastv, setScrollToPerguntastv, scrollToPerguntastg,
      setScrollToPerguntastg, hiddenMenuVisible, setHiddenMenuVisible, hiddenMenutgVisible, setHiddenMenutgVisible,
      isCompleted, setIsCompleted, open, setOpen, opentg, setOpentg, nome1pessoa, setnome1pessoa, nome2pessoa, setnome2pessoa,
      cpf1pessoa, setcpf1pessoa, cpf2pessoa, setcpf2pessoa, data1pessoa, setdata1pessoa, data2pessoa, setdata2pessoa, origem, setorigem,
      destino, setdestino, obs, setobs, openConsulta, setOpenConsulta, razaosocial, setRazaoSocial
    }}>
      {children}
    </MenuContext.Provider>
  );
};
