



export const Regulamento = () => {

    return (
        <div>
            
            <h1>Regulamento</h1>
            
            
        </div>



    )
}