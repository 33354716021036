import React, { useState } from "react";
import { Box, Button, TextField, Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useMenuContext } from "app/shared/contexts";
import './styleConsulta.css';

const bege = '#F7F0EA';

export const Consulta = () => {
    const { nome1pessoa, setnome1pessoa } = useMenuContext();
    const { cpf1pessoa, setcpf1pessoa } = useMenuContext();
    const { data1pessoa, setdata1pessoa } = useMenuContext();
    const { origem, setorigem } = useMenuContext();
    const { nome2pessoa, setnome2pessoa } = useMenuContext();
    const { cpf2pessoa, setcpf2pessoa } = useMenuContext();
    const { data2pessoa, setdata2pessoa } = useMenuContext();
    const { destino, setdestino } = useMenuContext();
    const { obs, setobs } = useMenuContext();
    const { setOpenConsulta } = useMenuContext();

    interface ErrorState {
        nome1pessoa: boolean;
        cpf1pessoa: boolean;
        data1pessoa: boolean;
        origem: boolean;
    }

    const [errors, setErrors] = useState<ErrorState>({
        nome1pessoa: false,
        cpf1pessoa: false,
        data1pessoa: false,
        origem: false
    });

    const [loading, setLoading] = useState(false);

    const cnpj = localStorage.getItem('cliente_cnpj');
    const { razaosocial } = useMenuContext();

    const handleCloseConsulta = () => {
        setOpenConsulta(false);
    };

    interface Setters {
        nome1pessoa: React.Dispatch<string | null>;
        nome2pessoa: React.Dispatch<string | null>;
        cpf1pessoa: React.Dispatch<string | null>;
        cpf2pessoa: React.Dispatch<string | null>;
        data1pessoa: React.Dispatch<string | null>;
        data2pessoa: React.Dispatch<string | null>;
        origem: React.Dispatch<string | null>;
        destino: React.Dispatch<string | null>;
        obs: React.Dispatch<string | null>;
    }

    const handleConsultaChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: keyof Setters) => {
        const setters: Setters = {
            nome1pessoa: setnome1pessoa,
            nome2pessoa: setnome2pessoa,
            cpf1pessoa: setcpf1pessoa,
            cpf2pessoa: setcpf2pessoa,
            data1pessoa: setdata1pessoa,
            data2pessoa: setdata2pessoa,
            origem: setorigem,
            destino: setdestino,
            obs: setobs
        };

        const setFieldValue = setters[fieldName];
        if (setFieldValue) {
            const value = event.target.value;
            setFieldValue(value ? value : null);
        }

        // Remove erro do campo se preenchido
        if (fieldName in errors) {
            setErrors(prev => ({
                ...prev,
                [fieldName]: false
            }));
        }
    };

    const handleEnviarConsulta = () => {
        const requiredFields = { nome1pessoa, cpf1pessoa, data1pessoa, origem };

        const newErrors: ErrorState = {
            nome1pessoa: !requiredFields.nome1pessoa,
            cpf1pessoa: !requiredFields.cpf1pessoa,
            data1pessoa: !requiredFields.data1pessoa,
            origem: !requiredFields.origem
        };

        if (Object.values(newErrors).some(error => error)) {
            setErrors(newErrors);

            Swal.fire({
                title: 'Campos incompletos',
                text: 'Todos os campos obrigatórios devem ser preenchidos.',
                icon: 'warning',
                customClass: {
                    popup: 'custom-swal-popup'
                }
            });

            return;
        }

        setLoading(true); // Mostra o loading

        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/sendViagem.php?cnpj=${cnpj}&razao=${razaosocial}&nome=${nome1pessoa}&nascimento=${data1pessoa}&destino=${destino}&origem=${origem}&cpf=${cpf1pessoa}&nome2=${nome2pessoa}&nascimento2=${data2pessoa}&cpf2=${cpf2pessoa}&observacao=${obs}`);
                handleCloseConsulta();
                console.log(response);
                if (response.status === 200) {
                    console.log(response);
                }
                Swal.fire(
                    'Consulta',
                    'Enviada com sucesso!',
                    'success'
                );
            } catch (error) {
                Swal.fire(
                    'Erro',
                    'Houve um problema ao enviar a consulta.',
                    'error'
                );
            } finally {
                setLoading(false); // Esconde o loading
            }
        };

        fetchData();
    };

    return (
        <div className="custom-modal" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '45vw', boxShadow: '24', background: bege, padding: 20, borderRadius: '0.4rem', display: 'flex' }}>
            <Box
                component="form"
                sx={{
                    margin: 'auto',
                }}
                noValidate
                autoComplete="off"
            >
                <div style={{ width: '40vw' }} className="d-flex flex-column mx-auto">
                    <div className="my-1 mx-auto" style={{ width: '100%' }}>
                        <TextField
                            id="outlined-basic"
                            label="Nome 1ª Pessoa"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'nome1pessoa')}
                            className={errors.nome1pessoa ? 'input-error' : ''}
                        />
                    </div>
                    <div className="my-2 d-flex justify-content-between">
                        <TextField
                            id="outlined-basic"
                            label="CPF 1ª Pessoa"
                            variant="outlined"
                            style={{ width: '70%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'cpf1pessoa')}
                            className={errors.cpf1pessoa ? 'input-error' : ''}
                        />
                        <TextField
                            type="date"
                            label="Nasc. 1ª Pessoa"
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: '28%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'data1pessoa')}
                            className={errors.data1pessoa ? 'input-error' : ''}
                        />
                    </div>
                    <div className="my-1 mx-auto" style={{ width: '100%' }}>
                        <TextField
                            id="outlined-basic"
                            label="Origem"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'origem')}
                            className={errors.origem ? 'input-error' : ''}
                        />
                    </div>
                    <div className="my-1 mx-auto" style={{ width: '100%' }}>
                        <TextField
                            id="outlined-basic"
                            label="Nome 2ª Pessoa"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'nome2pessoa')}
                        />
                    </div>
                    <div className="my-2 d-flex justify-content-between">
                        <TextField
                            id="outlined-basic"
                            label="CPF 2ª Pessoa"
                            variant="outlined"
                            style={{ width: '70%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'cpf2pessoa')}
                        />
                        <TextField
                            type="date"
                            label="Nasc. 2ª Pessoa"
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: '28%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'data2pessoa')}
                        />
                    </div>
                    <div className="my-1 mx-auto" style={{ width: '100%' }}>
                        <TextField
                            id="outlined-basic"
                            label="Destino"
                            disabled
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={destino}
                        />
                    </div>
                    <div className="my-1 mx-auto" style={{ width: '100%' }}>
                        <TextField
                            id="outlined-basic"
                            label="Observação"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e: any) => handleConsultaChange(e, 'obs')}
                        />
                    </div>
                    <div className="d-flex justify-content-between w-100">
                        <Button onClick={handleCloseConsulta} variant="contained" className="mt-2" style={{ width: '48%', backgroundColor: '#dc3545' }} disabled={loading}>Cancelar</Button>
                        <Button onClick={handleEnviarConsulta} variant="contained" className="mt-2" style={{ width: '48%', backgroundColor: '#198754' }} disabled={loading}>Enviar Consulta</Button>
                    </div>
                </div>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
