import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Fornecedores.css';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const nomesDeImagens = [
  'ADAMA',
  'ALBAUGH',
  'ATTAKILL',
  'BASF',
  'BAYER',
  'BEQUISA',
  'BREVANT',
  'CCAB',
  'CORTEVA',
  'FMC',
  'GOWAN',
  'NORTOX',
  'OUROFINO',
  'OXIQUIMICA',
  'PRENTISS',
  'SAKATA',
  'SEMINIS',
  'SIPCAMNICHINO',
  'SUMITOMO',
  'UPL'
];
const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <ArrowBackIosIcon
      className="slick-next custom-next"
      onClick={onClick}
    />
  );
};

const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <ArrowForwardIosIcon
      className="slick-prev custom-prev"
      onClick={onClick}
    />
  );
};




export const Fornecedores = () => {
  // const [autoplay, setAutoplay] = useState(true);
  const Mobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: true,
    // beforeChange: () => {
    //   setAutoplay(false);
    // },
    // afterChange: () => {
    //   setAutoplay(true);
    // },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    
  };

  const settingsmobile = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    // beforeChange: () => {
    //   setAutoplay(false);
    // },
    // afterChange: () => {
    //   setAutoplay(true);
    // },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const renderSlides = () =>
    nomesDeImagens.map((nome, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center', height: '300px' }} className='sliderfornecedores'>
        <img
          src={require(`assets/fornecedores/${nome}.png`)} // use template string para interpolar o caminho
          alt={`imagem ${index + 1}`}
          width={'200px'}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
      </div>
    ));

  return (
    <div>
      {Mobile ?
        <Slider {...settingsmobile} className='d-flex align-items-center'>
          {renderSlides()}
        </Slider>
        :
        <Slider {...settings} className='d-flex align-items-center'>
          {renderSlides()}
        </Slider>
      }
    </div>

  );
};


