import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';

interface ShareButtonProps {
  platform: 'facebook' | 'whatsapp' | 'imprimir';
  url: string;
  title: string;
}

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const ShareButton: React.FC<ShareButtonProps> = ({ platform, url, title }) => {
  const handleClick = () => {
    switch (platform) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%0A${encodeURIComponent(url)}`, '_blank');
        break;
      case 'imprimir':
        window.print();
        break;
      default:
        break;
    }
  };

  return (
    <Button variant="outlined" color="primary" onClick={handleClick} className=' m-2'>
      {platform.charAt(0).toUpperCase() + platform.slice(1)}
    </Button>
  );
};

interface ShareButtonsProps {
  url: string;
  title: string;
}

export const ShareButtons: React.FC<ShareButtonsProps> = ({ url, title }) => {
  return (
    <StyledButtonGroup variant="contained">
      <ShareButton platform="facebook" url={url} title={title} />
      <ShareButton platform="whatsapp" url={url} title={title} />
      <ShareButton platform="imprimir" url={url} title={title} />
    </StyledButtonGroup>
  );
};
