import React, { useState } from 'react';
import { Grid } from '@mui/material';
import useIsMobile from 'app/shared/hooks/useIsMobile';

type Photo = {
    src: string;
    alt: string;
};

type PhotoGalleryProps = {
    photos: Photo[];
};

function PhotoGallery({ photos }: PhotoGalleryProps) {
    const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
    const Mobile = useIsMobile();
    const closeModal = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            setSelectedPhoto(null);
        }
    };
    return (
        <div>
            <Grid container spacing={3}>
                {photos.map((photo: Photo, index: number) => (
                    <Grid item xs={Mobile ? 12 : 4} key={index}>
                        <img
                            src={photo.src}
                            alt={photo.alt}
                            style={{ width: '100%', cursor: 'pointer' }}
                            onClick={() => setSelectedPhoto(photo.src)}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Lightbox (usando Bootstrap modal) */}
            {selectedPhoto && (
                <div
                    className="modal show"
                    tabIndex={-1}
                    style={{ display: 'block', backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                    onClick={closeModal}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedPhoto(null)}></button>
                            </div>
                            <div className="modal-body" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
                                <img
                                    src={selectedPhoto}
                                    alt="Selected"
                                    style={{ width: Mobile ? '90%' : '100%', maxHeight: '80vh', objectFit: 'contain' }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PhotoGallery;
