



export const PerguntasFrequentes = () => {

    return (
        <div>
            
            <h1>Perguntas Frequentes</h1>
            
            
        </div>



    )
}