/// <reference types="webpack-env" />
import { Typography } from "@mui/material"
import { lightGreen } from '@mui/material/colors';


import useIsMobile from "app/shared/hooks/useIsMobile";
import PhotoGallery from "app/shared/components/galeria/Galeria";



const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';


const getTitleFromFilename = (filename: string): string => {
    const withoutExtension = filename.replace(/\.[^/.]+$/, '');
    return withoutExtension.replace(/[-_]/g, ' ');
};

// Crie um objeto para armazenar os títulos que já foram processados
const processedTitles: { [key: string]: boolean } = {};

const imageContext: __WebpackModuleApi.RequireContext = require.context(
    'assets/nossahistoria/',
    false,
    /\.(png|jpe?g|svg)$/,
);

const itemData = imageContext.keys().reduce((items, imageKey: string) => {
    const imgModule = imageContext(imageKey);
    const imgSrc = imgModule.default || imgModule;
    const alt = getTitleFromFilename(imageKey.split('/').pop() as string);

    // Verifique se o título já existe no objeto processedTitles
    if (!processedTitles[alt]) {
        // Adicione o título ao array de títulos únicos
        items.push({
            src: imgSrc,
            alt: imgSrc,
        });

        // Marque o título como processado no objeto processedTitles
        processedTitles[alt] = true;
    }

    return items;
}, [] as { src: string; alt: string }[]);





export const NossaHistoria = () => {
    const Mobile = useIsMobile();
    return (
        <div className="mt-5 d-flex flex-column">
            <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className=" d-flex flex-column">
                    <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro, textAlign: 'center' }}>
                        História AgRoss
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>

                <Typography style={{ color: verdeescuro, textAlign: 'justify' }}>
                    Fundada em agosto de 1997, a AgRoss é fruto do espirito inovador do Engenheiro Agrônomo Luiz Rossi Neto. Empenhado em consolidar um novo conceito de distribuição no segmento agro-químico, investiu em pesquisa e fez uso de sua vocação agrícola para lançar no mercado um canal atacadista para redistribuição de produtos para o campo.
                    A AgRoss é pioneira no Brasil na profissionalização do mercado atacadista de agroquímicos e na criação de um modelo exclusivo e especializado para este segmento, com o conceito de agregar valores intangíveis ao produto físico, percebido e efetivado na cadeia de agronegócio.
                </Typography>
                <div className={`d-flex ${Mobile ? 'flex-column' : ''}`}>
                    <div style={{ paddingRight: Mobile ? '0' : '2rem' }}>
                        <Typography style={{ color: verdeescuro, textAlign: 'justify' }}>

                            O grande valor somado pela AgRoss ao segmento agronômico foi o de conseguir levar tecnologia aos mais distantes cantos do Brasil, através de um modelo inovador de logística física, técnica e ambiental, disponibilizando em 14 estados do Brasil o que há de melhor em tecnologia de agroquímicos para pequenas revendas e consequentemente aos pequenos produtores, ou seja, disponibilizando acesso tecnológico ao minifúndio e incluindo os mesmos no mundo moderno da agricultura sustentável.
                            Este modelo pioneiro criou uma capilaridade eficiente que fez arrebanhar mais de 1800 revendas agropecuárias, sendo uma extensão importante de comunicação para o agronegócio brasileiro, mais especificamente ao pequeno produtor, substituindo moléculas ultrapassadas de agroquímicos por outras mais eficientes e menos agressivas ao homem e ao meio ambiente.
                        </Typography>
                    </div>
                    <div>
                        <img src={require('assets/nossahistoria/nossahistoriacapa/nossahistoria.jpg')} alt="" className=" mt-3 w-100" />
                    </div>
                </div>


            </div>
            <div className=" mt-4 p-5 d-flex" style={{ backgroundColor: bege }}>
                <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className=" d-flex flex-column">
                        <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                            UMA GALERIA COM AS PRINCIPAIS PREMIAÇÕES
                        </Typography>
                        <Typography fontFamily={'Arima Madurai'} variant={Mobile ? "h4" : "h3"} style={{ color: verdeescuro, textAlign: 'center' }}>
                            Reconhecimento que reforça a excelência!
                        </Typography>
                        <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                    <div>
                        <PhotoGallery photos={itemData} />
                    </div>
                </div>
            </div>
            <div className={`d-flex ${Mobile ? 'flex-column' : 'justify-content-around'} my-5`} style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className={`${Mobile ? 'w-100 m-auto my-3' : 'w-25'}`}>
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Missão AgRoss
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Prover tecnologia para revendas agrícolas através da distribuição dos melhores produtos, garantindo a satisfação e longevidade dos nossos clientes e fornecedores.
                    </Typography>
                </div>
                <div className={`${Mobile ? 'w-100 m-auto my-3' : 'w-25'}`}>
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Visão
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Manter a liderança no mercado de redistribuição através da nossa lealdade em atender exclusivamente as revendas agrícolas com serviços sempre inovadores e que contribuam para o sucesso do nosso cliente.
                    </Typography>
                </div>
                <div className={`${Mobile ? 'w-100 m-auto my-3' : 'w-25'}`}>
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Valores
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Agilidade, Excelência, Inovação, Relacionamento, Responsabilidade, Valor justo e Compromisso.
                    </Typography>
                </div>
            </div>

            <div className=" mt-4 p-5 d-flex flex-column" style={{ backgroundColor: bege }}>
                <div className=" my-4">
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Agilidade
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Buscar sempre com muita rapidez a solução mais apropriada para o nosso cliente.
                    </Typography>
                </div>
                <div className=" my-4">
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Excelência
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Aprimorar processos e pessoas para garantir cada vez mais melhoria no atendimento e capacidade diante das oportunidades.
                    </Typography>
                </div>
                <div className=" my-4">
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Inovação
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Fazer sempre o que ninguém se propôs a fazer, com qualidade e criatividade sempre com a proposta de beneficiar as revendas agrícolas sem prejudicar o meio ambiente.
                    </Typography>
                </div>
                <div className=" my-4">
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Relacionamento
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Estimular sempre a interação entre clientes e colaboradores com posicionamento ético, afim de gerar resultados a longo prazo e cultura interna diferenciada.
                    </Typography>
                </div>
                <div className=" my-4">
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Responsabilidade
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Perpetuar cada vez mais as informações técnicas através dos canais de mídia existentes para melhorar a rentabilidade do cliente do nosso cliente, possibilitando abastecimento alimentício do nosso país sem prejudicar o meio ambiente.
                    </Typography>
                </div>
                <div className=" my-4">
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Valor justo
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        Oferecer preço e prazo compatível com nossos serviços para que ambas as partes consigam obter lucro e manter os negócios rentáveis e duradouro.
                    </Typography>
                </div>
                <div className=" my-4">
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Compromisso
                    </Typography>
                    <Typography style={{ color: verdeescuro, textAlign: 'center' }}>
                        É nosso compromisso contribuir para minimizar a fome no mundo. Por isso, nosso time comercial é um dos principais responsáveis na propagação de informações e técnicas inovadoras que permitam extrair da mesma área plantada, cada vez mais rentabilidade.
                    </Typography>
                </div>
            </div>

        </div>



    )
}