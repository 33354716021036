import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import useIsMobile from 'app/shared/hooks/useIsMobile';

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column'

};
const styleMobile = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column'

};

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['preferences', 'performance', 'advertising']);
  const [showBanner, setShowBanner] = useState(!cookies['preferences']); // Alterado para verificar somente as preferências
  const Mobile = useIsMobile();
  const [preferences, setPreferences] = useState(true);
  const [performance, setPerformance] = useState(true);
  const [advertising, setAdvertising] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveSettings = () => {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 3); // Adiciona 3 meses à data atual
    setCookie('preferences', preferences, { path: '/', expires });
    setCookie('performance', performance, { path: '/', expires });
    setCookie('advertising', advertising, { path: '/', expires });
    setShowBanner(false);
  };


  const handleAceitarTodos = () => {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 3);
    setCookie('preferences', true, { path: '/', expires });
    setCookie('performance', true, { path: '/', expires });
    setCookie('advertising', true, { path: '/', expires });
    setShowBanner(false);
  };

  const handleRejeitarTodos = () => {
    setCookie('preferences', false, { path: '/' });
    setCookie('performance', false, { path: '/' });
    setCookie('advertising', false, { path: '/' });
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div>
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: bege, padding: 2, color: verdeescuro, zIndex: 999 }} className=' d-flex flex-column'>
        <div className='w-75 mx-auto'>
          <Typography variant="body2" style={{ textAlign: 'justify' }}>
            Este site usa cookies para melhorar a segurança, o desempenho e entender melhor como os usuários navegam no site. Isso é feito para que possamos fazer melhorias em nosso site para futuros usuários.
            Acesse nossa <a href='/politica' style={{ textDecoration: 'none', color: 'red' }}>Política de Privacidade</a> para que você esteja ciente e de acordo com ela.
          </Typography>
        </div>

        <div className={`mx-auto ${Mobile ? 'd-flex flex-column' : 'mt-3 d-flex w-50'}`}>
          <Button variant="contained" style={{ backgroundColor: primary, width: '100%' }} onClick={handleAceitarTodos} size='small' className={`${Mobile ? 'mx-auto mt-3' : ''}`}>
            Aceitar Cookies
          </Button>
          <Button variant="outlined" onClick={handleOpen} className={`${Mobile ? 'mx-auto mt-3' : 'mx-3'}`} size='small' style={{ width: '100%' }} >
            Configurar Cookies
          </Button>

        </div>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Mobile ? styleMobile : style} color={verdeescuro}>
          <Typography fontFamily={'Arima Madurai'} variant='h4' className=' text-center'>
            Configurações de Cookies
          </Typography>
          <Typography className='mt-4 text-center' >
            Acesse nossa <a href='/politica' style={{ textDecoration: 'none', color: 'red' }}>Política de Privacidade</a> para mais informações sobre cada cookie.
          </Typography>
          <div className=' my-3 mx-auto'>
            <FormControlLabel
              control={<Checkbox checked={preferences} onChange={e => setPreferences(e.target.checked)} />}
              label="Preferências"
            />
            <FormControlLabel
              control={<Checkbox checked={performance} onChange={e => setPerformance(e.target.checked)} />}
              label="Desempenho e Análise"
            />
            <FormControlLabel
              control={<Checkbox checked={advertising} onChange={e => setAdvertising(e.target.checked)} />}
              label="Publicidade e Rastreamento"
            />
          </div>
          <div className={` ${Mobile ? '' : 'mx-auto'}`}>
            <Button variant="contained" style={{ backgroundColor: primary, width: '100%' }} onClick={handleAceitarTodos} >
              Aceitar Todos
            </Button>
            <Button variant="contained" style={{ backgroundColor: primary, width: '100%' }} onClick={handleSaveSettings} className={`${Mobile ? 'my-3' : 'my-3'}`}>
              Aceitar Cookies Selecionados
            </Button>
            <Button variant="outlined" style={{ color: primary, width: '100%' }} onClick={handleRejeitarTodos}>
              Rejeitar Cookies
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CookieConsent;
