import React, { useReducer, useState } from 'react';
import { CartContext, CartReducer } from './CartContext';

interface CartProviderProps {
    children: React.ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
    const [state, dispatch] = useReducer(CartReducer, { items: [] });
    const [pontosCliente, setPontosCliente] = useState('');

    return (
        <CartContext.Provider value={{ state, dispatch, pontosCliente, setPontosCliente }}>
            {children}
        </CartContext.Provider>
    );
};
