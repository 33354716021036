import './raspadinha.css'
import { TitleComponent } from 'app/shared/components'
import useIsMobile from 'app/shared/hooks/useIsMobile';
import { Typography } from '@mui/material'
import { lightGreen } from '@mui/material/colors';


const verdeescuro = lightGreen[900];

export const Raspadinha = () => {
    const Mobile = useIsMobile();
    return (
        <div className={`divraspadinha d-flex flex-column pt-5 `} style={{ paddingBottom: Mobile ? '12rem' : '' }}>
            <img src={require('assets/raspadinha/Mala-fora.png')} alt="" style={{ width: Mobile ? '80%' : '40%' }} className=' mx-auto my-5' />

            <Typography fontFamily={'Arima Madurai'} variant={Mobile ? 'h3' : 'h3'} style={{ textAlign: 'center' }} color={verdeescuro}>
                Emoção Prorrogada!<br></br>
            </Typography>
            <Typography fontFamily={'Arima Madurai'} variant={Mobile ? 'h6' : 'h6'} style={{ textAlign: 'center' }} color={verdeescuro}>
                Na AgRoss toda hora é hora de prêmios!<br></br>
            </Typography>
            <TitleComponent texto='' />
            <Typography fontFamily={'Arima Madurai'} variant={Mobile ? 'h6' : 'h5'} style={{ textAlign: 'center', marginTop: '2%' }} color={verdeescuro}>
                Regulamento
            </Typography>
            <Typography style={{ width: Mobile ? '90%' : '70%', textAlign: 'justify' }} className=' mx-auto my-4' color={verdeescuro}>
                <b>1.</b> Esta é uma promoção realizada pela H&F GOIANA AGRÍCOLA DO BRASIL LTDA., doravante denominada simplesmente “AgRoss”, inscrita no CNPJ/MF sob o nº 47.861.748/0001-42, mediante as seguintes regras abaixo:<br></br>
                <b>1.1.</b> Esta promoção possui caráter exclusivamente promocional, e tem o objetivo único de presentear os clientes que forem contemplados nos prêmios instantâneos, atendidos pela AgRoss unidade de Anápolis.<br></br>
                <b>1.2.</b> Esta promoção possui exclusivo caráter promocional, não permitindo nenhum tipo de comércio com as raspadinhas.<br></br>
                <b>1.3.</b> A cada R$6.000,00* (seis mil reais) em compras na mesma nota fiscal dos produtos do portifólio AgRoss o participante ganha uma raspadinha. As notas fiscais não são cumulativas, não podendo o valor residual de uma nota fiscal, complementar o valor de outra nota fiscal para ter direito a raspadinha.<br></br>
                <b>1.3.1.</b> Esta promoção é limitada a 05 (cinco) raspadinhas por nota fiscal.<br></br>
                <b>1.3.2.</b> Os prêmios* instantâneos são referentes aos horários do relógio/ilustração:<br></br>
                <b>[1 hora]</b> 01 Relógio Masculino Casio Esportivo Analógico A Prova D’Água MRW-200H-9BVDF<br></br>
                <b>[2 horas]</b> 01 Relógio Infantil Homem Aranha Azul<br></br>
                <b>[3 horas]</b> 01 Relógio Casio Masculino MW-240-3BVDF<br></br>
                <b>[4 horas]</b> 01 Kit Relógio Masculino Mondaine Analógico Prata 99192G0MVNE1<br></br>
                <b>[5 horas]</b> 01 Relógio Feminino Analógico Casio Collect MQ-24D-7EDF<br></br>
                <b>[6 horas]</b> 01 Relógio Citizen Masculino modelo Tz31561p Cronógrafo Bicolor<br></br>
                <b>[7 horas]</b> 01 Relógio Mondaine Feminino Analógico Dourado 53607LPMVDE2 Colar e Brincos<br></br>
                <b>[8 horas]</b> 01 Relógio Tuguir Masculino Esportivo Digital Prova D’Água TG30056<br></br>
                <b>[9 horas]</b> 01 Relógio Condor Masculino Digital Prata Calendário COKW05CAA/8C<br></br>
                <b>[10 horas]</b> 01 Relógio Infantil Frozen Rosa<br></br>
                <b>[11 horas]</b> 01 Relógio Feminino Casio Standard Analógico MQ-24-9ELDF<br></br>
                <b>[12 horas]</b> 01 Relógio Swarovski Crytalline Aura<br></br>
                <b>1.3.3.</b> Se sua raspadinha for premiada, preencha corretamente o verso da raspadinha e entregue ao motorista da AgRoss.<br></br>
                <b>1.4.</b> <s>Esta promoção é válida de 01.09.2023 a 31.10.2023.</s>(Prorrogada)<br></br>
                <b>1.5.</b> Ao raspar e preencher a raspadinha, o participante manifesta expressamente sua concordância com todos os termos e critérios em sua totalidade contidos neste regulamento.<br></br>
                <b>1.6.</b> Todos os participantes e ganhadores poderão ter expostos seus nomes e imagens pelo DEPARTAMENTO DE MARKETING da AgRoss em qualquer meio de comunicação que for conveniente a AgRoss, incluindo no site www.agross.com.br e TV AgRoss, ao participar da manifestando expressamente a permissão do uso de seus dados (nome) e direito de imagem ao participar desta promoção.<br></br>
                <b>1.7.</b> A entrega dos prêmios instantâneos será realizada juntamente com a próxima compra do cliente.<br></br>
                <b>*Na falta do modelo informado, será entregue ao ganhador modelo similar, a depender da disponibilidade de estoque e/ou do fornecedor da AgRoss.</b><br></br>
            </Typography>
        </div>
    )
}