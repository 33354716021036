// NewsDetail.tsx

import React, { useEffect, useState } from 'react';
import { News } from './News';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography,  Button } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import { newsData } from './newsData';

import { ShareButton } from 'app/shared/components';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const verdeescuro = lightGreen[900];

const primary = lightGreen[800];

const NewsDetail: React.FC = () => {
    const { id } = useParams();
    const [news, setNews] = useState<News | null>(null);
    const navigate = useNavigate();
    useEffect(() => {
        const newsItem = newsData.find(news => news.id.toString() === id);
        setNews(newsItem || null);
    }, [id]);

    if (!news) {
        return <div>Notícia não encontrada</div>;
    }
    const url = window.location.href;
    const title = news.title;

    console.log(url, title)
    return (
        <div>
            <Card className=' mt-5'>
            <div style={{width: '62%', margin: 'auto'}}>
                <Button variant="outlined" size="small" startIcon={<KeyboardBackspaceOutlinedIcon />} style={{ color: primary, width: '5rem' }} className="mx-3 my-3" onClick={() => {
                        navigate('/noticias');
                        window.scrollTo(0, 0);
                    }}>
                        Voltar
                    </Button>
            </div>
                <CardMedia
                    component="img"
                    style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}
                    image={news.imageUrl}
                    alt={news.title}
                />
                <CardContent style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Typography variant="h4" component="div" style={{ textAlign: 'center', color: verdeescuro }}>{news.title}</Typography>
                    <Typography variant="body1" className='mt-3' style={{ textAlign: 'justify', color: primary }} dangerouslySetInnerHTML={{ __html: news.fullText }} />
                    <Typography variant="body1" component="div" style={{ color: primary }}>Fonte: {news.fonte}</Typography>
                </CardContent>
            <div style={{width: '80%', margin: 'auto'}}>
                <Button variant="outlined" size="small" startIcon={<KeyboardBackspaceOutlinedIcon />} style={{ color: primary, width: '5rem' }} className="mx-3 my-3" onClick={() => {
                        navigate('/noticias');
                        window.scrollTo(0, 0);
                    }}>
                        Voltar
                    </Button>
            </div>
            </Card>
            {/* <div className=' d-flex flex-column p-5' style={{ backgroundColor: bege, width: '100%' }}>
                <div className=' d-flex flex-column'>
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: primary, textAlign: 'center' }}>
                        Deixe um comentário
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
                <div className=' d-flex '>
                    <TextField
                        required
                        id="outlined-multiline-flexible"
                        label="Escreva sua mensagem"
                        multiline
                        style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}

                        rows={5}
                        className='my-3'

                    />
                </div>
                <Button variant="outlined" size="large" style={{ marginLeft: 'auto', marginRight: 'auto', color: verdeescuro }} endIcon={<SendIcon />}>
                    Enviar
                </Button>
            </div> */}
            <div>
                
                <ShareButton platform={'facebook'} url={url} title={title} />
                <ShareButton platform={'whatsapp'} url={url} title={title} />
                <ShareButton platform={'imprimir'} url={url} title={title} />
            </div>

        </div>

    );
};

export default NewsDetail;
