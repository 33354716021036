import { Carouseltg, TitleComponent } from "app/shared/components"
import { Accordion, AccordionDetails, AccordionSummary, Card, CardActionArea, CardContent, Grid, Link, Typography } from "@mui/material"
import { lightGreen } from '@mui/material/colors';
import './TroqueEGanhe.css'
import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Element } from 'react-scroll';
import { UserLogged, useMenuContext } from "app/shared/contexts";
import { useNavigate } from 'react-router-dom';
import useIsMobile from "app/shared/hooks/useIsMobile";


const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

interface Brindes {
    id_brinde: number;
    brinde: string | null;
    id_categoria: number;
    pontos: number;
    img_brinde: string;
    ativo: string;
    destaque: string;
    voltagem: string;
}
type AccordionItemProps = {
    title: string;
    content: React.ReactNode; // isso permite passar qualquer tipo de conteúdo, incluindo elementos JSX
    isOpen: boolean;
    onTitleClick: () => void;
};


const AccordionItem: React.FC<AccordionItemProps> = ({ title, content, isOpen, onTitleClick }) => {






    return (
        <Accordion expanded={isOpen} onChange={onTitleClick}>
            <AccordionSummary
                style={{ backgroundColor: isOpen ? verdeescuro : bege }}
                expandIcon={<ExpandMoreIcon style={{ color: isOpen ? bege : verdeescuro }} />}
            >
                <Typography fontFamily={'Arima Madurai'} variant='h6' style={{ color: isOpen ? bege : verdeescuro }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ color: verdeescuro }}>{content}</Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export const TroqueEGanhe: React.FC<{ onLinkClick: () => void }> = ({ onLinkClick }) => {

    const [filteredData, setFilteredData] = useState<Brindes[]>([]);
    const { userLogged } = useContext(UserLogged);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { setSelectedItem, scrollToPerguntastg, setScrollToPerguntastg } = useMenuContext();
    const Mobile = useIsMobile();
    const login = 'suporte-mkt2022@agross.com.br';
    const password = 'Ces2020@mkt';
    useEffect(() => {

        const getToken = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/auth/login/?email=${login}&password=${password}`);

                const newToken = response.data.access_token;



                const fetchData = async () => {
                    try {
                        const response = await axios.get('https://data-api.agross.com.br/api/v1/brindes', {
                            headers: {
                                Authorization: `Bearer ${newToken}`,

                            },
                        });
                        setFilteredData(response.data.brindes);
                        setIsLoading(false);

                    } catch (error) {
                        console.error('Erro ao buscar os dados', error);
                    }
                };

                fetchData();

            } catch (error) {
                console.error(error);
            }
        }

        getToken();

    }, []);

    const [openAccordion, setOpenAccordion] = useState<string | null>(null);

    const handleAccordionChange = (title: string) => {
        setOpenAccordion(openAccordion === title ? null : title);
    };

    useEffect(() => {
        if (scrollToPerguntastg) {
            // Adiar a execução até a próxima 'tarefa' para garantir que todos os renders tenham sido executados
            setTimeout(() => {
                const element = document.getElementById('perguntastg');
                if (element) {
                    window.scrollTo({
                        top: element.getBoundingClientRect().top + window.scrollY - 80,
                        behavior: 'smooth',
                    });
                }

                setScrollToPerguntastg(false); // resetar para falso depois de rolar
            }, 0); // Isso efetivamente coloca isso no final da fila de execução, após renders
        }
    }, [scrollToPerguntastg, setScrollToPerguntastg]);
    return (
        <div>
            <Carouseltg />

            <div className=' d-flex flex-column py-4'>
                <TitleComponent texto="Como Participar?" variant={Mobile ? 'h4' : 'h3'} />
                <Typography style={{ textAlign: 'center', color: verdeescuro }} variant={Mobile ? "body2" : "body1"}>
                    ENTENDA COMO FUNCIONA O TROQUE E GANHE
                </Typography>
            </div>
            <div style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }} className=" d-flex mt-4 flex-wrap">
                <div className={`divpassos d-flex ${Mobile ? 'flex-column' : 'flex-row'} align-items-center`} style={{ marginLeft: 'auto', marginRight: 'auto', width: Mobile ? '100%' : '48%', position: 'relative' }}>
                    <Typography
                        style={{ position: 'absolute', top: '0.5rem', left: '0.5rem', zIndex: 1, minWidth: 'auto', width: '1.5rem', height: '1.5rem', color: 'white', backgroundColor: primary }}
                        className=" d-flex align-items-center rounded-circle"
                    >
                        <p className=" m-auto">1</p>
                    </Typography>
                    <img src={require('assets/tg/passo_01.png')} alt="" width={'25%'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <Typography style={{ color: primary, textAlign: 'center', width: Mobile ? '95%' : '75%' }} className='my-3'>
                        Compre Produtos distribuídos pela Agross e acumule pontos.*<br></br>
                        Confira os produtos participantes.*
                    </Typography>
                </div>
                <div className={`divpassos d-flex ${Mobile ? 'flex-column' : 'flex-row'} align-items-center`} style={{ marginLeft: 'auto', marginRight: 'auto', width: Mobile ? '100%' : '48%', position: 'relative' }}>
                    <Typography
                        style={{ position: 'absolute', top: '0.5rem', left: '0.5rem', zIndex: 1, minWidth: 'auto', width: '1.5rem', height: '1.5rem', color: 'white', backgroundColor: primary }}
                        className=" d-flex align-items-center rounded-circle"
                    >
                        <p className=" m-auto">2</p>
                    </Typography>
                    <img src={require('assets/tg/passo_02.png')} alt="" width={'25%'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <Typography style={{ color: primary, textAlign: 'center', width: Mobile ? '95%' : '75%' }} className='my-3'>
                        Acompanhe sua pontuação através do extrato anexo a Nota Fiscal de compra ou consulte o gerente de sua unidade.
                    </Typography>
                </div>
                <div className={`divpassos d-flex ${Mobile ? 'flex-column' : 'flex-row'} align-items-center`} style={{ marginLeft: 'auto', marginRight: 'auto', width: Mobile ? '100%' : '48%', position: 'relative' }}>
                    <Typography
                        style={{ position: 'absolute', top: '0.5rem', left: '0.5rem', zIndex: 1, minWidth: 'auto', width: '1.5rem', height: '1.5rem', color: 'white', backgroundColor: primary }}
                        className=" d-flex align-items-center rounded-circle"
                    >
                        <p className=" m-auto">3</p>
                    </Typography>
                    <img src={require('assets/tg/passo_03.png')} alt="" width={'25%'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <Typography style={{ color: primary, textAlign: 'center', width: Mobile ? '95%' : '75%' }} className='my-3'>
                        Faça seu cadastro ou login para realizar seu pedido de troca.
                    </Typography>
                </div>
                <div className={`divpassos d-flex ${Mobile ? 'flex-column' : 'flex-row'} align-items-center`} style={{ marginLeft: 'auto', marginRight: 'auto', width: Mobile ? '100%' : '48%', position: 'relative' }}>
                    <Typography
                        style={{ position: 'absolute', top: '0.5rem', left: '0.5rem', zIndex: 1, minWidth: 'auto', width: '1.5rem', height: '1.5rem', color: 'white', backgroundColor: primary }}
                        className=" d-flex align-items-center rounded-circle"
                    >
                        <p className=" m-auto">4</p>
                    </Typography>
                    <img src={require('assets/tg/passo_04.png')} alt="" width={'25%'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <Typography style={{ color: primary, textAlign: 'center', width: Mobile ? '95%' : '75%' }} className='my-3'>
                        Troque seus pontos por mais de 500 produtos, entre viagens, eletro-eletrônicos, informática, eletrodomésticos, brinquedos, beleza e muito mais!
                    </Typography>
                </div>
            </div>
            <div className=' d-flex flex-column py-4'>
                <TitleComponent texto="Veja alguns dos produtos disponíveis." variant={Mobile ? 'h5' : 'h3'} />
                {userLogged ?
                    <Typography style={{ color: primary, textAlign: 'center' }}>
                        Para ver mais,{' '}
                        <Link
                            href="#"
                            style={{ color: primary }}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/brindes');
                                setSelectedItem('brindes')
                            }}
                        >
                            Clique aqui
                        </Link>{' '}

                    </Typography>
                    :
                    <Typography style={{ color: primary, textAlign: 'center' }}>
                        Para ver mais,{' '}
                        <Link
                            href="#"
                            style={{ color: primary }}
                            onClick={(e) => {
                                e.preventDefault();
                                onLinkClick();
                            }}
                        >
                            Clique aqui
                        </Link>{' '}
                        e faça login.
                    </Typography>
                }

            </div>


            <div className=" d-flex flex-column">
                {isLoading ?
                    <img src={require('assets/loader.gif')} alt="loader2" style={{ width: '7%', marginLeft: 'auto', marginRight: 'auto' }} />
                    :
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%' }} >
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: Mobile ? 2 : 5, sm: 8, md: 15 }} className='my-3'>
                            {filteredData.filter(brindes => brindes.destaque === 'S').map((brindes) => (
                                <Grid item xs={2} sm={4} md={5} onClick={() => { navigate('/brindes'); window.scrollTo(0, 0); }}>
                                    <Card sx={{ maxWidth: 345 }} className='m-2'>
                                        <CardActionArea>
                                            <CardContent style={{ textAlign: 'center', height: '22rem', display: 'flex', alignItems: 'center' }}>
                                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <div className=' d-flex'>
                                                        <img src={require(`assets/brindes/${brindes.id_brinde}.jpg`)} alt="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
                                                    </div>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Imagem meramente ilustrativa
                                                    </Typography>
                                                    <Typography gutterBottom variant="h5" component="div" className=' my-3' >
                                                        {brindes.brinde}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {Number(brindes.pontos).toLocaleString()} Pontos
                                                    </Typography>
                                                </div>

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>}



            </div>
            <div className=' d-flex flex-column py-4'>

                {userLogged ?
                    <Typography style={{ color: primary, textAlign: 'center' }}>
                        Para ver mais,{' '}
                        <Link
                            href="#"
                            style={{ color: primary }}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/brindes');
                                setSelectedItem('brindes')
                            }}
                        >
                            Clique aqui
                        </Link>{' '}

                    </Typography>
                    :
                    <Typography style={{ color: primary, textAlign: 'center' }}>
                        Para ver mais,{' '}
                        <Link
                            href="#"
                            style={{ color: primary }}
                            onClick={(e) => {
                                e.preventDefault();
                                onLinkClick();
                            }}
                        >
                            Clique aqui
                        </Link>{' '}
                        e faça login.
                    </Typography>
                }

            </div>

            <div style={{ width: '80%', height: '600px', marginTop: '1rem' }} className=" mx-auto">

                <iframe
                    src="https://online.pubhtml5.com/psjd/eruc/"
                    title="Revista"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    allowFullScreen
                ></iframe>
            </div>

            <Element name="perguntastg" id="perguntastg">
                <div className=' d-flex flex-column py-4'>
                    <TitleComponent texto='Perguntas Frequentes' variant={Mobile ? "h4" : "h3"} />

                    <div className=' d-flex justify-content-around my-5' style={{ width: Mobile ? '100%' : '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ width: Mobile ? '80%' : '70%' }}>
                            <AccordionItem
                                title="Como recupero minha senha?"
                                content={
                                    <span>
                                        Clique em "Entrar" e depois em "Esqueceu sua senha?", digite seu CNPJ e clique em "Recuperar Senha", um link será enviado ao e-mail cadastrado para que você possa redefinir sua senha.
                                    </span>
                                }
                                isOpen={openAccordion === "Question 1"}
                                onTitleClick={() => handleAccordionChange("Question 1")}
                            />
                            <AccordionItem
                                title="Não tenho mais acesso ao e-mail cadastrado, como recuperar?"
                                content={
                                    <span>
                                        Neste caso, a única opção é excluir o cadastro e refazê-lo com as informações atualizadas. Entre em contato com&nbsp;
                                        <a href="mailto:troqueeganhe@agross.com.br">troqueeganhe@agross.com.br</a>.
                                    </span>
                                }
                                isOpen={openAccordion === "Question 2"}
                                onTitleClick={() => handleAccordionChange("Question 2")}
                            />
                            <AccordionItem
                                title="Como faço para alterar o cadastro do responsável?"
                                content={
                                    <span>
                                        Faça login na sua conta, clique no botão minha conta e então clique no botão Solicitar Alteração de Responsável.<br></br>
                                        Preencha o formulário e o gerente da unidade que lhe atende irá fazer a aprovação.
                                    </span>
                                }
                                isOpen={openAccordion === "Question 3"}
                                onTitleClick={() => handleAccordionChange("Question 3")}
                            />
                            <AccordionItem
                                title="Realizei o cadastro, mas não consigo realizar as trocas."
                                content="Os motivos mais comuns para não conseguir efetuar as trocas são saldo de pontos insuficiente. Entre no carrinho e certifique se que ele está vazio,verifique seu saldo de pontos e refaça a troca."
                                isOpen={openAccordion === "Question 4"}
                                onTitleClick={() => handleAccordionChange("Question 4")}
                            />

                            <AccordionItem
                                title="É possível unir os pontos de mais de um CNPJ em uma única conta?"
                                content="Sim! Para unir os pontos de mais de um CNPJ, entre em contato com troqueeganhe@agross.com.br, informando os CNPJs das filiais e para qual CNPJ você deseja concentrar todos os pontos."
                                isOpen={openAccordion === "Question 5"}
                                onTitleClick={() => handleAccordionChange("Question 5")}
                            />
                            <AccordionItem
                                title="Por que meus pontos estão bloqueados?"
                                content="O regulamento do Troque & Ganhe passou por uma modificação em novembro/20 e os pontos conquistados pelos clientes estarão disponíveis para troca, somente após o pagamento de todas as parcelas da Nota Fiscal(s) que gerou a pontuação. Enquanto as baixas não são feitas, você conseguirá ver os pontos na coluna 'à disponibilizar' que vai com o extrato junto com sua nota fiscal."
                                isOpen={openAccordion === "Question 6"}
                                onTitleClick={() => handleAccordionChange("Question 6")}
                            />
                            <AccordionItem
                                title="Como faço para liberar saldo bloqueado?"
                                content="Os pontos somente são liberados, após o pagamento de todas as parcelas da Nota Fiscal(s) que gerou a pontuação."
                                isOpen={openAccordion === "Question 7"}
                                onTitleClick={() => handleAccordionChange("Question 7")}
                            />
                            <AccordionItem
                                title="Qual o prazo para entrega dos produtos?"
                                content="Após aprovação de seu pedido, o nosso programa de fidelidade tem um prazo médio de entrega próximo a 30 dias, podendo sofrer variações de acordo com o produto solicitado e a disponibilidade do produto no mercado. As entregas não dependem exclusivamente da AgRoss, desta forma, o prazo de entrega poderá variar próximo a datas comemorativas, como natal, feriados e etc, conforme a disponibilidade das empresas de entregas. Portanto, os pedidos devem ser realizados antecipadamente."
                                isOpen={openAccordion === "Question 8"}
                                onTitleClick={() => handleAccordionChange("Question 8")}
                            />
                            <AccordionItem
                                title="Consigo acompanhar meu pedido, tem código de rastreio?"
                                content="Não é possível fazer o rastreio do produto."
                                isOpen={openAccordion === "Question 9"}
                                onTitleClick={() => handleAccordionChange("Question 9")}
                            />
                            <AccordionItem
                                title="Recebi o produto com defeito/Avaria como realizar a troca?"

                                content={
                                    <span>
                                        Se o produto apresentar algum defeito ou avaria no período de 7 dias corridos após recebimento, entre em contato com&nbsp;
                                        <a href="mailto:troqueeganhe@agross.com.br">troqueeganhe@agross.com.br</a>.
                                        &nbsp;Após esse período, entre em contato diretamente com o fabricante.
                                    </span>
                                }
                                isOpen={openAccordion === "Question 10"}
                                onTitleClick={() => handleAccordionChange("Question 10")}
                            />
                        </div>
                    </div>
                </div>
            </Element>

        </div>



    )
}