import { Depoimentos as DepoimentosComponent } from "app/shared/components"
import { testimonials } from "../agross/Agross"
import useIsMobile from "app/shared/hooks/useIsMobile"
import { Element } from "react-scroll";
import { Typography } from "@mui/material";
import { lightGreen } from "@mui/material/colors";



const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

export const Depoimentos = () => {
    const Mobile = useIsMobile();
    return (
        <div>


            {Mobile ?
                <Element name="depoimentos" id="depoimentos">
                    <div className=" d-flex flex-column" style={{ backgroundColor: bege, color: primary, paddingBottom: "3rem", paddingTop: "3rem" }}>
                        <div style={{ width: '100%' }} className=" d-flex flex-column">
                            <Typography className=" m-auto">
                                DEPOIMENTOS
                            </Typography>
                            <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center', width: '90%', margin: 'auto' }}>
                                O que os nossos clientes&nbsp;dizem
                            </Typography>
                            <img src={require(`app/shared/imagens/separador.png`)} alt="" className=" m-auto" />
                        </div>
                        <div style={{ width: '90%', margin: 'auto' }} className="mt-4">
                            <DepoimentosComponent testimonials={testimonials} />
                        </div>
                    </div>
                </Element>
                :
                <Element name="depoimentos" id="depoimentos">
                    <div className=" d-flex justify-content-center" style={{ backgroundColor: bege, color: primary, paddingBottom: "3rem", paddingTop: "3rem" }}>
                        <div style={{ width: '30%' }}>
                            <Typography>
                                DEPOIMENTOS
                            </Typography>
                            <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro }}>
                                O que os nossos clientes&nbsp;dizem
                            </Typography>
                            <img src={require(`app/shared/imagens/separador.png`)} alt="" />
                        </div>
                        <div style={{ width: '40%' }}>
                            <DepoimentosComponent testimonials={testimonials} />
                        </div>
                    </div>
                </Element>
            }
           

            
            
        </div>



    )
}