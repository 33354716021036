import { Typography } from '@mui/material'
import './roundup3000.css'
import { TitleComponent } from 'app/shared/components'
import { lightGreen } from '@mui/material/colors';


const verdeescuro = lightGreen[900];

export const Roundup3000 = () => {
    return (
        <div className="divroundup3000 d-flex flex-column pt-5">
            <img src={require('assets/roundup3000/página conteúdo.png')} alt="" style={{ width: '80%' }} className=' mx-auto my-5' />

            <TitleComponent texto='Regulamento' />
            <Typography fontFamily={'Arima Madurai'} variant='h5' style={{ textAlign: 'center' }} color={verdeescuro}>
                Campanha Roundup 3000<br></br>
                Descrição do Processo de Participação
            </Typography>
            <Typography style={{ width: '70%', textAlign: 'justify' }} className=' mx-auto mt-4' color={verdeescuro}>
                <b>1.</b> Este regulamento versa sobre as regras da CAMPANHA “ROUNDUP 3000” e tem por finalidade descrever as regras e modo de participação da campanha. Esta é uma campanha promocional realizada pelo Grupo AgRoss, adiante serão informadas as regras que poderão ser alteradas exclusivamente por determinação do Grupo AgRoss, conforme abaixo: <br></br>
                <b>2.</b> A campanha “ROUNDUP 3000” consiste em disponibilizar aos clientes AgRoss que adquirirem volumes iguais ou superiores a 200 kg/litros da Linha Roundup em uma mesma nota fiscal a participação do sorteio de 3000 litros de Roundup Original Mais.<br></br>
                <b>3.</b> O prêmio será 3000 litros de Roundup Original Mais em embalagens de 20 litros.<br></br>
                <b>4.</b> O prêmio não poderá ser substituído por outro, nem mesmo por outro produto da linha Roundup. Também não é possível a substituição da embalagem do produto.<br></br>
                <b>5.</b> A campanha terá seu início em 01/10/2023 com prazo final indeterminado e poderá ser encerrada a qualquer momento pela AgRoss.<br></br>
                <b>6.</b> Estará credenciada a participar da promoção, as notas fiscais emitidas dentro do mês do sorteio que contiverem a compra de no mínimo 200 kg/litros da Linha Roundup.<br></br>
                <b>7.</b> As notas emitidas na data do sorteio serão incluídas no sistema apenas para o próximo sorteio.<br></br>
                <b>8.</b> Os sorteios ocorrerão todas às quintas-feiras e serão transmitidos através da TvAgRoss.<br></br>
                <b>9.</b> Para ser contemplado com o prêmio, a Revenda deverá estar online na plataforma da TvAgRoss (www.tvagross.com.br) e confirmar eletronicamente sua nota fiscal ganhadora através do clique na plataforma.<br></br>
                <b>10.</b> Dentro da plataforma da TvAgRoss as notas fiscais participantes da Campanha, estarão em destaque após o logo da promoção, conforme print abaixo:<br></br>
            </Typography>
            <img src={require('assets/roundup3000/Regulamento Roundup 3000.jpg')} alt="" style={{ width: '70%' }} className=' mx-auto my-2' />
            <Typography style={{ width: '70%', textAlign: 'justify' }} className=' mx-auto my-4' color={verdeescuro}>
                <b>11.</b> A não confirmação eletrônica através da plataforma da TvAgRoss implica na desqualificação da nota fiscal para recebimento do prêmio. Desse modo, sem esta confirmação, o prêmio não poderá ser reclamado de forma posterior.<br></br>
                <b>12.</b> Será considerado como ganhador do prêmio, o número de nota fiscal contemplado e confirmado na plataforma da TvAgRoss na hora do sorteio, de forma online. Em nenhuma hipótese o prêmio poderá ser reclamado de forma posterior.<br></br>
                <b>13.</b> Em caso de 2(dois) ou mais clientes contemplados com o mesmo número de nota fiscal sorteado, haverá um sorteio entre eles.<br></br>
                <b>14.</b> A AgRoss através do seu software irá acompanhar e identificar o número da nota fiscal sorteada em tempo real, conciliando de imediato com o cliente que comunicar a contemplação<br></br>
                <b>15.</b> O sorteio será realizado considerando 05 dígitos das notas fiscais. Notas fiscais com número inferior a 05 dígitos concorrerão utilizando o acréscimo do numeral 0 a esquerda até atingir 05 dígitos. Exemplo: Nota fiscal nº 12, para concorrer ao sorteio, aparecerá como 00012 na plataforma, sendo necessário o sorteio do número 0 três vezes para contemplar esta nota.<br></br>
                <b>16.</b> Em caso de problemas técnicos de áudio e vídeo no cliente, impossibilitando o acompanhamento dos sorteios da Campanha a AgRoss não se responsabilizará e não aceitará reclamações posteriores.<br></br>
                <b>17.</b> Esta promoção possui exclusivo caráter promocional.<br></br>
                <b>18.</b> A entrega do prêmio se dará em até 180 dias da contemplação e confirmação durante o sorteio na plataforma da TvAgRoss.<br></br>
                <b>19.</b> Todos os custos envolvidos com a logística e entrega do prêmio será custeado pela AgRoss, sendo que a entrega do prêmio somente será realizada no endereço do ganhador, ou seja, o mesmo endereço constante na nota fiscal que for sorteada.<br></br>
                <b>20.</b> Ao participar desta campanha, o cliente AgRoss obriga-se a observar e cumprir rigorosamente todas as leis cabíveis, assim como as normas e exigências constantes nas políticas internas do Grupo AgRoss.<br></br>
                <b>21.</b> O participante ganhador aceita tacitamente a divulgação do nome da Revenda ganhadora, bem como, caso a AgRoss tenha interesse em gravar uma participação especial na TvAgRoss confirmando a entrega do prêmio.<br></br>
                <b>22.</b> A confirmação eletrônica de contemplação implica na declaração do participante que declara que leu e concorda com os termos deste Regulamento.<br></br>
                <b>23.</b> Para dirimir eventuais conflitos judiciais referentes ao regulamento da presente promoção, torna-se eleito o Foro da Comarca de Campinas, estado de São Paulo.<br></br>
            </Typography>
        </div>
    )
}