import { Button, Link, Typography } from "@mui/material"
import { lightGreen } from "@mui/material/colors";
import TokenContext from 'app/shared/services/token/TokenContext';
import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import AuthService from "app/shared/services/AuthService";
import './Ocorrencias.css';
import useIsMobile from "app/shared/hooks/useIsMobile";

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

interface OcorrenciasProps {
    authService: AuthService;
}

interface Ocorrencias {
    id: string,
    cnpj: string,
    evento: string,
    tipoocorrencia: string,
    id_solicitacao: string,
    razsoc: string,
    id_brinde: string,
    brinde: string,
    valor: string,
    datahora: string,
    status: string,
    observacao: string
}

export const Ocorrencias = (props: OcorrenciasProps) => {

    const token = useContext(TokenContext);
    const cnpj = localStorage.getItem('cliente_cnpj');
    const [Ocorrencias, setOcorrencias] = useState<Ocorrencias[] | null>(null)
    const [loaderOcorrencias, setLoaderOcorrencias] = useState(false);
    const Mobile = useIsMobile();

    useEffect(() => {
        props.authService.getLoggedUser()
            .then(user => {
                if (user) {
                    const getDadosCliente = async () => {
                        setLoaderOcorrencias(true);
                        try {
                            const response = await axios.get(`https://data-api.agross.com.br/api/v1/ocorrencias/${cnpj}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });
                            console.log('minhas solicitações: ', response.data.ocorrencias);
                            setOcorrencias(response.data.ocorrencias)
                            setLoaderOcorrencias(false);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    getDadosCliente();
                } else {
                    console.log('No user is signed in');
                    setLoaderOcorrencias(false);

                }
            })
            .catch(error => {
                console.error('An error occurred:', error);
                setLoaderOcorrencias(false);
            });



    }, []);


    return (
        <div style={{ width: Mobile ? '90%' : '70%' }} className={`${Mobile ? 'm-auto' : 'mx-2'} my-2 p-1 position-relative`}>
            <div className={`loaderConta ${loaderOcorrencias ? 'd-flex' : 'd-none'}`}>
                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '13%' }} />
            </div>
            <div style={{ border: '1px solid gray', borderRadius: '0.3rem', fontSize: Mobile ? '0.7rem' : '' }}>
                <div style={{ borderBottom: '1px solid gray', backgroundColor: bege, borderRadius: '0.3rem' }} className="p-2">
                    <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.5rem', color: primary }}>
                        Ocorrências
                    </Typography>
                </div>
                <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                    style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', backgroundColor: bege }}>
                    <div style={{ width: '20%' }}>
                        <Typography fontFamily={'Arima Madurai'}  style={{ textAlign: 'center', fontSize:Mobile? '0.7rem': '1.2rem', color: primary }}>
                            N. Ocorrência
                        </Typography>
                    </div>
                    <div style={{ width: '30%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile? '0.7rem': '1.2rem', color: primary }}>
                            Ocorrência
                        </Typography>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile? '0.7rem': '1.2rem', color: primary }}>
                            Status
                        </Typography>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile? '0.7rem': '1.2rem', color: primary }}>
                            Data
                        </Typography>
                    </div>
                </div>
                {Ocorrencias && Ocorrencias.map((solicitacao) => (
                    <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                        style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)' }}>

                        <div className=" d-flex flex-column align-items-center" style={{ width: '20%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <div>
                                    {Number(solicitacao.id).toLocaleString()}
                                </div>

                            </div>

                        </div>
                        <div className=" d-flex flex-column align-items-center" style={{ width: '30%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                {solicitacao.evento}

                            </div>

                        </div>
                        <div style={{ width: '25%', textAlign: 'center' }}>
                            {solicitacao.status === 'R' ?
                                <Typography className=" p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'red' }}>
                                    Reprovado
                                </Typography>
                                :
                                (solicitacao.status === 'F' ?
                                    <Typography className=" bg-success p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>
                                        Aprovado
                                    </Typography>
                                    :
                                    ((solicitacao.status === 'P') ?
                                        <Typography className=" bg-warning p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>
                                            Em Análise
                                        </Typography>
                                        : '')
                                )
                            }
                        </div>
                        <div style={{ width: '25%', color: primary, textAlign: 'center' }}>
                            {solicitacao.datahora}
                        </div>

                    </div>
                ))
                }

            </div>
        </div>
    )
}