import { PortfolioComponent } from "app/shared/components"
import { PortfolioComponente } from "app/shared/components/portfolio2/PortfolioComponente"




export const Portfolio2 = () => {

    return (
        <div>

            {/* <PortfolioComponent /> */}
            <PortfolioComponente />



        </div>



    )
}
