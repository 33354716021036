/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import './CronometroTv.css';
import { useMenuContext } from 'app/shared/contexts';
import { TitleComponent } from '../titlecomponent/TitleComponent';
import { Link } from '@mui/material';

export const CronometroTv: React.FC = () => {
    // const [message, setMessage] = useState('');
    const clockRef = useRef<HTMLDivElement>(null);
    const { isCompleted, setIsCompleted } = useMenuContext();

    const getNextThursday = () => {
        const now = new Date();

        let nextThursday = new Date();
        const daysUntilNextThursday = (4 + 7 - now.getDay()) % 7;
        // Se hoje já é quinta-feira mas já passou das 15:45, ou se hoje é depois de quinta-feira,
        // ajuste para a próxima quinta-feira.
        if (now.getDay() > 4 || (now.getDay() === 4 && now.getHours() >= 17)) {
            if (daysUntilNextThursday === 0) {
                nextThursday.setDate(now.getDate() + 7);
            } else {
                nextThursday.setDate(now.getDate() + daysUntilNextThursday);
            }


        } else if (now.getDay() === 4 && ((now.getHours() === 15 && now.getMinutes() > 45) || (now.getHours() === 16))) {
            setIsCompleted(true);


        } else {
            // Se não, ajuste para a próxima quinta-feira.
            nextThursday.setDate(now.getDate() + (4 - now.getDay()) % 7);

        }

        // Define a hora para 15:45.
        nextThursday.setHours(15);
        nextThursday.setMinutes(45);
        nextThursday.setSeconds(0);
        nextThursday.setMilliseconds(0);
        return nextThursday;
    };

    useEffect(() => {
        const testedata = new Date();
        if (testedata.getHours() === 15 && testedata.getDay() === 4 && testedata.getMinutes() > 44) {
            setIsCompleted(true);
        } else if (testedata.getHours() === 16 && testedata.getDay() === 4) {
            setIsCompleted(true);
        }
        console.log('teste data: ', testedata.getHours());

        if ((window as any).$ && clockRef.current) {
            const clock = (window as any).$(clockRef.current).FlipClock({
                clockFace: 'DailyCounter',
                language: 'portuguese',
                autoStart: false,
                callbacks: {
                    stop: function () {
                        // setMessage('O relógio parou!');
                        console.log('O relógio parou!');


                    }
                }
            });

            const targetDate = new Date(getNextThursday());
            /* const targetDate = new Date('Thu Jun 6 2024 15:45:00'); */
            const now = new Date();
            const diffInSeconds = Math.floor((targetDate.getTime() - now.getTime()) / 1000);
            console.log('data: ', targetDate);

            clock.setTime(diffInSeconds);
            clock.setCountdown(true);
            clock.start();

            return () => {
                // Parar e limpar o relógio aqui
            };
        }
    }, []);

    return (
        <div className=' d-flex w-100'>
            <div className=' mx-auto'>
                {isCompleted ?
                    <div className=' d-flex flex-column'>
                        <Link href='https://tvagross.com.br/inicio' target='_blank' style={{ textDecoration: 'none' }}>
                            <TitleComponent texto='A Tv AgRoss está' />
                        </Link>
                        <div className=' d-flex mx-auto'>
                            <Link href='https://tvagross.com.br/inicio' target='_blank'>
                                <img src={require('app/shared/components/flipclock/noar.gif')} alt="" style={{ width: '30rem' }} />
                            </Link>
                        </div>
                    </div>
                    :
                    <div ref={clockRef} className="clock"></div>
                }
            </div>
        </div>
    );
};
