import { TitleComponent } from "app/shared/components"
import Typography from '@mui/material/Typography';
import { lightGreen } from "@mui/material/colors";
import { Divider } from "@mui/material";

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];


export const PoliticaPrivacidade = () => {

    return (
        <div className=" d-flex flex-column">
            <TitleComponent texto="Política de Privacidade" />
            <div style={{ width: '70%' }} className=" mx-auto my-3">
                <Typography color={verdeescuro}>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        A AgRoss está honrada com sua visita em nosso site e agradece pelo seu interesse em nossa empresa.
                        No nosso site a proteção de dados dos visitantes tem prioridade.
                        Os visitantes do nosso site devem ter a partir de 18 anos de idade, pois não é pretensão da AgRoss dirigir seus serviços a menores de idade.
                        Ao visitar o nosso site, será gerado um cookie no computador do usuário. O cookie nada mais é que um arquivo pequeno para reconhecer, acompanhar e armazenar a navegação do usuário na internet.
                        Cookie nos permite coletar informações como tipo de navegador, tempo gasto nos serviços, páginas visitadas, preferências de idioma e outros dados de tráfego. O cookie coleta informações de forma anônima e não nos permite identificar o usuário pessoalmente, pois nenhuma informação pessoal é rastreada.
                        Qualquer informação recolhida pelo site da AgRoss, nunca será repassada, cedida ou vendida a terceiros, salvo por obrigações legais ou decisões judiciais.
                        A AgRoss cumpre todas as leis, normas e regulamentos aplicáveis vigentes.
                        Nossa Política de Privacidade poderá ser atualizada ou modificada a qualquer momento, conforme a necessidade ou conveniência do grupo AgRoss, com adequação e conformidade legal disposição de lei ou norma jurídica, cabendo ao visitante verificá-la sempre que efetuar o acesso ao nosso site.
                    </p>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Nós nos preocupamos com sua privacidade
                        Usamos cookies para permitir que o nosso website funcione corretamente, melhore a sua experiência, personalize conteúdo e melhore o desempenho do site. Você pode configurar suas preferências em "Definições de cookies". Caso concorde com os cookies que utilizamos clique em "Aceitar todos os cookies". Conheça também a nossa Política de Proteção de Dados.
                        A AgRoss respeita a sua privacidade e valoriza a relação que temos consigo.
                        Tal como muitas organizações, utilizamos tecnologias, conhecida como cookies, para reunir informações que nos ajuda a melhorar a sua experiência e os nossos serviços.

                    </p>
                    <Typography variant="h5">
                        O que são cookies?
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Um cookie é um pequeno arquivo de texto que um site coloca no seu computador, smartphone ou qualquer outro dispositivo com acesso à internet, contendo informação sobre a sua navegação nesse site.  Os cookies são, então, reenviados para o website de origem ou para outro website que reconheça esse cookie.
                    </p>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Que tipo de cookies coletamos? Para qual finalidade?
                        Utilizamos cookies para tornar os websites da AgRoss mais fáceis de utilizar e para lhe fornecer uma melhor experiência enquanto os explora. Podemos utilizar também para personalização dos nossos produtos e sistemas de acordo com os interesses e necessidades de seus usuários. Quando possível, também utilizamos cookies para ajudar a agilizar atividades futuras.
                        Por fim, utilizamos cookies para gerar estatísticas que nos permitem compreender como as pessoas utilizam os nossos websites, o que nos ajuda a melhorar as suas estruturas e conteúdo.
                        Apresentamos os cookies utilizados nos websites em uma Central de Preferências disponível nos nossos websites, que estarão agrupados nas seguintes categorias:

                    </p>
                    <ul className="m-lg-3">
                        <li>
                            <p style={{ textAlign: 'justify' }}>
                                Cookies Estritamente Necessários: Conforme o próprio nome já diz, estes cookies são necessários para permitir determinadas funcionalidades do site e a utilização de nossos serviços.
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: 'justify' }}>
                                Cookies Analíticos: Os cookies analíticos são aqueles que possibilitam a coleta e análise de dados estatísticos, permitindo verificar quais páginas você acessou, quantas vezes foram acessadas estas páginas, que visam melhorar o desempenho e funcionamento do site.
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: 'justify' }}>
                                Cookies de Funcionalidade: Estes cookies são responsáveis por guardar as preferências da sua utilização em nosso site, fazendo com que você não precise selecioná-las novamente.
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: 'justify' }}>
                                Cookies de Segmentação: Estes tipos de cookies são aqueles destinados a coletar informações para personalizar a publicação de conteúdo.
                            </p>
                        </li>
                    </ul>
                    <Typography variant="h5">
                        Duração dos cookies que utilizamos
                    </Typography>
                    <p>
                        Em termos de duração, poderemos utilizar dois tipos diferentes de cookies nos nossos websites:
                    </p>
                    <ul className=" m-lg-3">
                        <li>
                            <p style={{ textAlign: 'justify' }}>
                                Cookies de Sessão: Estes cookies são temporários e permanecem no seu dispositivo até que deixe os nossos websites, ou
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: 'justify' }}>
                                Cookies Permanentes: Estes cookies permanecem no seu dispositivo durante muito mais tempo ou até que os apague manualmente (o tempo de permanência de um cookie no seu dispositivo irá depender da duração ou “tempo de vida” do cookie específico, bem como das definições do seu browser, como descrito abaixo).
                            </p>
                        </li>
                    </ul>
                    <Typography variant="h5">
                        São utilizados cookies de terceiros?
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Trabalhamos com uma série de fornecedores e parceiros que podem configurar cookies em seu dispositivo quando você visita nossos websites, para permitir que eles executem os serviços que estão fornecendo. Apresentamos esses cookies na Central de Preferências.
                    </p>
                    <Typography variant="h5">
                        Como eu rejeito ou apago cookies?
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Com exceção dos cookies estritamente necessários, que permitem o funcionamento do site, não há obrigatoriedade na autorização para utilizar os cookies. Por padrão, os cookies das demais categorias estarão desabilitados.
                        Nosso site não faz a coleta automática de cookies, desta forma, caso deseje ter uma melhor e completa experiência em nosso site, sugerimos que você autorize todos os cookies, através do centro de preferências de privacidade, localizado no banner de consentimento de cookies e também em um botão acessível a qualquer momento nos websites da Cooperativa.
                        Você pode também facilmente ajustar as configurações do seu navegador com relação à ativação ou à desativação de cookies. Favor consultar a Função de Ajuda de seu navegador se necessário.
                    </p>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Como falar conosco sobre nossas políticas de privacidade e de cookies?
                        Estamos comprometidos em proteger a sua privacidade. Havendo eventuais dúvidas quanto nossas políticas ou considerações,
                        contate-nos através do e-mail <a href="mailto:compliance@agross.com.br">compliance@agross.com.br</a> .<br></br>
                        Estaremos sempre à disposição para esclarecê-las.
                    </p>
                </Typography>

            </div>
            <Divider style={{ borderColor: primary }} />
            <TitleComponent texto="Política de Proteção de Dados" />
            <div style={{ width: '70%' }} className=" mx-auto my-3">
                <Typography color={verdeescuro}>
                    <Typography variant="h5">
                        Regras de Proteção de dados on-line da AgRoss.
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Primeiramente, queremos agradecer à você pela visita em nosso site e interesse em nossa empresa. Esperamos que você possa usufruir dos conteúdos e informações feitos especialmente para você e conhecer nossa cultura e valores que permeiam nossas políticas.
                        Para a AgRoss, a proteção de dados é um tema de alta prioridade, além de sua privacidade ser importante para nós. Por tal razão, esta página é projetada para fornecer informações sobre como estamos seguindo as normas de proteção de dados e quais informações coletamos, enquanto você está navegando em nossos canais de comunicação, e em como essas informações são coletadas, utilizadas e protegidas por nós, respeitando e estando em total conformidade com a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados – “LGPD”).
                        Inicialmente e mais importante: os seus dados pessoais somente serão utilizados nos casos detalhados abaixo e não serão utilizados em quaisquer outros casos, sem que haja o seu prévio e expresso consentimento.
                        A presente Política de Privacidade de Dados aplica-se a coleta e o tratamento de dados para o site AgRoss, incluindo suas plataformas/frentes de comunicação: e-mails marketing, serviços e formulários.

                    </p>
                    <Typography variant="h5">
                        Coleta de Dados
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Quando você visita o site AgRoss, informações gerais são coletadas automaticamente (ou seja, não por meio de cadastro) e são enviadas para o nosso servidor, porém não são armazenadas como dados pessoais. Os servidores web que são usados tem por padrão armazenar os seguintes dados:
                    </p>
                    <ul>
                        <li>O nome do seu provedor de serviços de internet;</li>
                        <li>O website a partir do qual você nos visitou;</li>
                        <li>Os sites que você visita, quando você está com a gente;</li>
                        <li>O seu endereço de IP;</li>
                        <li>URL ou arquivo solicitado;</li>
                        <li>Data e horário da sua visita;</li>
                        <li>Volume de dado transmitido durante sua navegação;</li>
                        <li>Informação de status de servidor, como mensagens de erro durante a navegação.</li>
                    </ul>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Importante dizer que tais informações são analisadas de forma anônima, com a finalidade única de melhorar o conteúdo e funcionalidade do nosso site.
                    </p>
                    <Typography variant="h5">
                        Coleta e Processamento de Dados Pessoais
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Para que você tenha conhecimento e sinta-se seguro, importante dizer que os dados pessoais somente são coletados quando você os fornecer no curso, por exemplo, de seu cadastro em nosso site, do preenchimento de formulários ou envio de e-mails, ou por meio de encomendas de produtos ou serviços.
                        Além disso, seus dados pessoais permanecem com a nossa empresa e nosso provedor, e poderão ser compartilhados pela AgRoss somente com seus fornecedores/prestadores de serviços que necessitam ter acesso a tais dados para o cumprimento de suas obrigações junto à AgRoss. Caso isso ocorra, tais fornecedores/prestadores de serviços somente poderão utilizar seus dados pessoais, de acordo com as nossas instruções e as finalidades previstas abaixo nesta Política, e encontram-se contratualmente obrigados a cumprir com os requisitos legais de proteção de dados aplicáveis. Importante: seus dados pessoais apenas serão utilizados para qualquer outra finalidade quando você tiver dado seu consentimento específico para tanto. Ademais, válido dizer que você pode revogar o seu consentimento para a utilização dos seus dados pessoais a qualquer momento, basta enviar um e-mail solicitando a revogação do seu consentimento ao endereço de e-mail informado ao final desta Política ou ao representante de proteção de dados (dados para contato informado abaixo). Todavia, note que esta revogação de consentimento poderá impactar a disponibilidade de certos serviços e programas, bem como seu acesso às informações sobre a AgRoss, que são compartilhadas via canais digitais, como e-mails de marketing enviados com base em seus interesses.
                    </p>
                    <Typography variant="h5">
                        Retenção de Dados
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Os dados pessoais são armazenados pelo período necessário para realizar um serviço do qual você tenha solicitado ou para o cumprimento das finalidades com as quais você tenha concedido sua permissão, desde que não existam requisitos legais contrários.
                    </p>
                    <Typography variant="h5">
                        Segurança
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        A AgRoss possui medidas de segurança técnicas e organizacionais para prevenir que a informação que você disponibilizou seja manipulada, intencionalmente ou não, perdida, destruída ou acessada por pessoas não autorizadas. Quando os dados pessoais estão sendo coletados e processados, as informações serão transferidas de forma criptografada, a fim de evitar o uso indevido dos dados por terceiros. Nossas medidas de segurança são continuamente analisadas e revistas, de acordo com a tecnologia adequada.
                        Contudo, apesar de a AgRoss adotar os melhores esforços no sentido de preservar a sua privacidade e proteger os seus dados pessoais, nenhuma transmissão de informações é totalmente segura, de modo que a AgRoss não poderá garantir integralmente que todas as informações que recebe e/ou envia não sejam alvo de acessos não autorizados perpetrado, por meio de métodos desenvolvidos para obter informações de forma indevida, como falhas técnicas, vírus ou invasões do banco de dados do site.
                        De todo modo, na remota hipótese de incidência de episódios desta natureza, a AgRoss garante seus melhores esforços para remediar as consequências do evento.
                    </p>
                    <Typography variant="h5">
                        Direito de Obter e Corrigir Informações
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Importante dizer a você, que caso queira, você tem o direito de obter informações sobre todos os seus dados pessoais armazenados, sendo que poderá recebê-los, examiná-los e, se necessário, alterá-los ou apagá-los. Para fazer isso, basta enviar um e-mail ao endereço de e-mail do Encarregado de Dados, informado ao final desta Política. A eliminação dos seus dados pessoais será realizada, a menos que sejamos obrigados por lei a armazenar tais informações (conforme item de “Retenção de Dados” acima).
                        A AgRoss imputará seus melhores esforços para atender os pedidos de Usuários no menor espaço de tempo possível. No caso de requisição de exclusão, será respeitado o prazo de armazenamento mínimo de informações de usuários de aplicações de Internet, determinado pela legislação brasileira, além de outras leis aplicáveis como a legislação trabalhista e tributária.
                    </p>
                    <Typography variant="h5">
                        Legislação Aplicável e Foro
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Esta Política será interpretada de acordo com as leis, normas e regulamentos do Brasil, no idioma português, especialmente de acordo com a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados). Por tal razão, quaisquer conflitos serão dirimidos em território nacional e conforme a legislação brasileira aplicável e vigente à época, ficando eleito o foro de Campinas.
                    </p>
                    <Typography variant="h5">
                        Contato – Encarregado de Dados
                    </Typography>
                    <p style={{ textAlign: 'justify' }} className=" mx-auto">
                        Em razão do desenvolvimento evolutivo e acelerado da internet, reservamo-nos o direito de implantar as mudanças adequadas a qualquer momento. Portanto, caso haja alguma dúvida ou sugestão, entre em contato com o representante de proteção aos dados da AgRoss, que terá o maior prazer em ajudá-lo. O nosso Encarregado de Dados é: Patrícia dos Anjos Viana, 
                        e-mail: <a href="mailto:compliance@agross.com.br">compliance@agross.com.br</a>
                    </p>
                </Typography>
            </div>
        </div>
    )
}