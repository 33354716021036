import * as firebaseAuth from 'firebase/auth'
import {auth} from 'firebaseConfig';



export default class AuthService {

    async getLoggedUser(): Promise<firebaseAuth.User | null> {
        return new Promise((resolve, reject) => {
            const unsubscribe = firebaseAuth.onAuthStateChanged(
                auth, 
                user => {
                    unsubscribe();
                    resolve(user);
                }, 
                error => {
                    unsubscribe();
                    reject(error);
                }
            );
        });
    }

    async loginusuario(){
        return firebaseAuth.signInAnonymously(auth
            ).then(user => {
                
                return user;
                
            })
            .catch(error => {
                
                return error;
            });
    }

    async loggout(){
        return firebaseAuth.signOut(auth);
    }

    
}