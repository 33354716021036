import { Box, Button, FormControl, Input, InputAdornment, InputLabel, Link, Typography } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import { TitleComponent } from "app/shared/components"
import TokenContext from "app/shared/services/token/TokenContext"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import SearchIcon from '@mui/icons-material/Search';
import useIsMobile from "app/shared/hooks/useIsMobile";
import { UserLogged } from "app/shared/contexts";
import { ShowDivContext } from "app/shared/contexts/ShowDivContext/ShowDivContext";

const primary = lightGreen[800];
interface Produtos {
    codfilial: number;
    filial: string;
    codprod: number;
    produto: string;
    pontos: number;
    codmarca: number;
    marca: string;
    img: string;
}

const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

export const ProdutosParticipantes = () => {
    const cnpj = localStorage.getItem('cliente_cnpj')
    const token = useContext(TokenContext);
    const API_URL = 'https://data-api.agross.com.br/api/v1/';
    const [produtos, setProdutos] = useState<Produtos[]>([]);
    const [filteredProdutos, setFilteredProdutos] = useState<Produtos[]>([]);
    const [marcas, setMarcas] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('');
    const [loaderProdutos, setLoaderProdutos] = useState(false);
    const Mobile = useIsMobile();
    const { userLogged } = useContext(UserLogged);
    const { setShowDiv } = useContext(ShowDivContext);



    useEffect(() => {
        const fetchData = async () => {
            setLoaderProdutos(true);
            try {

                const consultacodfilial = await axios.get(
                    `${API_URL}saldo/${cnpj}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                try {

                    const consultaprod = await axios.get(
                        `${API_URL}participantes/${consultacodfilial.data.saldo[0].codfilial}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setProdutos(consultaprod.data.participantes);
                    setFilteredProdutos(consultaprod.data.participantes);
                    // Extrair as marcas e remover repetições
                    const marcas = consultaprod.data.participantes.map((participante: Produtos) => participante.marca);
                    const marcasUnicas: string[] = Array.from(new Set(marcas));
                    setMarcas(marcasUnicas);
                    console.log(marcasUnicas);
                    setLoaderProdutos(false);

                } catch (error) {
                    console.error('Erro ao fazer login', error);
                    setLoaderProdutos(false);
                }

            } catch (error) {
                console.error('Erro ao fazer login', error);
                setLoaderProdutos(false);
            }
        }
        fetchData();
    }, [cnpj, token])

    const filterBySerie = (pontos: number) => {
        setFilteredProdutos(produtos.filter(product => String(product.pontos) === String(pontos)));
        const marcas = produtos.filter(product => String(product.pontos) === String(pontos)).map((participante: Produtos) => participante.marca);
        const marcasUnicas: string[] = Array.from(new Set(marcas));
        setMarcas(marcasUnicas);
    };

    const resetFilter = () => {
        setFilteredProdutos(produtos);
        const marcas = produtos.map((participante: Produtos) => participante.marca);
        const marcasUnicas: string[] = Array.from(new Set(marcas));
        setMarcas(marcasUnicas);

    };

    useEffect(() => {
        setFilteredProdutos(produtos.filter(product =>
            product.produto.toLowerCase().includes(search.toLowerCase()) ||
            product.marca.toLowerCase().includes(search.toLowerCase())
        ));
        const marcas = produtos.filter(product =>
            product.produto.toLowerCase().includes(search.toLowerCase()) ||
            product.marca.toLowerCase().includes(search.toLowerCase())
        ).map((participante: Produtos) => participante.marca);
        const marcasUnicas: string[] = Array.from(new Set(marcas));
        setMarcas(marcasUnicas);
    }, [search, produtos]);

    return (
        <div className=" d-flex flex-column position-relative">
            {userLogged ?

                <div>
                    <div className={`loaderConta ${loaderProdutos ? 'd-flex' : 'd-none'}`}>
                        {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                        <img src={require('assets/loader.gif')} alt="loader" style={{ width: '13%' }} />
                    </div>
                    <TitleComponent texto="Produtos Participantes" />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, backgroundColor: bege, width: '80%' }} className={`rounded-2 mx-auto my-3 ${Mobile ? 'flex-column' : ''}`}>

                        <FormControl variant="standard">
                            <InputLabel>Pesquisar Produtos</InputLabel>
                            <Input value={search} onChange={(e) => setSearch(e.target.value)}
                                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                            />
                        </FormControl>
                        <div className={`d-flex align-items-center ${Mobile ? 'flex-column' : ''}`}>
                            <Button style={{ marginLeft: Mobile ? '' : '1rem' }} className={`${Mobile ? 'my-2 w-100' : ''}`} variant='outlined' onClick={() => filterBySerie(2)}>Série Normal (2 PONTOS)</Button>
                            <Button style={{ marginLeft: Mobile ? '' : '1rem' }} className={`${Mobile ? 'my-2 w-100' : ''}`} variant='outlined' onClick={() => filterBySerie(6)}>Série Ouro (6 PONTOS)</Button>
                            <Button style={{ marginLeft: Mobile ? '' : '1rem' }} className={`${Mobile ? 'my-2 w-100' : ''}`} variant='outlined' onClick={() => filterBySerie(12)}>Série Diamante (12 PONTOS)</Button>
                            <Button style={{ marginLeft: Mobile ? '' : '1rem' }} className={`${Mobile ? 'my-2 w-100' : ''}`} variant='outlined' onClick={() => resetFilter()}>Todos</Button>
                        </div>
                    </Box>

                    {marcas.map((marca) => (
                        <div
                            style={{ width: '80%', backgroundColor: 'white', boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.2)' }}
                            className="d-flex flex-column mx-auto divprodutospart my-3 rounded-3 p-3"
                        >
                            <div style={{ width: Mobile ? '100%' : '15%' }} className="mx-auto">
                                <img src={require(`assets/marcas/${marca}.png`)} alt={marca} style={{ width: '100%' }} />
                            </div>
                            <div className=" d-flex flex-wrap">
                                {filteredProdutos.filter(participante => participante.marca === marca).map(filteredParticipante => (
                                    <div key={filteredParticipante.codprod} className="d-flex flex-column mx-auto divprodutospart my-3 rounded-3 p-3"
                                        style={{ width: Mobile ? '100%' : '30%', backgroundColor: 'white', boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.2)', textAlign: 'center', color: verdeescuro }}>
                                        {filteredParticipante.produto}<br></br>
                                        {filteredParticipante.pontos} Pontos
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                :
                <Typography style={{ color: primary, fontSize: '0.7rem', textAlign: 'center' }} className="my-5">
                    Para ver os produtos{' '}
                    <Link href="#" color="secondary"
                        onClick={() => setShowDiv(true)}

                    >
                        Clique aqui
                    </Link>{' '}
                    e faça login!
                </Typography>
            }



        </div>
    )
}