import { createContext, Dispatch } from 'react';

export interface CartItem {
  id_pedido: number;
  id_brinde: number;
  quantidade: number;
  pontos: number;
  imagem: string;
  brinde: string;
  voltagem: string | null;
  observacao_item: string | null;
}

export interface Cart {
  id_pedido: number;
  totalpedido: number;
  cnpj: string;
  observacao: string;
  itens: CartItem[];
}




export interface CartState {
  items: CartItem[];
}

export type CartAction =
  | { type: 'ADD_ITEM', payload: CartItem }
  | { type: 'REMOVE_ITEM', payload: { id_brinde: number } }
  | { type: 'UPDATE_VOLTAGE', payload: { id_brinde: number, newVoltage: string | null } }
  | { type: 'UPDATE_OBSERVACAO_ITEM', payload: { id_brinde: number, newObservacao: string } }
  | { type: 'SET_ITEMS', payload: CartItem[] }
  | { type: 'INCREMENT_ITEM', payload: { id_brinde: number } }
  | { type: 'DECREMENT_ITEM', payload: { id_brinde: number } }
  | { type: 'CLEAR_CART' };


// Context
export const CartContext = createContext<{
  state: CartState;
  dispatch: Dispatch<CartAction>;
  pontosCliente: string;
  setPontosCliente: React.Dispatch<React.SetStateAction<string>>;
}>({
  state: { items: [] },
  dispatch: () => null,
  pontosCliente: '',
  setPontosCliente: () => null,
});


// Reducer
export const CartReducer = (state: CartState, action: CartAction) => {
  let newState: CartState = { ...state };
  switch (action.type) {
    case 'ADD_ITEM': {
      const existingItem = state.items.find(
        item => item.id_brinde === action.payload.id_brinde
      );
      if (existingItem) {
        newState = {
          ...state,
          items: state.items.map(item =>
            item.id_brinde === action.payload.id_brinde
              ? {
                ...item,
                quantidade: item.quantidade + action.payload.quantidade,
                pontos: Number(item.pontos) + Number(action.payload.pontos) * action.payload.quantidade
              }
              : item
          )
        };
      } else {
        newState = { ...state, items: [...state.items, action.payload] };
      }
      break;
    }
    case 'REMOVE_ITEM':
      newState = {
        ...state,
        items: state.items.filter(
          item => item.id_brinde !== action.payload.id_brinde
        )
      };
      break;
    case 'UPDATE_VOLTAGE':
      newState = {
        ...state,
        items: state.items.map(item =>
          item.id_brinde === action.payload.id_brinde
            ? { ...item, voltagem: action.payload.newVoltage }
            : item
        )
      };
      break;
    case 'UPDATE_OBSERVACAO_ITEM':
      newState = {
        ...state,
        items: state.items.map(item =>
          item.id_brinde === action.payload.id_brinde
            ? { ...item, observacao_item: action.payload.newObservacao }
            : item
        )
      };
      break;
    case 'SET_ITEMS':
      newState = {
        ...state,
        items: action.payload
      };
      break;
    case 'CLEAR_CART':
      newState = {
        ...state,
        items: []
      };
      localStorage.removeItem('cart');
      break;

      case 'INCREMENT_ITEM':
        newState = {
          ...state,
          items: state.items.map(item =>
            item.id_brinde === action.payload.id_brinde
              ? {
                  ...item,
                  quantidade: item.quantidade + 1,
                  pontos: (item.quantidade + 1) * (item.pontos/item.quantidade)
                }
              : item
          )
        };
        break;
    case 'DECREMENT_ITEM':
      const itemToDecrement = state.items.find(item => item.id_brinde === action.payload.id_brinde);
      if (itemToDecrement && itemToDecrement.quantidade === 1) {
        newState = {
          ...state,
          items: state.items.filter(
            item => item.id_brinde !== action.payload.id_brinde
          )
        };
      } else if (itemToDecrement) {
        newState = {
          ...state,
          items: state.items.map(item =>
            item.id_brinde === action.payload.id_brinde
              ? {
                ...item,
                quantidade: item.quantidade - 1,
                pontos: (item.quantidade - 1) * (item.pontos/item.quantidade)
              }
              : item
          )
        };
      }
      break;

    default:
      return state;
  }

  // Atualiza o localStorage com o novo estado do carrinho
  localStorage.setItem('cart', JSON.stringify(newState.items));

  return newState;
};







