import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from "@mui/material"
import { lightGreen } from "@mui/material/colors"
import { TitleComponent } from "app/shared/components"
import useIsMobile from "app/shared/hooks/useIsMobile"
import TokenContext from "app/shared/services/token/TokenContext"
import axios from "axios"
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"

import Swal from "sweetalert2"


const primary = lightGreen[800];

const bege = '#F7F0EA';

export const Esqueceu = () => {


    const token = React.useContext(TokenContext);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tokenSenha = query.get('token');
    const [showPasswordNovaSenha, setShowPasswordNovaSenha] = React.useState(false);
    const [showPasswordNovaSenhaRep, setShowPasswordNovaSenhaRep] = React.useState(false);
    const [loaderTrocaSenha, setLoaderTrocaSenha] = useState(false);
    const [altSenhaNova, setAltSenhaNova] = useState('');
    const [altSenhaNovaRep, setAltSenhaNovaRep] = useState('');
    const [clienteCNPJ, setClienteCNPJ] = useState('');
    const [TokenExpired, setTokenExpired] = useState(false);
    const navigate = useNavigate();
    const Mobile = useIsMobile();


    const handleClickShowPasswordNovaSenha = () => setShowPasswordNovaSenha((show) => !show);
    const handleClickShowPasswordNovaSenhaRep = () => setShowPasswordNovaSenhaRep((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleAlterarSenhaNova = (event: any) => {
        setAltSenhaNova(event.target.value);
    }

    const handleAlterarSenhaNovaRep = (event: any) => {
        setAltSenhaNovaRep(event.target.value);
    }
    useEffect(() => {

        console.log(tokenSenha);

        const fetchConsultaToken = async () => {
            setLoaderTrocaSenha(true);
            try {
                const response = await axios.get(`https://data-api.agross.com.br/api/v1/consulta_token?token=${tokenSenha}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });

                setClienteCNPJ(response.data.consulta[0].cnpj);
                if (response.data.consulta[0].expirado === 'S') {
                    setTokenExpired(true);
                } else {
                    setTokenExpired(false);
                }

                setLoaderTrocaSenha(false);
            } catch (error) {
                console.error('Erro ao alterar senha', error);

            }
        };

        fetchConsultaToken();
    }, [token, tokenSenha]);

    const handleAlterarSenha = () => {
        const alterarsenhacompleto = {
            novasenha: altSenhaNova,
            novasenharep: altSenhaNovaRep
        }
        const fetchAlterarSenha = async () => {
            setLoaderTrocaSenha(true);
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/reset?cnpj=${clienteCNPJ}&senha=${alterarsenhacompleto.novasenha}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.data.reset[0].reset === 'S') {
                    Swal.fire(
                        'Senha Alterada!',
                        'Sua senha foi alterada com sucesso!',
                        'success'
                    ).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
                            // Recarrega a página atual.
                            navigate('/');
                        }
                    });
                }
                setLoaderTrocaSenha(false);
            } catch (error) {
                console.error('Erro ao alterar senha', error);
                setLoaderTrocaSenha(true);
            }
        };

        fetchAlterarSenha();

    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })
    return (
        <div className=" d-flex flex-column">
            <div className={`loader ${loaderTrocaSenha ? 'd-flex' : 'd-none'}`}>
                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '20%' }} />
            </div>
            {TokenExpired ?
                <TitleComponent texto="Sua permissão para reset de senha foi expirada ou é inválida." variant="h5" />
                :
                <div>
                    <TitleComponent texto="Recupere Sua Senha" />
                    <div className={`${Mobile ? '' : 'w-25'} d-flex flex-column m-auto`}>
                        <FormControl sx={{ mt: 2 }} variant="filled">
                            <InputLabel htmlFor="senhanova" style={{ color: primary }}>Nova Senha</InputLabel>
                            <FilledInput

                                onChange={handleAlterarSenhaNova}
                                style={{ color: primary }}
                                id="senhanova"
                                type={showPasswordNovaSenha ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ color: primary }}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPasswordNovaSenha}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPasswordNovaSenha ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl sx={{ mt: 2 }} variant="filled">
                            <InputLabel htmlFor="senhanovarep" style={{ color: primary }}>Repita a Nova Senha</InputLabel>
                            <FilledInput

                                onChange={handleAlterarSenhaNovaRep}
                                style={{ color: primary }}
                                id="senhanovarep"
                                type={showPasswordNovaSenhaRep ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ color: primary }}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPasswordNovaSenhaRep}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPasswordNovaSenhaRep ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Button
                            className=' my-3'
                            variant="contained"
                            onClick={() => {
                                if (altSenhaNova === '') {
                                    Swal.fire(
                                        'Erro',
                                        'Preencha a senha nova',
                                        'info'
                                    )
                                } else if (altSenhaNovaRep === '') {
                                    Swal.fire(
                                        'Erro',
                                        'Preencha a confirmação de senha nova',
                                        'info'
                                    )
                                } else if (altSenhaNova !== altSenhaNovaRep) {
                                    Swal.fire(
                                        'Erro',
                                        'As senhas novas não são iguais',
                                        'info'
                                    )
                                } else {
                                    swalWithBootstrapButtons.fire({
                                        title: 'Alterar Senha',
                                        text: "Deseja alterar senha?",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Sim',
                                        cancelButtonText: 'Não',
                                        reverseButtons: true
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            handleAlterarSenha();
                                        }
                                    })
                                }

                            }}
                            size="small"
                            style={{ color: 'white', border: `1px solid ${bege}`, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}

                        >
                            Alterar Senha
                        </Button>
                    </div>
                </div>
            }


        </div>
    )
}