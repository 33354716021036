const login = 'suporte-mkt2022@agross.com.br';
const password = 'Ces2020@mkt';


async function fetchToken() {
    const url = `https://data-api.agross.com.br/api/auth/login/?email=${login}&password=${password}`;

    const response = await fetch(url, {
        method: 'POST',
    });

    const data = await response.json();
    

    return data.access_token; // Substitua "token" pelo nome correto da chave do token na resposta da sua API.
}

export default fetchToken;
