import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import {
    Button,
    Modal,
    Typography,
    Box,
    Link,
    Card,
    CardContent,
    CardActionArea,
    Grid,
    FormControl,
    FormControlLabel,
    InputLabel,
    Input,
    InputAdornment,
    Tooltip,
    Checkbox
} from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import './PortfolioComponente.css';
import { useLocation, useNavigate } from 'react-router-dom';
import portfolioData from 'assets/PORTFOLIO2.json';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import AddIcon from '@mui/icons-material/Add';
import { UserLogged } from 'app/shared/contexts';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import SearchIcon from '@mui/icons-material/Search';
import { Fornecedores } from '../fornecedores/Fornecedores';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import TroqueGanhe from 'assets/portfolio/2pontos.png'
import ArrowDown from 'assets/portfolio/down-arrow.png'

interface Product {
    Produto: string;
    Marca: string;
    DESCRICAO: string;
    LINK_PRODUTO: string;
    LINK_FICHA: string;
    Linha: string | null;
}

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${verdeescuro}`,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export const PortfolioComponente = () => {
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();
    const { userLogged } = useContext(UserLogged);
    const [data, setData] = useState<Product[]>([]);
    const [filteredData, setFilteredData] = useState<Product[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [isArrowDown, setIsArrowDown] = useState(true);
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
    const Mobile = useIsMobile();
    const query = new URLSearchParams(location.search);
    const categoria = query.get('Categoria');

    const handleItemClick = useCallback((item: string) => {
        setSelectedItem(item);
    }, []);

    const handleOpen = (product: Product) => {
        setCurrentProduct(product);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const filterByType = (type: string) => {
        setFilteredData(data.filter(product => product.Linha === type));
    };

    const resetFilter = () => {
        setFilteredData(data);
    };

    const handleentraremcontato = (produto: string, marca: string) => {
        type LinksWhatsAppType = {
            [key: string]: string;
        };
        const linksWhatsApp: LinksWhatsAppType = {
            'CARAZINHO': `https://api.whatsapp.com/send?phone=5519986010077&text=Olá, quero comprar o produto ${produto} da ${marca}.`,
            'CAMPINAS': `https://api.whatsapp.com/send?phone=551937213300&text=Olá, quero comprar o produto ${produto} da ${marca}.`,
            'CARIACICA': `https://api.whatsapp.com/send?phone=5519993898853&text=Olá, quero comprar o produto ${produto} da ${marca}.`,
            'GOIANA': `https://api.whatsapp.com/send?phone=5519993799714&text=Olá, quero comprar o produto ${produto} da ${marca}.`,
            'LINS': `https://api.whatsapp.com/send?phone=551435221807&text=Olá, quero comprar o produto ${produto} da ${marca}.`,
            'PETROLINA': `https://api.whatsapp.com/send?phone=5519993818909&text=Olá, quero comprar o produto ${produto} da ${marca}.`,
            'POUSO ALEGRE': `https://api.whatsapp.com/send?phone=5519999982192&text=Olá, quero comprar o produto ${produto} da ${marca}.`,
            'SETE LAGOAS': `https://api.whatsapp.com/send?phone=5519993891516&text=Olá, quero comprar o produto ${produto} da ${marca}.`
        };
        if (userLogged) {
            const filial = localStorage.getItem('filial');
            if (filial) {
                const whatsappLink = linksWhatsApp[filial];
                window.open(whatsappLink, '_blank');
            }
        } else {
            navigate('/contato');
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        let preparedData: Product[] = [];

        if (portfolioData.some(item => Array.isArray(item))) {
            preparedData = portfolioData.flat() as unknown as Product[];
        } else if (portfolioData instanceof Array) {
            preparedData = portfolioData as unknown as Product[];
        } else {
            console.error("Os dados não são um array ou não correspondem à interface 'Product'");
        }

        const sortedData = preparedData.sort((a, b) => {
            if (a.Marca && b.Marca) {
                return a.Marca.localeCompare(b.Marca);
            }
            return (a.Marca ? -1 : 1);
        });

        setData(sortedData);
        setFilteredData(sortedData);

        setTimeout(() => {
            if (categoria) {
                setFilteredData(sortedData.filter(product => product.Linha === categoria));
            }
        }, 1000);
    }, []);

    useEffect(() => {
        setFilteredData(data.filter(product =>
            product.Produto.toLowerCase().includes(search.toLowerCase()) ||
            product.Marca.toLowerCase().includes(search.toLowerCase())
        ));
    }, [search, data]);

    useEffect(() => {
        filteredData.map((product, index) => console.log(product.Marca));
    }, []);

    function groupByTrademark(data: Product[]): { [key: string]: Product[] } {
        return data.reduce((acc: { [key: string]: Product[] }, product: Product) => {
            const { Marca } = product;
            if (!acc[Marca]) {
                acc[Marca] = [];
            }
            acc[Marca].push(product);
            return acc;
        }, {});
    }

    const groupedData = groupByTrademark(filteredData);

    const [checkedState, setCheckedState] = useState(new Array(23).fill(false));

    const handleCheckboxChange = (index: number) => {
        const updatedCheckedState = checkedState.map((item, idx) =>
            idx === index ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };

    const handleClearFilters = () => {
        setCheckedState(new Array(23).fill(false));
    };

    const toggleArrow = () => {
        setIsArrowDown(!isArrowDown);
        setIsMenuCollapsed(!isMenuCollapsed);
    };

    const ProductGroups = () => {
        return (
            <div>
                {Object.entries(groupedData).map(([marca, products], index) => (
                    <div key={marca} className='product-container'>
                        <div className='brand-logo'>
                            <img src={`../marcas/${marca}.png`} alt={`${marca} logo`} />
                        </div>
                        <div className='product-grid2'>
                            {products.map((product, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card className='product-card'>
                                        <CardActionArea className="MuiCardActionArea-root">
                                            <CardContent className="MuiCardContent-root" onClick={() => { handleOpen(product); handleItemClick(product.Produto) }}>
                                                <div style={{
                                                    width: '60%',
                                                    height: '80%',
                                                    marginTop: '-10%',
                                                }}>
                                                    <Typography variant="body1" color="text.secondary" style={{
                                                        marginTop: '3px',
                                                        width: '95%',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}>
                                                        {product.Produto}
                                                    </Typography>
                                                    <div style={{
                                                        position: 'absolute',
                                                        bottom: '10px',
                                                        left: '40%',
                                                        transform: 'translateX(-50%)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        width: '100%'
                                                    }}>
                                                        <Tooltip title="A cada R$ 1,00 em compras" arrow>
                                                            <img src={TroqueGanhe} />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    width: '30%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Tooltip title="Ver Mais" arrow placement='top'>
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ color: primary, border: `1px solid ${primary}`, marginBottom: '10px', transition: 'background-color 0.3s' }}
                                                            onMouseOver={(e) => {
                                                                e.currentTarget.style.backgroundColor = '#DFF2E1';
                                                            }}
                                                            onMouseOut={(e) => {
                                                                e.currentTarget.style.backgroundColor = 'transparent';
                                                            }}
                                                        >
                                                            <AddIcon />
                                                        </Button>
                                                    </Tooltip>

                                                    <Tooltip title="Comprar" arrow>
                                                        <Button
                                                            onClick={() => handleentraremcontato(product.Produto, product.Marca)}
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ color: primary, border: `1px solid ${primary}`, transition: 'background-color 0.3s' }}
                                                            onMouseOver={(e) => {
                                                                e.currentTarget.style.backgroundColor = '#DFF2E1';
                                                            }}
                                                            onMouseOut={(e) => {
                                                                e.currentTarget.style.backgroundColor = 'transparent';
                                                            }}
                                                        >
                                                            <LocalGroceryStoreIcon />
                                                        </Button>
                                                    </Tooltip>

                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </div>
                        <hr style={{ margin: '2rem 0', borderTop: '1px solid black' }} />
                    </div>
                ))}
            </div>

        );
    };

    return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flex: '1' }}>
            <div style={{ width: '80%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <div className=" d-flex flex-column" style={{ marginTop: '5%' }}>
                    {Mobile ?
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                            Produtos Marca AgRoss
                        </Typography>
                        :
                        <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro, textAlign: 'center' }}>
                            Produtos Marca AgRoss
                        </Typography>
                    }
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>

                {
                    Mobile ?
                        <div className=' d-flex flex-column mb-5 mt-3'>
                            <div className='divaminoagross p-4 my-3'>
                                <div className=' position-absolute' style={{ bottom: '3rem' }} >
                                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: 'white' }}>
                                        AminoAgRoss Fertilizante Foliar
                                    </Typography>
                                    <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                        É um fertilizante foliar organomineral com macro e micronutrientes.
                                    </Typography>
                                    <Button variant="contained" size='large' color="primary" className='descricaoprodutos'
                                        onClick={() => {
                                            navigate('/aminoagross');
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Leia mais
                                    </Button>
                                    <Button variant="contained" size='large' color="primary" className='descricaoprodutos mx-2' onClick={() => handleentraremcontato('AminoAgRoss Fertilizante Foliar', 'AgRoss')}>
                                        Comprar
                                        <LocalGroceryStoreIcon />
                                    </Button>
                                </div>
                            </div>
                            <div className='divsemente1051 p-4 my-3'>
                                <div className=' position-absolute' style={{ bottom: '3rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: 'white' }}>
                                        Semente Milho Verde Híbrido AG1051
                                    </Typography>
                                    <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                        Atendendo a demanda de mercado por uma semente de Milho Verde.
                                    </Typography>
                                    <Button variant="contained" size='large' color="primary" href="https://agross.com.br/project/semente-milho/" className='descricaoprodutos'>
                                        Leia mais
                                    </Button>
                                    <Button variant="contained" size='large' color="primary" className='descricaoprodutos mx-2' onClick={() => handleentraremcontato('Semente Milho Verde Híbrido AG1051', 'AgRoss')}>
                                        Comprar
                                        <LocalGroceryStoreIcon />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className=' d-flex justify-content-around mb-5 mt-3' >
                            <div className='divaminoagross p-5 mx-2'
                            >
                                <div className=' position-absolute' style={{ bottom: '3rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: 'white' }}>
                                        AminoAgRoss Fertilizante Foliar
                                    </Typography>
                                    <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                        É um fertilizante foliar organomineral com macro e micronutrientes.
                                    </Typography>
                                    <div>
                                        <Button variant="contained" size='large' color="primary" className='descricaoprodutos'
                                            onClick={() => {
                                                navigate('/aminoagross');
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            Leia mais
                                        </Button>
                                        <Button variant="contained" size='large' color="primary" className='descricaoprodutos mx-2' onClick={() => handleentraremcontato('AminoAgRoss Fertilizante Foliar', 'AgRoss')}>
                                            Comprar
                                            <LocalGroceryStoreIcon />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className='divsemente1051 p-5 mx-2'
                            >
                                <div className=' position-absolute' style={{ bottom: '3rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: 'white' }}>
                                        Semente Milho Verde Híbrido AG1051
                                    </Typography>
                                    <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                        Atendendo a demanda de mercado por uma semente de Milho Verde.
                                    </Typography>
                                    <div>
                                        <Button variant="contained" size='large' color="primary" href="/sementemilho" className='descricaoprodutos'>
                                            Leia mais
                                        </Button>
                                        <Button variant="contained" size='large' color="primary" className='descricaoprodutos mx-2' onClick={() => handleentraremcontato('Semente Milho Verde Híbrido AG1051', 'AgRoss')}>
                                            Comprar
                                            <LocalGroceryStoreIcon />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

                <div className=" d-flex flex-column my-5">
                    <Typography fontFamily={'Arima Madurai'} variant={Mobile ? "h4" : "h3"} style={{ color: verdeescuro, textAlign: 'center' }}>
                        Todos os produtos do Portfólio AgRoss
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <Typography variant="body1" style={{ textAlign: 'center', color: primary, width: `${Mobile ? '100%' : '60%'}`, marginLeft: 'auto', marginRight: 'auto' }} >
                        A AgRoss se preocupa com a assertividade das compras realizadas pelos nossos clientes.
                        Pesquise os produtos que melhor atenderá sua necessidade.
                    </Typography>
                </div>

                {
                    Mobile ?
                        <div className='align-divs-mobile'>
                            <div className="product-section-mobile">
                                <Box sx={{
                                    display: 'flex', justifyContent: 'space-between', p: 2, backgroundColor: '#FAF0EB', borderRadius: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', width: '100%'
                                }} >
                                    <FormControl variant="standard" style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%', alignContent: 'end' }} >
                                        <SearchIcon />
                                        <Input value={search} placeholder='Pesquisar Produtos...' style={{ marginLeft: '1rem', width: '100%' }} onChange={(e) => setSearch(e.target.value)} />
                                    </FormControl>
                                </Box>
                                <div className='clearDiv'>
                                    <Button variant="text" onClick={handleClearFilters}>Limpar Filtros</Button>
                                </div>
                                <Box className="filter-box">
                                    <div onClick={toggleArrow} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="h6">Filtros</Typography>
                                        <img
                                            src={ArrowDown}
                                            alt="Info"
                                            style={{ width: '24px', height: '24px', transform: isArrowDown ? 'rotate(0deg)' : 'rotate(180deg)', cursor: 'pointer' }}
                                            onClick={toggleArrow}
                                        />
                                    </div>
                                    {!isMenuCollapsed && (
                                        <>
                                            <hr className="filter-divider" />
                                            <Typography variant="subtitle1">Linha de atuação:</Typography>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[0]} onChange={() => handleCheckboxChange(0)} />}
                                                    label="Biodefensivos"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[1]} onChange={() => handleCheckboxChange(1)} />}
                                                    label="Defensivos"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[2]} onChange={() => handleCheckboxChange(2)} />}
                                                    label="Fertilizantes"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[3]} onChange={() => handleCheckboxChange(3)} />}
                                                    label="Sementes"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[4]} onChange={() => handleCheckboxChange(4)} />}
                                                    label="Outros"
                                                />
                                            </FormControl>
                                            <hr className="filter-divider" />
                                            <Typography variant="subtitle1">Fornecedores:</Typography>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[5]} onChange={() => handleCheckboxChange(5)} />}
                                                    label="Adama"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[6]} onChange={() => handleCheckboxChange(6)} />}
                                                    label="Albaugh"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[7]} onChange={() => handleCheckboxChange(7)} />}
                                                    label="Atta Kill"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[8]} onChange={() => handleCheckboxChange(8)} />}
                                                    label="Basf"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[9]} onChange={() => handleCheckboxChange(9)} />}
                                                    label="Bayer"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[10]} onChange={() => handleCheckboxChange(10)} />}
                                                    label="Bequisa"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[11]} onChange={() => handleCheckboxChange(11)} />}
                                                    label="Brevant"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[12]} onChange={() => handleCheckboxChange(12)} />}
                                                    label="Corteva"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[13]} onChange={() => handleCheckboxChange(13)} />}
                                                    label="FMC"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[14]} onChange={() => handleCheckboxChange(14)} />}
                                                    label="Gowan"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[15]} onChange={() => handleCheckboxChange(15)} />}
                                                    label="Ourofino"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[16]} onChange={() => handleCheckboxChange(16)} />}
                                                    label="OxiQuímica"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[17]} onChange={() => handleCheckboxChange(17)} />}
                                                    label="Sakata"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[18]} onChange={() => handleCheckboxChange(18)} />}
                                                    label="Sumitomo Chemical"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[19]} onChange={() => handleCheckboxChange(19)} />}
                                                    label="UPL"
                                                />
                                            </FormControl>
                                            <hr className="filter-divider" />
                                            <Typography variant="subtitle1">Pontuando no Troque e Ganhe:</Typography>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[20]} onChange={() => handleCheckboxChange(20)} />}
                                                    label="2 pontos (série Normal)"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[21]} onChange={() => handleCheckboxChange(21)} />}
                                                    label="6 pontos (série Ouro)"
                                                />
                                            </FormControl>
                                            <FormControl component="fieldset" className="MuiFormControl-root">
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkedState[22]} onChange={() => handleCheckboxChange(22)} />}
                                                    label="12 pontos (série Diamante)"
                                                />
                                            </FormControl>
                                            <hr className="filter-divider" />

                                            <FormControl component="fieldset" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <button
                                                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                                                    style={{
                                                        backgroundColor: 'green',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        borderRadius: '8px',
                                                        padding: '10px 20px',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s ease'
                                                    }}
                                                    onMouseDown={(e) => e.currentTarget.style.backgroundColor = 'darkgreen'}
                                                    onMouseUp={(e) => e.currentTarget.style.backgroundColor = 'green'}
                                                >
                                                    Aplicar Filtros
                                                </button>
                                            </FormControl>
                                        </>
                                    )}
                                </Box>
                            </div>
                            <div className="product-grid">
                                <ProductGroups />
                            </div>
                        </div>
                        :
                        <div className='align-divs'>
                            <div className="product-section">
                                <Box sx={{
                                    display: 'flex', justifyContent: 'space-between', p: 2, backgroundColor: '#FAF0EB', borderRadius: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', width: '100%'
                                }} >
                                    < FormControl variant="standard" style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%', alignContent: 'end' }} >
                                        <SearchIcon />
                                        <Input value={search} placeholder='Pesquisar Produtos...' style={{ marginLeft: '1rem', width: '100%' }} onChange={(e) => setSearch(e.target.value)} />
                                    </FormControl>

                                </Box>
                                <div className='clearDiv'>
                                    <Typography variant="h6">Filtros</Typography>
                                    <Button variant="text" onClick={handleClearFilters}>Limpar Filtros</Button>
                                </div>
                                <Box className="filter-box">
                                    <Typography variant="subtitle1">Linha de atuação:</Typography>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[0]} onChange={() => handleCheckboxChange(0)} />}
                                            label="Biodefensivos"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[1]} onChange={() => handleCheckboxChange(1)} />}
                                            label="Defensivos"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[2]} onChange={() => handleCheckboxChange(2)} />}
                                            label="Fertilizantes"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[3]} onChange={() => handleCheckboxChange(3)} />}
                                            label="Sementes"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[4]} onChange={() => handleCheckboxChange(4)} />}
                                            label="Outros"
                                        />
                                    </FormControl>
                                    <hr className="filter-divider" />
                                    <Typography variant="subtitle1">Fornecedores:</Typography>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[5]} onChange={() => handleCheckboxChange(5)} />}
                                            label="Adama"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[6]} onChange={() => handleCheckboxChange(6)} />}
                                            label="Albaugh"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[7]} onChange={() => handleCheckboxChange(7)} />}
                                            label="Atta Kill"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[8]} onChange={() => handleCheckboxChange(8)} />}
                                            label="Basf"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[9]} onChange={() => handleCheckboxChange(9)} />}
                                            label="Bayer"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[10]} onChange={() => handleCheckboxChange(10)} />}
                                            label="Bequisa"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[11]} onChange={() => handleCheckboxChange(11)} />}
                                            label="Brevant"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[12]} onChange={() => handleCheckboxChange(12)} />}
                                            label="Corteva"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[13]} onChange={() => handleCheckboxChange(13)} />}
                                            label="FMC"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[14]} onChange={() => handleCheckboxChange(14)} />}
                                            label="Gowan"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[15]} onChange={() => handleCheckboxChange(15)} />}
                                            label="Ourofino"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[16]} onChange={() => handleCheckboxChange(16)} />}
                                            label="OxiQuímica"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[17]} onChange={() => handleCheckboxChange(17)} />}
                                            label="Sakata"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[18]} onChange={() => handleCheckboxChange(18)} />}
                                            label="Sumitomo Chemical"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[19]} onChange={() => handleCheckboxChange(19)} />}
                                            label="UPL"
                                        />
                                    </FormControl>
                                    <hr className="filter-divider" />
                                    <Typography variant="subtitle1">Pontuando no Troque e Ganhe:</Typography>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[20]} onChange={() => handleCheckboxChange(20)} />}
                                            label="2 pontos (série Normal)"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[21]} onChange={() => handleCheckboxChange(21)} />}
                                            label="6 pontos (série Ouro)"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" className="MuiFormControl-root">
                                        <FormControlLabel
                                            control={<Checkbox checked={checkedState[22]} onChange={() => handleCheckboxChange(22)} />}
                                            label="12 pontos (série Diamante)"
                                        />
                                    </FormControl>
                                </Box>
                            </div>


                            <div className="product-grid">
                                <ProductGroups />
                            </div>
                        </div>
                }


                {
                    Mobile ?
                        <Modal open={open} onClose={handleClose}>
                            <Box
                                sx={{
                                    ...style,
                                    width: '90%',
                                    height: 'auto',
                                    padding: Mobile ? 1 : 2,
                                    borderRadius: '0.5rem',
                                    position: 'relative',
                                    backgroundColor: 'white',
                                    outline: 'none',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    overflowY: Mobile ? 'auto' : 'hidden' // Adicionando scroll interno para garantir que o conteúdo não fique escondido
                                }}
                            >
                                {currentProduct && (
                                    <>
                                        <CancelRoundedIcon
                                            style={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                zIndex: 1,
                                                minWidth: 'auto',
                                                width: '2rem',
                                                height: '2rem',
                                                color: primary,
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleClose}
                                        />

                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: '#5A874B',
                                                textAlign: 'center',
                                                fontWeight: 'bolder',
                                                marginTop: Mobile ? '10px' : '20px'
                                            }}
                                        >
                                            {currentProduct.Produto.toUpperCase()}
                                        </Typography>

                                        <Box style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            justifyContent: 'center',
                                            marginTop: Mobile ? '10px' : '20px',
                                            padding: '12px'
                                        }}>
                                            <Typography
                                                style={{
                                                    color: '#575757',
                                                    textAlign: 'justify',
                                                    fontSize: Mobile ? '14px' : '16px',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {currentProduct.DESCRICAO}
                                            </Typography>
                                        </Box>

                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                width: '100%',
                                                marginTop: Mobile ? '10px' : '50px',
                                                textAlign: 'left',
                                                paddingLeft: Mobile ? '10px' : '20px'
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: Mobile ? '0px' : '20px' }}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: Mobile ? '16px' : '18px', marginRight: '0%' }}>Fornecedor:</Typography>
                                                <img src={`../marcas/${currentProduct.Marca}.png`} alt={`${currentProduct.Marca} logo`} style={{ width: '40%', height: 'auto' }} />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: Mobile ? '10px' : '20px' }}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: Mobile ? '16px' : '18px', marginRight: '5%' }}>Familia:</Typography>
                                                <Typography variant="body2" style={{ fontSize: '16px' }}>Benzimidazol</Typography>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: Mobile ? '16px' : '18px', marginRight: '3%' }}>Linha de atuação:</Typography>
                                                <Typography variant="body2" style={{ fontSize: '16px' }}>Controle de Pragas</Typography>
                                            </div>
                                        </Box>

                                        <Box className='d-flex flex-row' style={{ justifyContent: 'space-evenly', width: '100%', marginTop: Mobile ? '10%' : '50px' }}>
                                            {currentProduct.LINK_PRODUTO &&
                                                <Link target='_blank' href={currentProduct.LINK_PRODUTO}>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor: '#0E9AFF',
                                                            color: 'white',
                                                            textTransform: 'none',
                                                            fontSize: Mobile ? '16px' : '18px',
                                                            fontWeight: 'bold',
                                                            transition: 'background-color 0.3s'
                                                        }}
                                                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#58B5FF'}
                                                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#0E9AFF'}
                                                    >
                                                        Página do Produto
                                                    </Button>
                                                </Link>
                                            }

                                            <Button
                                                onClick={() => handleentraremcontato(currentProduct.Produto, currentProduct.Marca)}
                                                variant="contained"
                                                style={{
                                                    backgroundColor: '#5A874B',
                                                    color: 'white',
                                                    textTransform: 'uppercase',
                                                    fontSize: Mobile ? '16px' : '18px',
                                                    fontWeight: 'bold',
                                                    marginLeft: '20px',
                                                    transition: 'background-color 0.3s'
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#79B764'}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#5A874B'}
                                            >
                                                Comprar
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Modal>

                        :
                        <Modal open={open} onClose={handleClose}>
                            <Box
                                sx={{
                                    ...style,
                                    width: '50%',
                                    height: 'auto',
                                    padding: Mobile ? 1 : 2,
                                    borderRadius: '0.5rem',
                                    position: 'relative',
                                    backgroundColor: 'white',
                                    outline: 'none',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    overflowY: Mobile ? 'auto' : 'hidden' // Adicionando scroll interno para garantir que o conteúdo não fique escondido
                                }}
                            >
                                {currentProduct && (
                                    <>
                                        <CancelRoundedIcon
                                            style={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                zIndex: 1,
                                                minWidth: 'auto',
                                                width: '2rem',
                                                height: '2rem',
                                                color: primary,
                                                cursor: 'pointer'
                                            }}
                                            onClick={handleClose}
                                        />

                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: '#5A874B',
                                                textAlign: 'center',
                                                fontWeight: 'bolder',
                                                marginTop: Mobile ? '10px' : '20px'
                                            }}
                                        >
                                            {currentProduct.Produto.toUpperCase()}
                                        </Typography>

                                        <Box style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            justifyContent: 'center',
                                            marginTop: Mobile ? '10px' : '20px'
                                        }}>
                                            <Typography
                                                style={{
                                                    color: '#575757',
                                                    textAlign: 'justify',
                                                    fontSize: Mobile ? '14px' : '16px',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {currentProduct.DESCRICAO}
                                            </Typography>
                                        </Box>

                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: Mobile ? 'column' : 'row',
                                                justifyContent: 'space-around',
                                                width: '100%',
                                                marginTop: '5%',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <div className='fornecedor-modal' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: Mobile ? '100%' : '30%' }}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: Mobile ? '16px' : '18px', marginRight: '10px' }}>Fornecedor:</Typography>
                                                <img src={`../marcas/${currentProduct.Marca}.png`} alt={`${currentProduct.Marca} logo`} style={{ width: Mobile ? '30%' : '50%', height: 'auto' }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: Mobile ? '100%' : '30%', marginTop: Mobile ? '10px' : '0' }}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: Mobile ? '16px' : '18px' }}>Familia:</Typography>
                                                <Typography variant="body2" style={{ fontSize: Mobile ? '14px' : '16px' }}>Benzimidazol</Typography>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: Mobile ? '100%' : '30%', marginTop: Mobile ? '10px' : '0' }}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: Mobile ? '16px' : '18px' }}>Linha de atuação:</Typography>
                                                <Typography variant="body2" style={{ fontSize: Mobile ? '14px' : '16px' }}>Controle de Pragas</Typography>
                                            </div>
                                        </Box>

                                        <Box className='d-flex flex-row' style={{ justifyContent: 'space-evenly', width: '100%', marginTop: '10%', }}>
                                            {currentProduct.LINK_PRODUTO &&
                                                <Link target='_blank' href={currentProduct.LINK_PRODUTO}>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor: '#0E9AFF',
                                                            color: 'white',
                                                            textTransform: 'none',
                                                            fontSize: Mobile ? '16px' : '18px',
                                                            fontWeight: 'bold',
                                                            transition: 'background-color 0.3s'
                                                        }}
                                                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#58B5FF'}
                                                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#0E9AFF'}
                                                    >
                                                        Página do Produto
                                                    </Button>
                                                </Link>
                                            }

                                            <Button
                                                onClick={() => handleentraremcontato(currentProduct.Produto, currentProduct.Marca)}
                                                variant="contained"
                                                style={{
                                                    backgroundColor: '#5A874B',
                                                    color: 'white',
                                                    textTransform: 'uppercase',
                                                    fontSize: Mobile ? '16px' : '18px',
                                                    fontWeight: 'bold',
                                                    marginLeft: '20px',
                                                    transition: 'background-color 0.3s'
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#79B764'}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#5A874B'}
                                            >
                                                Comprar
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Modal>

                }


                <div style={{ color: primary }} className="my-5 py-5 px-5">
                    <div className={`text-lg-center ${Mobile ? 'd-flex flex-column' : ''}`} >
                        <Typography variant={Mobile ? "body2" : "body1"} style={{ textAlign: 'center' }}>
                            NOSSOS PRINCIPAIS
                        </Typography>
                        <Typography fontFamily={'Arima Madurai'} variant={Mobile ? "h4" : "h3"} style={{ color: verdeescuro }} className="mt-2 text-center">
                            Fornecedores
                        </Typography>
                        <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        <Fornecedores />
                    </div>
                </div>
            </div >
        </div >
    );
};
