// NewsItem.tsx

import React from 'react';
import { News } from './News';
import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { lightGreen } from '@mui/material/colors';

const primary = lightGreen[800];
interface NewsItemProps {
  news: News;
}

const NewsItem: React.FC<NewsItemProps> = ({ news }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/noticias/${news.id}`);
  };

  return (

    <Card onClick={handleClick} style={{ height: '25rem' }}>
      
      <CardMedia
        component="img"
        height="auto"
        image={news.imageUrl}
        alt={news.title}
      />
      <CardContent>
        <Typography variant="h5" component="div">{news.title}</Typography>
        <Typography variant="body2" color="text.secondary">{news.introText}</Typography>
      </CardContent>
    </Card>
  );
};

export default NewsItem;
