import { AuthContext } from "./AuthContext";

type AuthProviderProps = {
    
    children : any;
}


export default function AuthProvider(props: AuthProviderProps){
return (
    <AuthContext.Provider value={{}}>
        {props.children}
    </AuthContext.Provider>
)
}