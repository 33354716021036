import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importando useNavigate do react-router-dom

export const Ppt = () => {
    const navigate = useNavigate(); // Novo método para navegação

    useEffect(() => {
        // Criar um elemento 'a' e configurá-lo
        const link = document.createElement('a');
        link.href = 'assets/materiais/Apresentação Institucional 23-24.pptx';
        link.download = 'Apresentação Institucional 23-24.pptx'; // O nome do arquivo que será salvo pode ser ajustado aqui
        document.body.appendChild(link);

        // Simular um clique no link para iniciar o download
        link.click();

        // Remover o link do DOM
        document.body.removeChild(link);

        // Redirecionar após o início do download (você pode querer adicionar um atraso aqui)
        setTimeout(() => {
            navigate('/'); // Navegue para a página inicial. Ajuste se a sua rota inicial for diferente.
        }, 2000); // Ajuste o tempo conforme necessário para lidar com o tempo de inicialização do download

    }, [navigate]); // Dependência para o hook useEffect

    return (
        <div>
            {/* O conteúdo visível ao usuário enquanto o download está sendo preparado, se necessário */}
            Preparando o download...
        </div>
    );
};
