import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import axios from 'axios';

interface ItemCarrinho {
  id_solicitacao: string;
  cnpj: string;
  status: string;
  codbrinde: number;
  brinde: string;
  quantidade: number;
  pontos: number;
  total_brinde: string;
  voltagem: string | null;
  img_brinde: string;
  troca_voltagem: string;
}
interface CarrinhoContextData {
  carrinho: ItemCarrinho[];
  setCarrinho: React.Dispatch<React.SetStateAction<ItemCarrinho[]>>;
  fetchCarrinho: () => Promise<void>; // Adicione esta linha
  pedido: String | null;
  loaderCarrinho: boolean;
  setLoaderCarrinho: (value: boolean) => void;
  mostrarCarrinho: boolean;
  setMostrarCarrinho: (value: boolean) => void;
  pontos: string;
  setPontos: (value: string) => void;

}
// Criação do Context
export const CarrinhoContext = createContext<CarrinhoContextData>({} as CarrinhoContextData);

interface CarrinhoProviderProps {
  children: ReactNode;
}

export const CarrinhoProvider = ({ children }: CarrinhoProviderProps) => {
  const [carrinho, setCarrinho] = useState<ItemCarrinho[]>([]);
  const [pedido, setPedido] = useState<String | null>('');
  const [loaderCarrinho, setLoaderCarrinho] = useState(false);
  const [mostrarCarrinho, setMostrarCarrinho] = useState(false);
  const [pontos, setPontos] = useState('');

  const fetchCarrinho = async () => {
    try {
      // Pegando o token e o cnpj do localStorage
      const token = localStorage.getItem('token');
      const cnpj = localStorage.getItem('cliente_cnpj');

      // Verificando se o token e o cnpj existem
      if (!token || !cnpj) {
        throw new Error('Token ou CNPJ não encontrados');
      }

      const response = await axios.post(`https://data-api.agross.com.br/api/v1/lista_carrinho/${cnpj}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (Array.isArray(response.data.carrinho) && response.data.carrinho.length === 0) {


        const getNextPedido = async () => {
          try {
            const response = await axios.get(`https://data-api.agross.com.br/api/v1/pedidonext`, {
              headers: {
                Authorization: `Bearer ${token}`,

              },
            });
            console.log(response.data.pedidonext[0].pedidonext);
            setPedido(response.data.pedidonext[0].pedidonext);
          } catch (error) {
            console.error(error);
          }
        }
        getNextPedido();

      }
      else {
        setPedido(response.data.carrinho[0].id_solicitacao);

      }

      setCarrinho(response.data.carrinho);
      if (response.data.carrinho[0] !== (null || undefined)) {
        if (response.data.carrinho[0].codbrinde !== null) {
          setMostrarCarrinho(true);
        } else {
          setMostrarCarrinho(false);
        }
      }
      setLoaderCarrinho(false);
    } catch (error) {
      console.error('Erro ao buscar os dados do carrinho', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchCarrinho();
  }, []);

  return (
    <CarrinhoContext.Provider value={{ carrinho, setCarrinho, fetchCarrinho, pedido, loaderCarrinho, setLoaderCarrinho, mostrarCarrinho, setMostrarCarrinho, pontos, setPontos }}>
      {children}
    </CarrinhoContext.Provider>
  );
};

export const useCarrinho = () => useContext(CarrinhoContext);