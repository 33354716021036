import { PortfolioComponent } from "app/shared/components"
import { PortfolioComponente } from "app/shared/components/portfolio2/PortfolioComponente"




export const Portfolio = () => {

    return (
        <div>

            <PortfolioComponent />
            {/* <PortfolioComponente /> */}



        </div>



    )
}
