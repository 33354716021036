import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import { lightGreen } from "@mui/material/colors";
import TokenContext from 'app/shared/services/token/TokenContext';
import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import { TitleComponent } from "app/shared/components";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import useIsMobile from "app/shared/hooks/useIsMobile";
import Hotjar from '@hotjar/browser'

const primary = lightGreen[800];

const siteId = 5018745;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

interface Recomendacoes {
    codprod: string,
    produto: string,
    embalagem: string,
    codfamilia: string,
    familia: string,
    codlinha: string,
    linha: string,
    id_cultura: string,
    cultura: string,
    img_cultura: string,
    id_doenca: string,
    doenca: string,
    doenca_descricao: string,
    controle: string | null,
    sintomas: string | null,
    img_doenca: string | null,
    dosagem: string | null,
    carencia: string | null,
    volume: string | null
}

type Culturas = {
    id: number;
    cultura: string;
};

const culturaData: Culturas[] = [
    { id: 36, cultura: "ABACATE" },
    { id: 66, cultura: "ABOBORA" },
    { id: 20, cultura: "ALFACE" },
    { id: 67, cultura: "AMENDOIM" },
    { id: 1, cultura: "ARROZ" },
    { id: 73, cultura: "ARROZ IRRIGADO" },
    { id: 24, cultura: "BANANA" },
    { id: 3, cultura: "BATATA" },
    { id: 23, cultura: "BETERRABA" },
    { id: 5, cultura: "CAFE" },
    { id: 8, cultura: "CANA DE AÇÚCAR" },
    { id: 9, cultura: "CEBOLA" },
    { id: 21, cultura: "CENOURA" },
    { id: 11, cultura: "CITROS" },
    { id: 7, cultura: "FEIJÃO" },
    { id: 46, cultura: "FIGO" },
    { id: 16, cultura: "GOIABA" },
    { id: 22, cultura: "GRAMADOS" },
    { id: 28, cultura: "MAÇÃ" },
    { id: 17, cultura: "MAMÃO" },
    { id: 29, cultura: "MANGA" },
    { id: 30, cultura: "MELANCIA" },
    { id: 31, cultura: "MELAO" },
    { id: 2, cultura: "MILHO" },
    { id: 4, cultura: "MORANGO" },
    { id: 10, cultura: "PASTAGEM" },
    { id: 32, cultura: "PEPINO" },
    { id: 35, cultura: "PIMENTÃO" },
    { id: 59, cultura: "SERINGUEIRA" },
    { id: 54, cultura: "SOJA" },
    { id: 6, cultura: "TOMATE" },
    { id: 50, cultura: "TRIGO" },
    { id: 19, cultura: "UVA" }
];


export const RecomendacoesTecnicas = () => {

    const token = useContext(TokenContext);
    const [Recomendacoes, setRecomendacoes] = useState<Recomendacoes[] | null>(null)
    const [FilteredRecomendacoes, setFilteredRecomendacoes] = useState<Recomendacoes[] | null>(null)
    const [FilteredDoencas, setFilteredDoencas] = useState<Recomendacoes[] | null>(null)
    const [loaderRecomendacoes, setLoaderRecomendacoes] = useState(false);
    const [filterCultura, setFilterCultura] = useState(false);
    const [filterDoenca, setFilterDoenca] = useState(false);
    const Mobile = useIsMobile();

    useEffect(() => {

        const getDadosCliente = async () => {
            setLoaderRecomendacoes(true);
            try {
                const response = await axios.get(`https://data-api.agross.com.br/api/v1/recomendacoes`, {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });

                console.log(response.data.recomendacoes);

                setRecomendacoes(response.data.recomendacoes);

                setLoaderRecomendacoes(false);
            } catch (error) {
                console.error(error);
                setLoaderRecomendacoes(false);
            }
        }
        getDadosCliente();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filtrarcultura = async (culturas: string) => {
        await setFilteredRecomendacoes(Recomendacoes!.filter(cultura => cultura.cultura === culturas));
        setFilterCultura(true);
        window.scrollTo(0, 0);

    }
    const filtrardoenca = async (doencas: string) => {
        setFilteredDoencas(FilteredRecomendacoes!.filter(doenca => doenca.id_doenca === doencas));
        setFilterDoenca(true)
        window.scrollTo(0, 0);
    }

    const handleClick = async (doencas: string) => {
        const query = doencas.split(' ')[0];
        const url = `https://www.agross.com.br/portfolio?query=${query}`;
        window.location.href = url;
    };

    return (
        <div className=" d-flex">
            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%', position: 'relative' }}>
                <div className={`loaderRecomendacoes ${loaderRecomendacoes ? 'd-flex' : 'd-none'}`}>
                    {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                    <img src={require('assets/loader.gif')} alt="loader" style={{ width: '13%', marginTop: '6rem' }} />
                </div>

                <TitleComponent texto={'Recomendações Técnicas'} variant={Mobile ? "h4" : "h3"} />

                {filterCultura ?

                    <div className=" position-relative ">
                        {filterDoenca ?
                            <div>
                                <div className=" d-flex">
                                    <Button variant="outlined" size="small" startIcon={<KeyboardBackspaceOutlinedIcon />} style={{ color: primary }} onClick={() => {
                                        setFilterDoenca(false);
                                    }}>
                                        Voltar
                                    </Button>
                                    <Button variant="outlined" size="small" startIcon={<HomeOutlinedIcon />} style={{ color: primary, marginLeft: '1rem' }} onClick={() => {
                                        setFilterDoenca(false);
                                        setFilterCultura(false);
                                    }}>
                                        Início
                                    </Button>
                                </div>
                                <div style={{ height: Mobile ? 'auto' : '15rem', width: '100%', overflow: 'hidden', position: 'relative' }} className={`d-flex align-items-center my-2 justify-content-between ${Mobile ? 'flex-column' : ''}`}>
                                    <div className={`${Mobile ? 'w-100' : 'w-50'}`}>
                                        <img src={require(`assets/culturas/${FilteredRecomendacoes![0].id_cultura}.jpg`)} alt="" className=" w-100" />
                                    </div>
                                    <div className={`d-flex ${Mobile ? 'w-100' : 'w-50'}`}>
                                        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <TitleComponent texto={`${FilteredDoencas![0].doenca}`} exibirImagem={false} />
                                        </div>
                                    </div>
                                </div>
                                <Grid container spacing={1} style={{ marginLeft: 'auto', marginRight: 'auto' }} className=" d-flex justify-content-around">
                                    {FilteredDoencas!.map((doença) => (
                                        <Card sx={{ width: Mobile ? '90%' : '49%' }} className="my-4">
                                            <CardActionArea>
                                                <CardContent onClick={() => handleClick(doença.produto)}>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                        style={{ textAlign: 'center', color: primary }}
                                                    >
                                                        {doença.produto}
                                                    </Typography>
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{ textAlign: 'center', color: primary }}
                                                    >
                                                        {doença.familia}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                </Grid>
                            </div>
                            :
                            <div>

                                <Button variant="outlined" size="small" startIcon={<KeyboardBackspaceOutlinedIcon />} style={{ color: primary }} onClick={() => {
                                    setFilterCultura(false);
                                }}>
                                    Voltar
                                </Button>
                                <div style={{ height: Mobile ? 'auto' : '15rem', width: '100%', overflow: 'hidden', position: 'relative' }} className={`d-flex align-items-center my-2 justify-content-between ${Mobile ? 'flex-column' : ''}`}>
                                    <div className={`${Mobile ? 'w-100' : 'w-50'}`}>
                                        <img src={require(`assets/culturas/${FilteredRecomendacoes![0].id_cultura}.jpg`)} alt="" className=" w-100 " />
                                    </div>
                                    <div className={`d-flex ${Mobile ? 'w-100' : 'w-50'}`}>
                                        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <TitleComponent texto={`${FilteredRecomendacoes![0].cultura}`} exibirImagem={false} />
                                        </div>
                                    </div>

                                </div>
                                <TitleComponent texto={`Doenças`} exibirImagem={false} />
                                <Grid container spacing={1} style={{ marginLeft: 'auto', marginRight: 'auto' }} className=" d-flex justify-content-around">
                                    {FilteredRecomendacoes!.map((doença) => (
                                        <Card sx={{ width: '356px' }} className="my-4" onClick={() => filtrardoenca(doença.id_doenca)}>
                                            <CardActionArea>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'center', color: primary }}>
                                                        {doença.doenca}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                </Grid>
                            </div>
                        }

                    </div>
                    :
                    <Grid container spacing={1} style={{ marginLeft: 'auto', marginRight: 'auto' }} className=" d-flex justify-content-around">
                        {culturaData.map((cultura) => (
                            <Card sx={{ width: Mobile ? '80%' : '30%' }} className="my-4" onClick={() => filtrarcultura(cultura.cultura)}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height={Mobile ? "120rem" : "190rem"}
                                        image={require(`assets/culturas/${cultura.id}.jpg`)}
                                        alt={cultura.cultura}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'center', color: primary }}>
                                            {cultura.cultura}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))}

                    </Grid>
                }

            </div>
        </div>
    )
}