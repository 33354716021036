import './desafio.css'
import { TitleComponent } from 'app/shared/components'
import useIsMobile from 'app/shared/hooks/useIsMobile';
import { Typography } from '@mui/material'
import { lightGreen } from '@mui/material/colors';


const verdeescuro = lightGreen[900];

export const Desafio = () => {
    const Mobile = useIsMobile();
    return (
        <div className={`divraspadinha d-flex flex-column pt-5 `} style={{ paddingBottom: Mobile ? '12rem' : '' }}>
            <img src={require('assets/raspadinha/DesafioPremium.png')} alt="" style={{ width: Mobile ? '80%' : '40%' }} className=' mx-auto my-5' />

            <TitleComponent texto='' />
            <Typography fontFamily={'Arima Madurai'} variant={Mobile ? 'h6' : 'h5'} style={{ textAlign: 'center', marginTop: '0%' }} color={'white'}>
                Regulamento
            </Typography>
            <Typography style={{ width: Mobile ? '90%' : '70%', textAlign: 'justify' }} className=' mx-auto my-4' color={'white'}>
                <b>.</b> Este regulamento versa sobre as regras da promoção e tem por finalidade proteger e favorecer os participantes, bem como organizar e sequenciar a promoção, estas não podem ser mudadas ou substituídas, a não ser pelos coordenadores e administradores do Grupo AgRoss.<br></br>
                <b>[CLÁUSULA PRIMEIRA: OBJETIVOS DA CAMPANHA]</b> <br></br>
                <b>1.</b> A Campanha AgRoss Premium Desafio tem como objetivo oferecer benefícios únicos e fortalecer ainda mais a parceria com os clientes selecionados. Consiste na concessão de um rebate, no valor de até 2% (dois por cento) dos valores de compras realizadas dentro do período da campanha.
                <br></br>
                <b>[CLÁUSULA SEGUNDA: PERÍODO DA CAMPANHA]</b> <br></br>
                <b>2.</b> O período desta campanha será de 01/06/2024 a 31/08/2024.<br></br>
                <b>2.1</b> A critério exclusivo do Grupo AgRoss, o período desta campanha poderá ser estendido. No caso de extensão do período, poderá a AgRoss determinar a inclusão de novos participantes e/ou novas metas, sendo assegurado a meta inicial do cliente até o fim do período inicial, podendo ainda, acrescentar nova meta para período posterior e/ou manter a meta inicial do cliente estendendo apenas o período da campanha para cumprimento do desafio.<br></br>
                <b>[CLÁUSULA TERCEIRA: DA PARTICIPAÇÃO]</b>  <br></br>
                <b>Os clientes elegíveis para participar da Campanha serão escolhidos por critérios exclusivos do Grupo AgRoss. O cliente elegível receberá uma carta-convite para participação, que aceitando participar deverá durante a campanha cumprir com os seguintes requisitos:</b><br></br>
                <b>3.1</b> Permanecer adimplente com o Grupo AgRoss;<br></br>
                <b>3.2</b> Cumprir com mais de 90% da meta estabelecida;<br></br>
                <b>3.3</b> Para cada cliente convidado é estabelecida uma meta pessoal, intransferível e não cumulativa. A referida individualidade é referente ao número do CNPJ, não sendo aplicável a meta e premiação a empresas do mesmo grupo empresarial.<br></br>
                <b>3.4</b> Não serão aceitas devoluções dos produtos participantes adquiridos no período da campanha.<br></br>
                <b>3.5</b> Para fins desta campanha, cada CNPJ é considerado de forma individual, mesmo tratando-se de empresas do mesmo grupo empresarial.<br></br>
                <b>3.6</b> Para efetivar sua participação, é necessário assinar a carta-convite enviada com o certificado digital da empresa (e-CNPJ) e enviá-la ao vendedor AgRoss da sua região.<br></br>
                <b>[CLÁUSULA QUARTA: DOS PRODUTOS PARTICIPANTES]</b><br></br>
                <b>4.</b> Todos os produtos do portfólio AgRoss são elegíveis para participarem da promoção, com exceção da linha de Sementes de todas as marcas. <br></br>
                <b>[CLÁUSULA QUINTA: DA PREMIAÇÃO]</b> <br></br>
                <b>Será concedido um rebate, no valor de até 2% (dois por cento) dos valores de compras realizadas dentro do período da campanha para os clientes que cumprirem suas metas, conforme abaixo:</b> <br></br>
                <b>5.1</b> 1% (um por cento) de rebate dos produtos comprados durante o período de campanha ao cliente que atingir a meta com o percentual de 90% (noventa por cento) até 99,9% (noventa e nove virgula nove por cento);<br></br>
                <b>5.2</b> 2% (dois por cento) de rebate dos produtos comprados durante o período de campanha ao cliente que atingir a meta com o percentual de 100 % (cem por cento) ou mais;<br></br>
                <b>5.3</b> Em caso de os valores faturados excederem a meta, o percentual de 2% de rebate, será limitado ao máximo de 115% (cento e quinze por cento) da meta;<br></br>
                <b>[CLÁUSULA SEXTA: DO PRAZO DE APURAÇÃO ]</b> <br></br>
                <b>6.</b> A apuração dos resultados será realizada no mês subsequente ao fim da campanha. Os clientes que cumprirem todos os requisitos aqui estabelecidos receberão sua premiação em forma de cheque nominal ou depósito em conta.<br></br>
                <b>6.1</b> Se no momento da apuração o cliente encontra-se inadimplente, este perderá o direito a premiação.<br></br>
                <b>[CLÁUSULA SÉTIMA: DA FORMA DE PAGAMENTO DA PREMIAÇÃO]</b> <br></br>
                <b>7.</b> O pagamento será realizado preferencialmente via depósito/transferência bancária, ou ainda, via cheque nominal a pessoa física ou jurídica as que tenha sido indicada na carta-convite.<br></br>
                <b>7.1</b> Em caso de omissão de dados no envio de resposta à carta-convite, caso tenha direito a premiação, os valores serão enviados a uma conta de titularidade do CNPJ comprador, não havendo possibilidade de alteração ou reclamação após o envio.<br></br>
                <b>[CLÁUSULA OITAVA: DO PRAZO DE PAGAMENTO]</b> <br></br>
                <b>8.</b> Realizada a apuração, o cliente será informado que cumpriu com os requisitos, mas o pagamento da premiação será realizado apenas quando houver a quitação de todos os títulos emitidos durante a campanha. O pagamento da premiação será realizado em até 30 (trinta) dias da quitação destes.<br></br>
                <b>[CLÁUSULA NONA: DAS DISPOSIÇÕES GERAIS]</b> <br></br>
                <b>9.</b> Esta campanha independe de qualquer modalidade aleatória (sorte), não estando, portanto, sujeita à autorização prévia estabelecida no Art. 1º da Lei n° 5.768/71.<br></br>
                <b>9.1</b> Ao participar dessa campanha, o cliente AgRoss obriga-se a observar e cumprir rigorosamente todas as leis cabíveis, incluindo, mas não se limitando à legislação brasileira anticorrupção, a legislação brasileira contra a lavagem de dinheiro, assim como as normas e exigências constantes das políticas internas do Grupo AgRoss.<br></br>
                <b>9.2</b> Qualquer eventualidade ou fato omisso que não se enquadre nesse regulamento, ficará sobre a apreciação e julgamento exclusivo do Grupo AgRoss, devendo ser acatada a decisão em caráter inquestionável por todos os participantes.<br></br>
                <b>[CLÁUSULA DÉCIMA: DO FORO]</b> <br></br>
                <b>10.</b> Para dirimir eventuais conflitos judiciais referentes ao regulamento da presente campanha, estabelecem eleito o Foro da Comarca de Campinas, Estado de São Paulo.<br></br>
            </Typography>
        </div >
    )
}