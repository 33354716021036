import { Typography } from '@mui/material';
import { lightGreen } from '@mui/material/colors';

const verdeescuro = lightGreen[900];

interface TypographyProps {
    texto: string;
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline";
    exibirImagem?: boolean;
}

export const TitleComponent: React.FC<TypographyProps> = ({ texto, variant = "h3", exibirImagem = true }) => (
    <div className=" d-flex flex-column mt-5">
        <Typography fontFamily={'Arima Madurai'} variant={variant} style={{ color: verdeescuro, textAlign: 'center' }} className='mt-4'>
            {texto}
        </Typography>
        {exibirImagem && <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />}
    </div>
);
