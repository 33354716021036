import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import { useCookies } from 'react-cookie';
import { lightGreen } from '@mui/material/colors';

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];

const style = {
  position: 'absolute',
  top: '7%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '0px 0px 7px 7px'
};
const styleMobile = {
  position: 'absolute',
  top: '13%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '0px 0px 7px 7px'
};

const AgRossMessage = () => {
  const [cookies, setCookie] = useCookies(['preferences']);
  const [open, setOpen] = useState(false);
  const Mobile = useIsMobile();

  useEffect(() => {
    if (!cookies['preferences']) {
      setOpen(true);
    }
  }, [cookies]);

  const handleClose = () => {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 3); // Define a expiração para 3 meses
    setCookie('preferences', 'true', { path: '/', expires });
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="agross-modal-title"
      aria-describedby="agross-modal-description"
    >
      <Box sx={Mobile ? styleMobile : style} color={verdeescuro}>
        <Typography id="agross-modal-description" style={{ textAlign: 'justify' }}>
          Você está no site AgRoss, o conteúdo deste site é destinado a agricultores e demais profissionais do setor agrícola.
        </Typography>
        <Button variant="contained" style={{ backgroundColor: primary, marginTop: '20px' }} onClick={handleClose} className={`${Mobile ? 'w-75' : ''}`}>
          OK
        </Button>
      </Box>
    </Modal>
  );
};

export default AgRossMessage;
