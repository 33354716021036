// contexts/ShowDivContext.js

import { createContext } from 'react';

export const ShowDivContext = createContext({
  showDiv: false,
  setShowDiv: (value: boolean) => { },
});


