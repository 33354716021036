import React, { useState, useEffect } from "react";
import "./style.css";
import './cronometro.css'

const COUNTDOWN_TARGET = new Date('Thu Nov 09 2023 15:45:00').getTime(); // Corrigi a data para o último dia de Junho

const getTimeLeft = () => {
  const now = new Date().getTime(); // Obter o tempo atual em milissegundos
  const totalTimeLeft = COUNTDOWN_TARGET - now; // Subtração de números
  const Dias = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
  const Horas = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
  const Minutos = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
  const Segundos = Math.floor((totalTimeLeft / 1000) % 60);
  return { Dias, Horas, Minutos, Segundos };
};

export const Cronometro = () => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft);
  const [alterar, setAlterar] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
      setAlterar(true);
      setTimeout(() => {
        setAlterar(false);
    }, 800);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>

      <div className='countdown'>

        <div className='content'>
          {Object.entries(timeLeft).map(([label, value]) => (
            <div className='box' key={label}>
              <div className='value'>
                <span>{value}</span>
              </div>
              <span className='label'>{label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={`flip seconds-last ${alterar ? 'play' : ''}`}>
        <input type="hidden" />
        <div className="flip-display">
          <div className="flip-display-top">{timeLeft.Segundos}</div>
          <div className="flip-display-bottom">{timeLeft.Segundos}</div>
        </div>
        <div className="flipper">
          <div className="flipper-top">{timeLeft.Segundos}</div>
          <div className="flipper-bottom">{timeLeft.Segundos}</div>
        </div>
      </div>
    </div>
  );
};
