import fetchToken from './fetchToken';


async function getTokenAndSave() {
  const token = await fetchToken();
  
  // Salvando o token no localStorage
  localStorage.setItem('token', token);

  // Retornando o token
  return token;
}

export default getTokenAndSave;



