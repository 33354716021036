



export const HoraDoRango = () => {

    return (
        <div>
            
            <h1>Hora do Rango</h1>
            
            
        </div>



    )
}