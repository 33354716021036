import AuthService from 'app/shared/services/AuthService';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import { lightGreen } from '@mui/material/colors';
import { useState, useContext, useEffect } from "react";
import Button from '@mui/material/Button';
import * as React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import Swal from 'sweetalert2';
import axios from 'axios';
import TokenContext from 'app/shared/services/token/TokenContext';
import { ShowDivContext } from 'app/shared/contexts/ShowDivContext/ShowDivContext';
import { Select, MenuItem as MuiMenuItem, SelectChangeEvent } from '@mui/material';

const primary = lightGreen[800];
const bege = '#F7F0EA';

interface Menuheader2Props {
    onMenuClick?: (menuItem: string) => void;
    authService: AuthService;
    onClose: () => void;
    showSeletorProp: boolean;
    showCadastrar: boolean; // Adicionando a prop showCadastrar
}

export const MenuRevenda: React.FC<Menuheader2Props> = (props) => {
    const Mobile = useIsMobile();
    const token = React.useContext(TokenContext);
    const { showDiv, setShowDiv } = useContext(ShowDivContext);

    const [cnpj, setCnpj] = useState('');
    const [errorCNPJ, setErrorCNPJ] = useState(false);
    const [nome, setNome] = useState('');
    const [errorNome, setErrorNome] = useState(false);
    const [cpf, setCpf] = useState('');
    const [errorCPF, setErrorCPF] = useState(false);
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [CNPJcadastro, setCNPJCadastro] = useState('');
    const [NomeRespCadastro, setNomeRespCadastro] = useState('');
    const [CPFRespCadastro, setCPFRespCadastro] = useState('');
    const [EmailRespCadastro, setEmailRespCadastro] = useState('');
    const [estado, setEstado] = useState<string>('');
    const [estadoId, setEstadoId] = useState<number | null>(null);
    const [estados, setEstados] = useState<{ id: number; nome: string; }[]>([]);
    const [cidade, setCidade] = useState<string>('');
    const [municipios, setMunicipios] = useState<string[]>([]);
    const [isMunicipiosDisabled, setIsMunicipiosDisabled] = useState<boolean>(true);
    const [errorEstado, setErrorEstado] = useState<boolean>(false);
    const [errorCidade, setErrorCidade] = useState<boolean>(false);

    useEffect(() => {
        const fetchEstados = async () => {
            try {
                const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
                setEstados(response.data.map((estado: any) => ({ id: estado.id, nome: estado.nome })));
            } catch (error) {
                console.error('Erro ao buscar os estados:', error);
            }
        };

        fetchEstados();
    }, []);

    const handleCadastroCNPJ = (event: any) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        const value = event.target.value;
        setCnpj(value);
        setErrorCNPJ(value.length > 0 && value.length < 14);
        setCNPJCadastro(onlyNums);
    };

    const handleBlurCPF = () => {
        if (cpf.length === 0 || cpf.length < 11) {
            setErrorCPF(true);
        }
    };

    const handleBlur = () => {
        if (nome.length === 0 || nome.length <= 3) {
            setErrorNome(true);
        }
    };

    const handleCadastroNome = (event: any) => {
        const value = event.target.value;
        setNome(value);
        setNomeRespCadastro(event.target.value);
        if (value.length > 3) {
            setErrorNome(false);
        }
    };

    const handleCadastroCPF = (event: any) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        const value = event.target.value;
        setCpf(value);
        if (value.length === 11) {
            setErrorCPF(false);
        }
        setCPFRespCadastro(onlyNums);
    };

    const handleCadastroEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value);
        setEmailRespCadastro(value);
        if (value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setErrorEmail(false);
        }
    };

    const handleChangeEstado = async (event: SelectChangeEvent<string>) => {
        const estadoSelecionado = event.target.value;
        const estadoObj = estados.find(e => e.nome === estadoSelecionado);
        if (!estadoObj) return;

        setEstado(estadoSelecionado);
        setEstadoId(estadoObj.id);
        setErrorEstado(false);

        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoObj.id}/municipios`);
            const municipiosNomes = response.data.map((municipio: any) => municipio.nome);
            setMunicipios(municipiosNomes);
            setIsMunicipiosDisabled(false);
        } catch (error) {
            console.error('Erro ao buscar os municípios:', error);
            setMunicipios([]);
            setIsMunicipiosDisabled(true);
        }
    };

    const handleChangeCidade = (event: SelectChangeEvent<string>) => {
        setCidade(event.target.value);
        setErrorCidade(false);
    };

    interface ICadastro {
        [key: string]: string;
    }

    const handleCadastrar = () => {
        if (!estado) {
            setErrorEstado(true);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Por favor preencha corretamente o estado.',
            });
            return;
        }
        if (!cidade) {
            setErrorCidade(true);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Por favor preencha corretamente a cidade.',
            });
            return;
        }

        const cadastrarcompleto: ICadastro = {
            cnpj: CNPJcadastro,
            nome: NomeRespCadastro,
            cpf: CPFRespCadastro,
            email: EmailRespCadastro,
        };

        const campoMensagem: ICadastro = {
            cnpj: 'Favor Preencher o campo CNPJ da empresa.',
            nome: 'Favor Preencher o campo Nome do responsável.',
            cpf: 'Favor Preencher o campo CPF do responsável.',
            email: 'Favor Preencher o campo email.',
        };


        for (const campo in cadastrarcompleto) {
            if (cadastrarcompleto[campo] === '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: campoMensagem[campo],
                });
                return;
            }
        }

        if (cnpj === "12345678912345") {
            Swal.fire({
                title: 'Erro! Esse CNPJ já está cadastrado no sistema AgRoss',
                text: '',
                icon: 'warning',
            });
        } else if (cnpj === "98765432198765") {
            Swal.fire({
                title: 'Seu pré-cadastro foi enviado com sucesso! Entraremos em contato via email com mais informações para finalizar o processo.',
                text: '',
                icon: 'success',
            });
        }
    };

    const handleBlurEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setErrorEmail(true);
        } else {
            setErrorEmail(false);
        }
    };

    return (
        <div style={{ overflowX: 'clip', display: props.showCadastrar ? 'block' : 'none' }}>
            <AppBar position="relative" >
                <Toolbar style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', paddingRight: 0, paddingLeft: 0 }}>
                    {Mobile ?
                        <div className="divcadastrargeralmobile">
                            <div className='divcadastrarmobile'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={props.onClose}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Faça seu cadastro!
                                </Typography>

                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="cnpjempresa" style={{ color: primary }}>
                                        CNPJ da empresa* (somente números)
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroCNPJ}
                                        id="cnpjempresa"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="nomeresp" style={{ color: primary }}>
                                        Nome do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroNome}
                                        id="nomeresp"
                                        type="text"
                                        inputProps={{
                                            pattern: '[A-Za-z]*'
                                        }}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="cpfresp" style={{ color: primary }}>
                                        CPF do Responsável* (Somente números)
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroCPF}
                                        id="cpfresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="emailresp" style={{ color: primary }}>
                                        E-mail do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroEmail}
                                        id="emailresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    onClick={handleCadastrar}
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<PersonIcon />}
                                >
                                    Cadastrar
                                </Button>
                            </div>
                        </div>
                        :
                        <div className="divcadastrargeral">
                            <div className='divcadastrar'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={props.onClose}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Faça seu cadastro!
                                </Typography>

                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="cnpjempresa" style={{ color: errorCNPJ ? 'red' : primary }}>
                                        {errorCNPJ ? 'Digite um CNPJ Válido' : 'CNPJ da empresa* (somente números)'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroCNPJ}
                                        id="cnpjempresa"
                                        type="text"
                                        value={cnpj}
                                        inputProps={{
                                            maxLength: 14,
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl variant="standard" className="my-3" fullWidth>
                                    <InputLabel id="select-estado-label" style={{ color: primary }}>Selecione um estado</InputLabel>
                                    <Select
                                        labelId="select-estado-label"
                                        id="select-estado"
                                        value={estado}
                                        onChange={handleChangeEstado}
                                        label="Selecione um estado"
                                        className="custom-select"
                                    >
                                        {estados.map((estado) => (
                                            <MuiMenuItem key={estado.id} value={estado.nome}>
                                                {estado.nome}
                                            </MuiMenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" className="my-3" fullWidth>
                                    <InputLabel id="select-cidade-label" style={{ color: errorCidade ? primary : primary, zIndex: 999 }}>
                                        {errorCidade ? 'Por favor preencha corretamente a cidade' : 'Selecione uma cidade'}
                                    </InputLabel>
                                    <Select
                                        labelId="select-cidade-label"
                                        id="select-cidade"
                                        value={cidade}
                                        onChange={handleChangeCidade}
                                        label="Selecione uma cidade"
                                        className="custom-select"
                                        disabled={isMunicipiosDisabled}
                                        style={{ backgroundColor: isMunicipiosDisabled ? '#eaeaea' : '' }}
                                    >
                                        {municipios.map((municipio, index) => (
                                            <MuiMenuItem key={index} value={municipio}>
                                                {municipio}
                                            </MuiMenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="nomeresp" style={{ color: errorNome ? 'red' : primary }}>
                                        {errorNome ? 'Digite um Nome Válido' : 'Nome do Responsável*'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroNome}
                                        onBlur={handleBlur}
                                        id="nomeresp"
                                        type="text"
                                        value={nome}
                                        inputProps={{
                                            pattern: '[A-Za-z]*'
                                        }}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="cpfresp" style={{ color: errorCPF ? 'red' : primary }}>
                                        {errorCPF ? 'Digite um CPF Válido' : 'CPF do Responsável* (Somente números)'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: errorCPF ? 'red' : primary }}
                                        onChange={handleCadastroCPF}
                                        onBlur={handleBlurCPF}
                                        id="cpfresp"
                                        type="text"
                                        value={cpf}
                                        inputProps={{
                                            maxLength: 11,
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className='my-3'>
                                    <InputLabel htmlFor="emailresp" style={{ color: errorEmail ? 'red' : primary }}>
                                        {errorEmail ? 'Por favor, insira um e-mail válido.' : 'E-mail do Responsável*'}
                                    </InputLabel>
                                    <Input
                                        style={{ color: errorEmail ? 'red' : primary }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCadastroEmail(e);
                                        }}
                                        onBlur={handleBlurEmail}
                                        id="emailresp"
                                        type="email"
                                        value={email}
                                        inputProps={{
                                            pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    onClick={handleCadastrar}
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<PersonIcon />}
                                >
                                    Cadastrar
                                </Button>
                            </div>
                        </div>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
};
