import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { App } from './app/App';
import AuthProvider from 'app/shared/contexts/auth/AuthProvider';
import { initHotjar } from './hotjar';
import { initGA } from 'analytics';

const TRACKING_ID = 'G-5Q8WTVTRMM';

initHotjar();
initGA(TRACKING_ID);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();