import { Link, Button, Modal, TextField, Typography } from "@mui/material"
import { lightGreen } from "@mui/material/colors";
import { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import './Menufooter.css'
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { useMenuContext } from "app/shared/contexts";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import useIsMobile from "app/shared/hooks/useIsMobile";
import { AsYouType } from 'libphonenumber-js';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';
const MenuContainer = styled.div`
  
  align-items: center;
  height: 4rem;
`;

const MenuItem = styled.div`
  margin-right: 20px;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  position: relative;
  
  font-family: 'Tiresias Infofont', sans-serif !important;

  &:hover {
    .line {
      width: 2rem;
    }
  }
`;

const Line = styled.div<{ isActive: boolean }>`
  width: 0;
  height: 2px;
  background-color: ${primary};
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.3s ease-in-out;

  ${({ isActive }) =>
        isActive &&
        css`
      width: 100%;
      @media (max-width: 768px) {
        width: 2rem;
      }
    `
    }
`;
export const Menufooter = () => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('');
    const { selectedItem, setSelectedItem } = useMenuContext();
    const { hiddenMenutgVisible, setHiddenMenutgVisible, setScrollToParticipar, hiddenMenuVisible,
        setHiddenMenuVisible, setScrollToPerguntastv, setOpen, setOpentg, setScrollToVideostv, setScrollToPerguntastg } = useMenuContext();
    const Mobile = useIsMobile();
    const [OpenNovidades, setOpenNovidades] = useState(false);

    const handleItemClick = useCallback(
        (item: string) => {
            setActiveItem(item);
            setSelectedItem(item);
            const animationDuration = 700;

            if (item === 'TvAgRoss' || item === 'aovivo' || item === 'comoparticipar' || item === 'videos' || item === 'perguntasfrequentes' || item === 'regras' || item === 'horadorango') {
                if (!hiddenMenutgVisible) {
                    setHiddenMenuVisible(true);
                } else {
                    setHiddenMenutgVisible(false);
                    setTimeout(() => {
                        setHiddenMenuVisible(true);
                    }, animationDuration);
                }
                if (item === 'TvAgRoss') {
                    navigate('/tvagross');
                } else if (item === 'aovivo') {
                    navigate('/assistaaovivo');
                } else if (item === 'comoparticipar') {
                    navigate('/comoparticipar');
                } else if (item === 'videos') {
                    navigate('/videos');
                } else if (item === 'perguntasfrequentes') {
                    navigate('/perguntasfrequentes');
                } else if (item === 'regras') {
                    navigate('/regras');
                } else if (item === 'horadorango') {
                    navigate('/horadorango');
                }
            } else if (item === 'Troque & Ganhe' || item === 'Regulamento' || item === 'perguntasfrequentestg' || item === 'brindes' || item === 'carrinho') {
                if (!hiddenMenuVisible) {
                    setHiddenMenutgVisible(true);
                } else {
                    setHiddenMenuVisible(false);
                    setTimeout(() => {
                        setHiddenMenutgVisible(true);
                    }, animationDuration);
                }
                if (item === 'Troque & Ganhe') {
                    navigate('/troqueeganhe');
                } else if (item === 'Regulamento') {
                    navigate('/regulamento');
                } else if (item === 'perguntasfrequentestg') {
                    navigate('/perguntasfrequentestg');
                } else if (item === 'brindes') {
                    navigate('/brindes');
                } else if (item === 'carrinho') {
                    navigate('/carrinho');
                }
            } else {
                setHiddenMenuVisible(false);
                setHiddenMenutgVisible(false);
            }
            if (item === 'Início') {
                navigate('/');
            } else if (item === 'Nossa História') {
                navigate('/nossahistoria');
            } else if (item === 'Portfólio') {
                navigate('/portfolio');
            } else if (item === 'Vantagens') {
                navigate('/vantagens');
            } else if (item === 'Notícias') {
                navigate('/noticias');
            } else if (item === 'Depoimentos') {
                navigate('/depoimentos');
            } else if (item === 'Contato') {
                navigate('/contato');
            }
            window.scrollTo(0, 0);
        },
        [hiddenMenutgVisible, hiddenMenuVisible, navigate, setHiddenMenuVisible, setHiddenMenutgVisible, setSelectedItem] // Adicione as dependências relevantes aqui
    );

    const handleParticiparClick = () => {
        if (window.location.pathname !== '/tvagross') {
            navigate('/tvagross');
            setTimeout(() => {
                setScrollToParticipar(true);
            }, 500);
        } else {
            setTimeout(() => {
                setScrollToParticipar(true);
            }, 1000);
        }
    };

    const handlePerguntastvClick = () => {
        if (window.location.pathname !== '/tvagross') {
            navigate('/tvagross');
            setTimeout(() => {
                setScrollToPerguntastv(true);
            }, 500);

        } else {
            setTimeout(() => {
                setScrollToPerguntastv(true);
            }, 1000);
        }
    };
    const handleVideostvClick = () => {
        if (window.location.pathname !== '/tvagross') {
            navigate('/tvagross');
            setTimeout(() => {
                setScrollToVideostv(true);
            }, 500);
        } else {
            setTimeout(() => {
                setScrollToVideostv(true);
            }, 1000);
        }
    };

    const handlePerguntastgClick = () => {
        if (window.location.pathname !== '/tvagross') {
            navigate('/tvagross');
            setTimeout(() => {
                setScrollToPerguntastg(true);
            }, 500);

        } else {
            setTimeout(() => {
                setScrollToPerguntastg(true);
            }, 1000);
        }
    };

    const handleOpen = () => {
        setOpen(true);

    };
    const handleOpentg = () => {
        setOpentg(true);

    };

    // const handleOpenNovidades = () => {
    //     setOpenNovidades(true);

    // };
    const handleCloseNovidades = () => {
        setOpenNovidades(false);

    };

    const [phoneNumber, setPhoneNumber] = useState('');

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formatter = new AsYouType('BR');
        const inputChars = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (inputChars.length <= 11) { // Check if input is within the length limit
            const formattedNumber = formatter.input(inputChars);
            setPhoneNumber(formattedNumber);
        }
    };
    return (
        <div className={`divfooter p-4  ${Mobile ? 'flex-column justify-content-between' : 'justify-content-around d-flex'}`} style={{ color: "white", height: Mobile ? '100rem' : '30rem', backgroundColor: Mobile ? '#404a3d' : '' }}>
            <div className={`${Mobile ? 'mx-auto d-flex flex-column' : 'mx-3'}`} style={{ width: Mobile ? '70%' : '20%', height: Mobile ? '17%' : '', }}>
                <div className="mx-auto">
                    <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                        Materiais AgRoss
                    </Typography>
                    <div className="line"></div>
                </div>
                <Typography style={{ textAlign: 'justify', fontSize: '0.8rem' }} className=" my-5">
                    Aqui você pode fazer o download de Materiais da AgRoss como o manual de identidade visual, logos, Revista do Troque & Ganhe, etc.
                </Typography>

                <Typography style={{ color: "#eddd5e !important" }}>
                    <a href="/materiais" >Clique Aqui</a>
                </Typography>

                <div
                    style={{ width: Mobile ? '300px' : '330px', height: Mobile ? '155px' : '200px', marginTop: '5%', borderRadius: '16px', marginLeft: '-20px' }}>
                    <Link href="https://www.vakinha.com.br/4712837" target={'_blank'}>
                        <img src={require('assets/footer/SOS_AgRoss.png')} alt="" style={{ height: '100%', width: '100%', borderRadius: '16px' }} />
                    </Link>
                </div>

            </div>

            <div className={`${Mobile ? 'mx-auto d-flex flex-column' : 'mx-3'}`} style={{ width: Mobile ? '70%' : '8%', height: Mobile ? '29%' : '', marginTop: '40%' }}>
                <div className="mx-auto">
                    <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                        AgRoss
                    </Typography>
                    <div className="line"></div>
                </div>
                <MenuContainer style={{ margin: Mobile ? '' : 'auto' }} className={`my-5 ${Mobile ? 'menumobileline' : ''}`} >
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Início')} className="my-2" >
                        Início
                        <CSSTransition in={activeItem === 'Início'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Início'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Nossa História')} className="my-2">
                        Nossa História
                        <CSSTransition in={activeItem === 'Nossa História'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Nossa História'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Portfólio')} className="my-2">
                        Portfólio
                        <CSSTransition in={activeItem === 'Portfólio'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Portfólio'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Vantagens')} className="my-2">
                        Vantagens
                        <CSSTransition in={activeItem === 'Vantagens'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Vantagens'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Notícias')} className="my-2">
                        Notícias
                        <CSSTransition in={activeItem === 'Notícias'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Notícias'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Depoimentos')} className="my-2">
                        Depoimentos
                        <CSSTransition in={activeItem === 'Depoimentos'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Depoimentos'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Contato')} className="my-2">
                        Contato
                        <CSSTransition in={activeItem === 'Contato'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Contato'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('TvAgRoss')} className="my-2">
                        TvAgRoss
                        <CSSTransition in={activeItem === 'TvAgRoss'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'TvAgRoss'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Troque & Ganhe')} className="my-2">
                        Troque & Ganhe
                        <CSSTransition in={activeItem === 'Troque & Ganhe'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Troque & Ganhe'} />
                        </CSSTransition>
                    </MenuItem>
                </MenuContainer>
            </div>


            <div className={`${Mobile ? 'mx-auto d-flex flex-column' : 'mx-3'}`} style={{ width: Mobile ? '70%' : '8%', height: Mobile ? '20%' : '' }}>
                <div className="mx-auto">
                    <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                        Tv AgRoss
                    </Typography>
                    <div className="line"></div>
                </div>
                <MenuContainer style={{ margin: Mobile ? '' : 'auto' }} className="my-5">
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => window.open('https://tvagross.com.br/inicio', '_blank')} className="my-2">
                        Assista Ao Vivo
                        <CSSTransition in={activeItem === 'aovivo'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'aovivo'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => {
                        handleParticiparClick();
                        handleItemClick('TvAgRoss');
                    }} className="my-2">

                        Como Participar
                        <CSSTransition in={activeItem === 'comoparticipar'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'comoparticipar'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => {
                        handleVideostvClick();
                        handleItemClick('TvAgRoss');
                    }} className="my-2">
                        Vídeos
                        <CSSTransition in={activeItem === 'videos'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'videos'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => {
                        handlePerguntastvClick();
                        handleItemClick('TvAgRoss');
                    }} className="my-2">
                        Perguntas Frequentes
                        <CSSTransition in={activeItem === 'perguntasfrequentes'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'perguntasfrequentes'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleOpen()} className="my-2">
                        Regras
                        <CSSTransition in={activeItem === 'regras'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'regras'} />
                        </CSSTransition>
                    </MenuItem>
                    {/* <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('horadorango')} className="my-2">
                        Hora do Rango
                        <CSSTransition in={activeItem === 'horadorango'} timeout={300} classNames="line">
                        <Line className="line" isActive={selectedItem === 'horadorango'} />
                        </CSSTransition>
                    </MenuItem> */}
                </MenuContainer>
            </div>

            <div className={`${Mobile ? 'mx-auto d-flex flex-column' : 'mx-3'}`} style={{ width: Mobile ? '70%' : '15%', height: Mobile ? '17%' : '' }}>
                <div className="mx-auto">
                    <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                        Troque e Ganhe
                    </Typography>
                    <div className="line"></div>
                </div>
                <MenuContainer style={{ margin: Mobile ? '' : 'auto' }} className="my-5">
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleOpentg()} className="my-2">
                        Regulamento
                        <CSSTransition in={activeItem === 'Regulamento'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Regulamento'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => {
                        handlePerguntastgClick();
                        handleItemClick('Troque & Ganhe');

                    }} className="my-2">
                        Perguntas Frequentes
                        <CSSTransition in={activeItem === 'perguntasfrequentestg'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'perguntasfrequentestg'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('brindes')} className="my-2">
                        Prêmios
                        <CSSTransition in={activeItem === 'brindes'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'brindes'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('carrinho')} className="my-2">
                        <LocalGroceryStoreIcon style={{ width: '1.2rem' }} /> Carrinho
                        <CSSTransition in={activeItem === 'carrinho'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'carrinho'} />
                        </CSSTransition>
                    </MenuItem>
                </MenuContainer>
            </div>

            <div className={`${Mobile ? 'mx-auto d-flex flex-column' : 'mx-3'}`} style={{ width: Mobile ? '70%' : '23%', height: Mobile ? '17%' : '', marginTop: Mobile ? '-10%' : '' }}>
                <div className="mx-auto">
                    <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                        Endereço
                    </Typography>
                    <div className="line"></div>
                </div>
                <Typography style={{ textAlign: 'justify', fontSize: '0.8rem' }} className=" mt-5">
                    R. Arnaldo Barreto, 795 – São&nbsp;Bernardo – Campinas/SP - CEP: 13030-420
                </Typography>

                <Typography style={{ color: "#eddd5e", fontSize: '0.9rem' }}>
                    contato@agross.com.br
                </Typography>
                <Typography style={{ color: "#eddd5e", fontSize: '0.9rem' }}>
                    0800 055 4533
                </Typography>
                {/* <Button variant="contained" className=" mt-3" onClick={handleOpenNovidades}>Receba nossas novidades!</Button> */}
            </div>

            <Modal
                open={OpenNovidades}
                onClose={handleCloseNovidades}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={{ overflow: Mobile ? 'overlay' : 'overlay', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: Mobile ? '90%' : '30%', boxShadow: '24', background: bege, padding: 20, borderRadius: '0.4rem' }}>
                    <CancelRoundedIcon
                        className='btnclose'
                        style={{ position: 'absolute', top: 2, right: 3, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                        onClick={handleCloseNovidades}
                    />
                    <Typography id="modal-modal-title" variant="h5" component="h2" style={{ color: verdeescuro, textAlign: 'center' }} fontFamily={'Arima Madurai'}>
                        Receba nossas novidades!
                    </Typography>
                    <TextField label="Nome" variant="outlined" size="small" className="w-100 my-1" />
                    <TextField label="Empresa" variant="outlined" size="small" className="w-100 my-1" />
                    <TextField label="Whatsapp" variant="outlined" size="small" className="w-100 my-1" value={phoneNumber} onChange={handlePhoneNumberChange} />
                    <TextField label="e-mail" variant="outlined" size="small" className="w-100 my-1" />
                    <Button variant="contained" className=" w-100 mt-3">Enviar</Button>
                </div>
            </Modal>
        </div>
    )
}