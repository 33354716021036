import * as firebase from 'firebase/app'
import * as firebaseAuth from 'firebase/auth'
import {browserLocalPersistence} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBzMfYTmUdN5y1U2QqCQ1qMGK4aKDUNb78",
    authDomain: "site-agross.firebaseapp.com",
    projectId: "site-agross",
    storageBucket: "site-agross.appspot.com",
    messagingSenderId: "581093165433",
    appId: "1:581093165433:web:1635d42a15fcf7c4cc47a8"
  };
  const app = firebase.initializeApp(firebaseConfig);
  
  export const auth = firebaseAuth.initializeAuth(app, {
    persistence: browserLocalPersistence
  });