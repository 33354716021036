import Swal from "sweetalert2";
import { Box, Button, Card, CardActionArea, CardContent, Grid, MenuItem, Modal, Slider, TextField, Typography, Tooltip } from "@mui/material"

import { CartContext } from "app/shared/services/CartContext";
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AuthService from 'app/shared/services/AuthService';
import { UserLogged, useMenuContext } from "app/shared/contexts";
import { useNavigate } from 'react-router-dom';
import './Brindes.css'
import TokenContext from "app/shared/services/token/TokenContext";
import { useCarrinho } from "app/shared/services/cart/CarrinhoContext";
import { ShowDivContext } from "app/shared/contexts/ShowDivContext/ShowDivContext";
import useIsMobile from "app/shared/hooks/useIsMobile";
import { Consulta } from "app/shared/components/Consulta/Consulta";
import myPoints from 'assets/pontos.png';

const bege = '#F7F0EA';



interface Brindestg {
    id_brinde: number;
    brinde: string;
    id_categoria: number;
    pontos: number;
    img_brinde: string;
    ativo: string;
    destaque: string;
    voltagem: string;
}

const currencies = [
    {
        value: 999,
        label: 'Todos',
    },
    {
        value: 5,
        label: 'Acessórios Automotivos',
    },
    {
        value: 2,
        label: 'Bebês',
    },
    {
        value: 3,
        label: 'Brinquedos',
    },
    {
        value: 4,
        label: 'Camping E Treking',
    },
    {
        value: 22,
        label: 'Casa Inteligente',
    },
    {
        value: 7,
        label: 'Eletrodomésticos',
    },
    {
        value: 9,
        label: 'Eletroportáteis',
    },
    {
        value: 8,
        label: 'Eletrônicos, Escritório E Tecnologia',
    },
    {
        value: 10,
        label: 'Esportes E Lazer',
    },
    {
        value: 12,
        label: 'Ferramentas E Máquinas',
    },
    {
        value: 14,
        label: 'Games',
    },
    {
        value: 18,
        label: 'Locomoção',
    },
    {
        value: 15,
        label: 'Piscina E Verão',
    },
    {
        value: 16,
        label: 'Saúde E Beleza',
    },
    {
        value: 20,
        label: 'Utensílios De Cozinha',
    },
    {
        value: 17,
        label: 'Viagens E Turismo',
    },

];

interface BrindesProps {
    authService: AuthService;
}


function valuetext(value: number) {
    return `${Number(value).toLocaleString()}`;
}
export const Brindes = (props: BrindesProps) => {

    const [filteredData, setFilteredData] = useState<Brindestg[]>([]);
    const [Data, setData] = useState<Brindestg[]>([]);
    const token = React.useContext(TokenContext);
    const [search, setSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const { userLogged } = useContext(UserLogged);
    const { setShowDiv } = useContext(ShowDivContext);
    const Mobile = useIsMobile();
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(999);
    const { pontosCliente } = useContext(CartContext);
    const { fetchCarrinho, pedido, setLoaderCarrinho } = useCarrinho();
    const carrinhoClient = useCarrinho();
    const totaldepontos = carrinhoClient.carrinho.reduce((totalPontos, carrinho) => totalPontos + Number(carrinho.total_brinde), 0);
    const { razaosocial } = useMenuContext();
    const { setdestino } = useMenuContext();

    const { openConsulta, setOpenConsulta } = useMenuContext();

    const handleOpenConsulta = () => {
        setOpenConsulta(true);
    };

    const handleCloseConsulta = () => {
        setOpenConsulta(false);
    };


    const handleEnviarConsultaMoto = () => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        const fetchData = async () => {
            setIsLoading(true);
            try {

                const response = await axios.post(`https://data-api.agross.com.br/sendMoto.php?cnpj=${cnpj}&razao=${razaosocial}`, {}, {

                });

                console.log(response);
                if (response.status === 200) {
                    console.log(response);

                }

                Swal.fire(
                    'Consulta',
                    'Enviada com sucesso!',
                    'success'
                )
            } catch (error) {
                Swal.fire(
                    'Consulta',
                    'Enviada com sucesso!',
                    'success'
                );
            } finally {
                setIsLoading(false); // Finaliza o carregamento
            }
        };

        fetchData();
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://data-api.agross.com.br/api/v1/brindes', {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });
                const sortedData = [...response.data.brindes].sort((a, b) => a.pontos - b.pontos);
                setFilteredData(sortedData);
                setData(sortedData);
                setIsLoading(false);
            } catch (error) {
                console.error('Erro ao buscar os dados', error);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        console.log('alterei')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);


    const filterByType = (id: number) => {
        if (id === 999) {
            setFilteredData(Data);
        } else {
            setFilteredData(Data.filter(product => Number(product.id_categoria) === id));
        }
    };
    useEffect(() => {
        setFilteredData(Data.filter(product =>
            product.brinde.toLowerCase().includes(search.toLowerCase())
        ));
        setPontosBrindes([5000, 5000000]);
        setSelectedCategory(999);
    }, [search, Data]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleAdicionarItem = (id_brinde: Number) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        console.log(pedido);

        if (pedido !== '') {
            const CarrinhoCliente = {
                "carrinho": {
                    "id_pedido": pedido,
                    "cnpj": cnpj,
                    "itens": [
                        {
                            "id_pedido": pedido,
                            "id_brinde": id_brinde,
                            "quantidade": 1
                        }
                    ]
                }
            }

            const fetchData = async () => {
                try {
                    const encodedData = encodeURIComponent(JSON.stringify(CarrinhoCliente));
                    const response = await axios.post(`https://data-api.agross.com.br/api/v1/carrinho/?carrinho=${encodedData}`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`,

                        },
                    });

                    console.log(response);
                    if (response.status === 200) {
                        setLoaderCarrinho(true);
                        fetchCarrinho();
                    }


                } catch (error) {
                    console.error('Erro ao alterar quantidade', error);
                }
            };

            fetchData();
        } else {
            console.log('pedido: ', pedido);
            const fetchData = async () => {
                try {
                    // const responsepedido = await axios.get('https://data-api.agross.com.br/api/v1/pedidonext', {
                    //     headers: {
                    //         Authorization: `Bearer ${token}`,

                    //     },
                    // });
                    const pedidonovo = 93060;
                    console.log(pedidonovo);
                    const CarrinhoCliente = {
                        "carrinho": {
                            "id_pedido": pedidonovo,
                            "cnpj": cnpj,
                            "itens": [
                                {
                                    "id_pedido": pedidonovo,
                                    "id_brinde": id_brinde,
                                    "quantidade": 1
                                }
                            ]
                        }
                    }
                    console.log(CarrinhoCliente);

                    const fetchData2 = async () => {
                        try {
                            const encodedData = encodeURIComponent(JSON.stringify(CarrinhoCliente));
                            const response = await axios.post(`https://data-api.agross.com.br/api/v1/carrinho/?carrinho=${encodedData}`, {}, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });

                            console.log(response);



                        } catch (error) {
                            console.error('Erro ao alterar quantidade', error);
                        }
                    };

                    fetchData2();



                } catch (error) {
                    console.error('Erro ao alterar quantidade', error);
                }
            };
            fetchData();
        }

    }
    const [pontosBrindes, setPontosBrindes] = React.useState<number[]>([5000, 5000000]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setSearch('');
        const valores = newValue as number[];
        setPontosBrindes(valores);
        setFilteredData(Data.filter(item =>
            item.pontos >= valores[0] && item.pontos <= valores[1] &&
            String(item.id_categoria) !== '17' &&
            String(item.id_brinde) !== '575'
        ));
        setSelectedCategory(999);
    };


    const handleCategoryChange = (event: any) => {
        setSearch('');
        setPontosBrindes([5000, 5000000]);
        setSelectedCategory(event.target.value);
    };

    return (

        <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} className=' my-5'>

            <div>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, backgroundColor: bege, alignItems: 'center' }} className={`rounded-2 ${Mobile ? 'flex-column' : ''}`}>
                    <SearchIcon style={{ fontSize: 35, marginRight: '-5%' }} />
                    <TextField label="Pesquisar Prêmios" value={search} onChange={(e) => setSearch(e.target.value)} variant="outlined" />

                    <div className={`d-flex flex-column ${Mobile ? 'mt-5' : ''}`} style={{ width: Mobile ? '100%' : '35%' }}>
                        <div>
                            <Box sx={{ width: '100%' }}>
                                <Slider
                                    getAriaLabel={() => 'Temperature range'}
                                    max={5000000}
                                    min={5000}
                                    step={5}

                                    value={pontosBrindes}
                                    onChange={handleChange}
                                    valueLabelDisplay="on"
                                    size="small"
                                    getAriaValueText={valuetext}
                                />
                            </Box>
                        </div>
                        <div className={`d-flex justify-content-between ${Mobile ? 'flex-column' : ''}`}>

                            <div style={{ width: Mobile ? '100%' : '45%' }}>
                                <TextField label="Pontuação Mínima" variant="outlined" size="small" className={`${Mobile ? 'my-3' : ''}`} value={pontosBrindes[0]} type="number"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const newValue = [Number(event.target.value), pontosBrindes[1]];
                                        setPontosBrindes(newValue);
                                        const newEvent = new Event('change') as unknown as Event;
                                        handleChange(newEvent, newValue);
                                    }}
                                />
                                {pontosBrindes[0] > pontosBrindes[1] ?
                                    <Typography variant="caption" color={'red'}>
                                        Pontuação maior que a máxima!
                                    </Typography>
                                    :
                                    ''
                                }

                            </div>
                            <div style={{ width: Mobile ? '100%' : '45%' }}>
                                <TextField label="Pontuação Máxima" variant="outlined" size="small" className={`${Mobile ? 'my-3' : ''}`} value={pontosBrindes[1]} type="number"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const newValue = [pontosBrindes[0], Number(event.target.value)];
                                        setPontosBrindes(newValue);
                                        const newEvent = new Event('change') as unknown as Event;
                                        handleChange(newEvent, newValue);
                                    }}
                                />
                                {pontosBrindes[0] > pontosBrindes[1] ?
                                    <Typography variant="caption" color={'red'}>
                                        Pontuação menor que a mínima!
                                    </Typography>
                                    :
                                    ''
                                }

                            </div>
                            <Tooltip title={
                                <React.Fragment>
                                    <span>Usar meus pontos disponíveis</span><br />
                                </React.Fragment>
                            }>
                                <img
                                    src={myPoints}
                                    alt='icon'
                                    style={{
                                        marginLeft: 8, marginTop: 8, width: '25px', height: '25px', transition: 'transform 0.3s',
                                        cursor: 'pointer'
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <TextField
                        style={{ marginLeft: Mobile ? '' : '1rem', width: Mobile ? '100%' : '30%' }}
                        id="filtrarporcategorias"
                        select
                        className={Mobile ? 'mt-3' : ''}
                        label="Filtrar por categoria"
                        defaultValue={999}
                        variant='filled'
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value} onClick={() => filterByType(option.value)}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </Box>
                {
                    isLoading ?
                        <div className=" d-flex">

                            <img src={require('assets/loader.gif')} alt="loader2" style={{ width: '7%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }} />
                        </div>
                        :
                        <div className=" d-flex">
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }} >
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ md: 21 }} className={`my-3 ${Mobile ? 'w100' : ''}`}>
                                    {filteredData.map((brindes) => (
                                        <Grid item xs={2} sm={4} md={7}
                                        // onClick={() => swalWithBootstrapButtons.fire({
                                        //     title: 'Trocar Brinde?',
                                        //     text: "Deseja solicitar este brinde?",
                                        //     icon: 'warning',
                                        //     showCancelButton: true,
                                        //     confirmButtonText: 'Sim, quero pedir este brinde.',
                                        //     cancelButtonText: 'Não, cancelar!',
                                        //     reverseButtons: true
                                        // }).then((result) => {
                                        //     if (result.isConfirmed) {
                                        //         if (Number(pontosCliente) < Number(Number(totaldepontos) + Number(brindes.pontos))) {
                                        //             Swal.fire(
                                        //                 'Pontos Insuficientes',
                                        //                 'Você não possui saldo suficiente para trocar este brinde',
                                        //                 'error'
                                        //             )
                                        //         } else {
                                        //             handleAdicionarItem(brindes.id_brinde);
                                        //             Swal.fire({
                                        //                 title: 'Brinde Adicionado',
                                        //                 text: "O brinde foi adicionado com sucesso",
                                        //                 icon: 'success',
                                        //                 showCancelButton: true,
                                        //                 confirmButtonText: 'Ir para o Carrinho',
                                        //                 cancelButtonText: 'Continuar trocando',
                                        //                 cancelButtonColor: '#198754',
                                        //                 confirmButtonColor: '#dc3545'
                                        //             }).then((result) => {
                                        //                 if (result.isConfirmed) {
                                        //                     navigate('/carrinho');
                                        //                 }
                                        //             })
                                        //         }
                                        //     }
                                        // })}
                                        >
                                            <Card sx={{ maxWidth: 345 }} className={`m-2 ${Mobile ? 'm-auto' : ''}`} style={{ width: Mobile ? '78vw' : '', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
                                                <CardContent style={{ textAlign: 'center', height: '25rem', display: 'flex', alignItems: 'center', cursor: 'default' }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        height: '100%', // Certifique-se de que a div tem altura suficiente
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        width: '100%'
                                                    }}>
                                                        <div className=' d-flex'>
                                                            <img src={require(`assets/brindes/${brindes.id_brinde}.jpg`)} alt="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
                                                        </div>

                                                        <Box display={"flex"} alignItems={"center"} style={{ marginLeft: '15%' }}>
                                                            <Typography variant="body2" color="text.secondary" style={{ marginRight: '2%' }}>
                                                                Imagem meramente ilustrativa
                                                            </Typography>
                                                            <Tooltip title={
                                                                <React.Fragment>
                                                                    <span>No Troque e Ganhe não trabalhamos com marcas, modelos, cores especofocas e sim com
                                                                        a categoria do produto solicitado, conforme disponibilidade de estoque do nosso fornecedor,
                                                                        após a aprovação de sua solicitação de troca, vide nosso regulamento.
                                                                    </span><br />
                                                                </React.Fragment>
                                                            }>
                                                                <InfoOutlinedIcon style={{ fontSize: 22, color: 'grey' }} />
                                                            </Tooltip>
                                                        </Box>
                                                        <Typography gutterBottom variant="h5" component="div" className=' my-3' >
                                                            {brindes.brinde}
                                                        </Typography>
                                                        {String(brindes.id_categoria) === '17' ?
                                                            <Typography variant="body2" color="text.secondary">
                                                                1 Pessoa + 1 Acompanhante
                                                            </Typography>
                                                            :
                                                            ''
                                                        }
                                                        {String(brindes.id_categoria) === '17' ?
                                                            ''
                                                            :
                                                            <div>
                                                                {String(brindes.id_brinde) === '575' ?
                                                                    ''
                                                                    :
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {Number(brindes.pontos).toLocaleString()} Pontos
                                                                    </Typography>
                                                                }
                                                            </div>

                                                        }

                                                        {userLogged ?
                                                            <div>
                                                                {String(brindes.id_categoria) === '17' ?
                                                                    (
                                                                        <div>
                                                                            <Button variant='outlined' className="mt-2" startIcon={<LocalGroceryStoreIcon />}
                                                                                // onClick={() => {
                                                                                //     Swal.fire(
                                                                                //         'Consultar Viagem',
                                                                                //         'Favor enviar e-mail com CNPJ, Razão social, Nome, CPF, Data de Nascimento, Destino de origem para troqueeganhe@agross.com.br',
                                                                                //         'info'
                                                                                //     )
                                                                                // }}
                                                                                onClick={() => swalWithBootstrapButtons.fire({
                                                                                    title: 'Consultar Viagem?',
                                                                                    text: "Deseja consultar viagem?",
                                                                                    icon: 'question',
                                                                                    showCancelButton: true,
                                                                                    confirmButtonText: 'Consultar',
                                                                                    cancelButtonText: 'Não, cancelar!',
                                                                                    reverseButtons: true
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        setdestino(brindes.brinde);
                                                                                        handleOpenConsulta();
                                                                                    }
                                                                                }).then((result) => {
                                                                                    console.log('enviar consulta');


                                                                                })}

                                                                                style={{ width: '100%' }}
                                                                            >Consulte
                                                                            </Button>

                                                                        </div>
                                                                    ) :
                                                                    (
                                                                        <div>
                                                                            {String(brindes.id_brinde) === '575' ?
                                                                                <div>
                                                                                    <Button variant='outlined' className="mt-2" startIcon={<LocalGroceryStoreIcon />}
                                                                                        // onClick={() => {
                                                                                        //     Swal.fire(
                                                                                        //         'Consulte',
                                                                                        //         'Favor enviar e-mail com seu CNPJ para troqueeganhe@agross.com.br',
                                                                                        //         'info'
                                                                                        //     )
                                                                                        // }}
                                                                                        onClick={() => swalWithBootstrapButtons.fire({
                                                                                            title: 'Consultar Moto?',
                                                                                            text: "Deseja consultar moto?",
                                                                                            icon: 'question',
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText: 'Consultar',
                                                                                            cancelButtonText: 'Não, cancelar!',
                                                                                            reverseButtons: true
                                                                                        }).then((result) => {
                                                                                            if (result.isConfirmed) {
                                                                                                handleEnviarConsultaMoto();
                                                                                            }
                                                                                        })}

                                                                                        style={{ width: '100%' }}
                                                                                    >Consulte
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                <Button variant='outlined' className="mt-2" startIcon={<LocalGroceryStoreIcon />}
                                                                                    style={{ width: '100%' }}
                                                                                    onClick={() => swalWithBootstrapButtons.fire({
                                                                                        title: 'Trocar Prêmio?',
                                                                                        text: "Deseja solicitar este prêmio?",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Sim, quero pedir este prêmio.',
                                                                                        cancelButtonText: 'Não, cancelar!',
                                                                                        reverseButtons: true
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            if (Number(pontosCliente) < Number(Number(totaldepontos) + Number(brindes.pontos))) {
                                                                                                Swal.fire(
                                                                                                    'Pontos Insuficientes',
                                                                                                    'Você não possui saldo suficiente para trocar este prêmio',
                                                                                                    'error'
                                                                                                )
                                                                                            } else {
                                                                                                handleAdicionarItem(brindes.id_brinde);
                                                                                                Swal.fire({
                                                                                                    title: 'Prêmio Adicionado',

                                                                                                    text: "O prêmio foi adicionado com sucesso",
                                                                                                    icon: 'success',
                                                                                                    showCancelButton: true,
                                                                                                    confirmButtonText: 'Ir para o Carrinho',
                                                                                                    cancelButtonText: 'Continuar trocando',
                                                                                                    cancelButtonColor: '#198754',
                                                                                                    confirmButtonColor: '#dc3545'
                                                                                                }).then((result) => {
                                                                                                    if (result.isConfirmed) {
                                                                                                        navigate('/carrinho');
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    })}>Trocar
                                                                                </Button>
                                                                            }</div>
                                                                    )
                                                                }
                                                            </div>
                                                            :
                                                            <Button variant='outlined' className="mt-2" startIcon={<LocalGroceryStoreIcon />}
                                                                style={{ width: '100%' }}
                                                                onClick={() => swalWithBootstrapButtons.fire({
                                                                    title: 'Ops',
                                                                    text: "Para trocar prêmios faça login!",
                                                                    icon: 'error',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Fazer Login',
                                                                    cancelButtonText: 'Continuar navegando',
                                                                    reverseButtons: true
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        setShowDiv(true);
                                                                    }
                                                                })}>Trocar
                                                            </Button>
                                                        }

                                                    </div>


                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>

                        </div>
                }
            </div >

            <Modal
                open={openConsulta}
                onClose={handleCloseConsulta}
                style={{ zIndex: 20 }}

            >
                <Box>
                    <Consulta />

                </Box>
            </Modal>

        </div >







    )
}